import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { IonIcon, IonSpinner } from "@ionic/react";
import FormStyles from "./../Form/Form.module.scss";
import TextFormInput from "../FormInput/TextFormInput";
import SelectFormInput from "../FormInput/SelectFormInput";
import CheckboxFormInput from "../FormInput/CheckboxFormInput";
import {
  CURRENCIES,
  FormActionButtons,
  handleSubListAdd,
  handleSubListDelete,
  hasTableFormChange,
  IMAGE_TYPE, RemoveSubFormIcon,
  STATUS,
  stopFormSubmitByEnter
} from "../Form/Form";
import Utils, { TIME_ZONES } from "../../serverUtils/Utils";
import moment from "moment-timezone";
import { default as AddIcon } from "@mui/icons-material/AddCircleOutline";

import MultiSelectFormInput from "../FormInput/MultiSelectFormInput";
import { AddressForm } from "../Form/AddressForm";
import { ellipsisVertical } from "ionicons/icons";
import TournamentModel from "../../serverUtils/models/TournamentModel";
import { TournamentSchema, convertDate } from "../../serverUtils/Models";
import { Button, Menu, MenuItem, Tooltip } from "@mui/material";
import UserModel from "../../serverUtils/models/UserModel";
import PaymentInfos, { GIHTransactionFee } from "../Form/PaymentInfos";
import SocialMedias from "../Form/SocialMedias";
import ContactInfos from "../Form/ContactInfos";
import ImageFormInput from "../FormInput/ImageFormInput";
import LeagueModel from "../../serverUtils/models/LeagueModel";
import ImageModel from "../../serverUtils/models/ImageModel";
import { useStore } from "../../Store";
import { default as CheckUrlPathIcon } from "@mui/icons-material/DoneAll";
import { default as FileIcon } from "@mui/icons-material/FileOpen";
import "./TournamentInformationForm.scss";
import { RequestCommon } from "../../serverUtils/requests";
import TextAreaFormInput from "../FormInput/TextAreaFormInput";
import DateFormInput1 from "../FormInput/DateFormInput1";
import { headCells_registration } from "./Registrations";
import AlertPane from "../FormInput/AlertPane";

const CRC_Fields = [...Utils.listObjectKeys(TournamentSchema().model), 'banner_fileName', 'ruleFile'];
export default function TournamentInformationForm({ data }) {
  const session = useStore((state) => state.session);
  const setSession = useStore((state) => state.setSession);
  const cache = useStore((state) => state.pushTournament);
  const ruleFile = useRef(null);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    control,
    getValues,
    getFieldState,
    setError,
    clearErrors,
  } = useForm();
  const history = useHistory();
  const [message, setMessage] = useState("");
  const [formData, setFormData] = useState(data || {});
  const [isSubmitting, setIsSubmitting] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [checkUrlPathText, setCheckUrlPathText] = useState();
  const [ruleFileError, setRuleFileError] = useState();

  const fileInputRef = useRef();
  const startDateRef = useRef();
  const endDateRef = useRef();
  const closeDateRef = useRef();
  const atheleteChangeDateRef = useRef();
  const coachChangeDateRef = useRef();
  const refundDateRef = useRef();

  const checkCRCTimeout = useRef();
  const [hasChange, setHasChange] = useState(false);
  const checkCRC = () => {
    checkCRCTimeout.current && clearTimeout(checkCRCTimeout.current);
    checkCRCTimeout.current = setTimeout(() => {
      let d = {...data, ...getValues()};
      let c = hasTableFormChange(d, CRC_Fields);
      setHasChange(c);
    }, 1000);
  }

  const doDelete = async () => {
    if (isSubmitting) {
      return;
    }
    setMessage("");
    if (formData.getRegistrations().length > 0){
      return setMessage('error: Tournament cannot be deleted until all registration resources has been clear');
    }

    setIsSubmitting("delete");
    let result = await TournamentModel.deleteTournament(formData.id);
    if (result && result.id) {
      UserModel.updateAdminInfo(session, setSession, "tournaments", {
        id: formData.id,
      }, true);   

      setMessage("success: Successfully deleted tournament.");
      setIsSubmitting(null);
      return setTimeout(() => {
        document.location.href = "/tournaments";
      }, 1000);
    }else {
      setMessage("error: Error deleting tournament.");
    }
  };

  const setTimeZoneDt = (dt, time_zone) => {
    if (dt) {
      let ts = moment(dt).toDate().getTime();
      if (ts) {
        return TournamentModel.convertToTimeZoneDate(ts, time_zone);
      }
    }
  }

  const onSubmit = async (_d) => {
    if (isSubmitting) {
      return;
    }
    setMessage("");
    setIsSubmitting("save");
    console.log('errors:', errors, _d);
    let d = Utils.deepCopy(_d);
    if (!d.league) {
      return setMessage("error: League is required");
    }
    try {
      if (typeof d.league === "object") {
        d.league = d.league.id || "";
      }
      d.id = formData.id;
      if (!(await checkUrlPath())) {
        return;
      }
      Object.assign(formData, d);
      Object.keys(formData.dates).forEach(dt => {
        if (!dt.endsWith('_tz')) {
          d.dates[dt] = setTimeZoneDt(formData.dates[dt], d.time_zone);
        }
      });

      if (d?.t_shirt?.expiration_date) {
        d.t_shirt.expiration_date = setTimeZoneDt(d.t_shirt.expiration_date, d.time_zone);
      }
      delete d.fee_info;
      if (d.banner) {
        formData.getImage &&
          formData.getImage()?.id &&
          (await ImageModel.deleteImage(formData.getImage().id));
        await ImageModel.addEntityImage(
          d.banner,
          formData,
          IMAGE_TYPE.tournament
        );
        delete d.banner;
      }

      if (ruleFile.current) {
        let rUploadFile = await RequestCommon.uploadFile(ruleFile.current, p => console.log(p));
        if (!rUploadFile || rUploadFile.error) {
          return setMessage(`error: Error uploading rule file.`);
        }

        if (formData.rule) {
          await RequestCommon.deleteFile(formData.rule);
        }
        formData.rule = d.rule = rUploadFile.id;
        let rf = {...ruleFile.current};
        formData.getRuleFile = () => rf;
        ruleFile.current = null;
      }

      let result = await TournamentModel.updateTournament(d);
      if (result && result.id) {
        Object.keys(d.dates).forEach(dt => {
          if (!dt.endsWith('_tz')) {
            d.dates[`${dt}_tz`] = d.dates[dt];
            d.dates[dt] = TournamentModel.convertTimeZoneToLocal(d.dates[dt]);
          }
        });
        if (d?.t_shirt?.expiration_date){
          d.t_shirt.expiration_date_tz = d.t_shirt.expiration_date;
          d.t_shirt.expiration_date = TournamentModel.convertTimeZoneToLocal(d.t_shirt.expiration_date);
        }
        let updateTour = { ...formData, ...d};
        setFormData(updateTour);
        setMessage(
          `success: Successfully ${d.id ? "updated" : "added"} tournament.`
        );
        cache(result.id, updateTour);
        if (!d.id) {
          UserModel.updateAdminInfo(session, setSession, "tournaments", {
            id: result.id,
            name: updateTour.name,
          });
          updateTour.id = result.id;
          updateTour.getRegistrations = () => [];
          updateTour.getAvailableDivisions = () => [];
          updateTour.getSchedules = () => new Promise(resolve => resolve([]));
          updateTour.getPlacements = () => [];
          updateTour.crc = Utils.getCRC(updateTour, CRC_Fields);
          return setTimeout(() => document.location.href = `/tournaments/${result.id}/edit`, 1000);;
        }
        setTimeout(() => document.location.reload(), 1000);
      } else {
        setMessage(`error: Error ${d.id ? "updating" : "adding"} tournament.`);
      }
    } finally {
      setIsSubmitting(null);
    }
  };

  useEffect(() => {
    if (data) {
      setValue("admins", data.admins);
      if (data?.dates) {
        Object.keys(data.dates).filter(d => !d.endsWith('_tz')).forEach(dt => {
          let dts = data.dates[`${dt}_tz`];
          data.dates[dt] = TournamentModel.convertTimeZoneToLocal(dts);
        });
      }

      const {t_shirt} = data;
      if (t_shirt && t_shirt?.expiration_date_tz && isNaN(data.t_shirt.expiration_date_tz)) {
        data.t_shirt.expiration_date = TournamentModel.convertTimeZoneToLocal(data.t_shirt.expiration_date_tz);
      }

      let ts = new Date().getTime();
      let st = data?.dates?.start_date || ts;
      setValue("dates.start_date", convertDate(st));
      let et = data?.dates?.end_date || ts;
      setValue("dates.end_date", convertDate(et));

      let dt = data?.dates?.close_date;
      dt && setValue("dates.close_date", convertDate(dt));
      dt = data?.dates?.member_change_date;
      dt && setValue("dates.member_change_date", convertDate(dt));
      dt = data?.dates?.coach_change_date || ts;
      dt && setValue("dates.coach_change_date", convertDate(dt));
      dt = data?.dates?.refund_date || ts;
      dt && setValue("dates.refund_date", convertDate(dt));
      dt = data?.t_shirt?.expiration_date || ts;
      dt && setValue("t_shirt.expiration_date", dt);

      setFormData({ ...data });
      session && session.is_super && data.getLeague && 
        setValue("league", {
          id: data.league,
          display: data.getLeague() && data.getLeague().name
        });
      setValue('social_medias', data.social_medias);
      data.crc = !data.crc? Utils.getCRC({...data, ...getValues()}, CRC_Fields):data.crc;
    } 
  }, [data, session]);

  const doCopy = async () => {
    if (isSubmitting) {
      return;
    }
    try{
      setMessage("");
      setIsSubmitting("clone");
      let tournament = Utils.copy({ ...formData, ...getValues() });
      tournament.name = `Copy ${tournament.name}`;
      delete tournament.dates;
      tournament.dates = {start_date: setTimeZoneDt(new Date(), tournament.time_zone)}
      if (typeof tournament.league === "object") {
        tournament.league = tournament.league.id || "";
      }
      console.log("doCopy:", tournament);
      let result = await TournamentModel.cloneTournament(tournament.id);
      if (result && result.id) {
        setMessage("success: Successfully clone tournament.");
        tournament.id = result.id;
        UserModel.updateAdminInfo(session, setSession, "tournaments", {
          id: result.id,
          name: tournament.name,
        });
        return setTimeout(() => history.push(`/tournaments/${result.id}/edit`), 2000);
      }else {
        setMessage("error: Error cloning tournament.");
      }
    }finally {
      setIsSubmitting(null);
    }
  };

  const validateDate = () => {
    startDateRef && startDateRef.current.setError('');
    endDateRef && endDateRef.current.setError('');
    closeDateRef && closeDateRef.current.setError('');
    atheleteChangeDateRef&& atheleteChangeDateRef.current.setError('');
    coachChangeDateRef && coachChangeDateRef.current.setError('');
    refundDateRef && refundDateRef.current.setError('');

    errors.dates = errors.dates || {};
    const {start_date} = getValues().dates;
    if (isNaN(start_date)){
        return startDateRef.current.setError('Date is invalid');
    }
    let stDate = new Date(start_date);
    [endDateRef, 
    closeDateRef, 
    atheleteChangeDateRef, 
    coachChangeDateRef, 
    refundDateRef].forEach(ref => {
      let field = ref.current.name;
      let v = getValues().dates[field.split('.').pop()];
      let isError;
      let dt = new Date(v);
      if (isNaN(v)){
        return;
      }
      if (field === "dates.end_date"){
        isError = dt < stDate;
      }else {
        isError = dt > stDate;
      }

      if (isError) {
        setError(field, { message: "Date is invalid" });
        ref.current.setError("Date is out of range");
      }else {
        clearErrors(field);
        ref.current.setError("");
      }
    });
    checkCRC();
  };

  const checkUrlPath = async (e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    setCheckUrlPathText("");
    let url = getValues("url_path");
    if (!url) {
      return true;
    }
    if (url.split(" ").length > 1) {
      return setError("url_path", { message: "URL path must be one word" });
    }
    let result = await TournamentModel.checkUrlPath(data.league, url.trim());
    if (!result) {
      return setError("url_path", { message: "URL path is not available" });
    }
    delete errors.url_path;
    setCheckUrlPathText("URL path is available");
    return true;
  };

  const RulFile = ({}) => {
    const RULE_FILE_MAX_SIZE = 200 * 1024;
    const loadLocalFile = (files) => {
      if (files.length === 0) {
        return;
      }
      setRuleFileError('');
      let file = fileInputRef.current.files[0];
      if (file.size > RULE_FILE_MAX_SIZE) {
        setRuleFileError("Maximun size is 200K bytes");
        fileInputRef.current.value = '';
        return setRefresh(!refresh);
      }else {
        delete errors.ruleFileName;
      }
      let ext = file.name.split('.');
      ext = ext.length>1? ext.pop():'';
      let reader = new FileReader();
      reader["readAsDataURL"](file);
      reader.addEventListener(
        "load",
        () => {
          ruleFile.current = {
            data: reader.result, 
            ext, 
            name: file.name, 
            lastModifiedDate: file.lastModified || (file.lastModifiedDate && file.lastModifiedDate.getTime()),
            size: file.size
          };
          formData.getRuleFile = () => ruleFile.current;
          setValue('ruleFile', file.name);
          checkCRC();
        },
        false
      );
    }
    return <div className="tournament-rule">
      <input
        ref={fileInputRef}
        hidden
        accept="*"
        type="file"
        onChange={e => {
          loadLocalFile(fileInputRef.current.files);
        }}
      />
      <TextFormInput 
        value={getValues().ruleFile || formData.getRuleFile && formData.getRuleFile()?.name || ''}
        name="ruleFileName"
        label="Rule File"
        register={register} setValue={setValue}
        inputProps={{readOnly: true}}
        errorText={ruleFileError}
        onChange={v => {
          if (!v) {
            fileInputRef.current.value = v;
            setValue('ruleFile', v);
            setValue('rule', v);
            checkCRC();
          }
        }}
      />
      <Tooltip title="Select rule file">
        <button className="button icon_button">
          <FileIcon className="UploadIcon"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              fileInputRef.current.click();
            }}
          />
        </button>
      </Tooltip>
    </div>;
  }

  // console.log('errors:', errors);
  let isNoLeagueAvail = session.getAdminInfo().leagues.length === 0;
  return !formData ?
    <IonSpinner name="crescent" className="spinner" />
    : 
    <div className="TournamentInformationForm">
      {hasChange && <AlertPane isFloat message={'warning: Data has changed'} setMessage={() => setHasChange(false)}/>}
      <form className={FormStyles.form} 
        onKeyDown={stopFormSubmitByEnter}
        onSubmit={handleSubmit(onSubmit)}>
        {session.is_super && (
          <GIHTransactionFee register={register} formData={formData} onChange={checkCRC}/>
        )}
        <div className={FormStyles.fieldDescription}>
          <h3>Banner</h3>
        </div>
        <div className={FormStyles.fields}>
          <ImageFormInput
            name="banner"
            images={ImageModel.setImageFormInput(
              IMAGE_TYPE.tournament,
              getValues,
              getFieldState,
              formData,
              "banner"
            )}
            size={600}
            maxWidth={200}
            onImage={d => {
              setValue("banner", d, { shouldDirty: true });
              setValue('banner_fileName', d.fileName);
              checkCRC();
            }}
          />
        </div>
        <div className={FormStyles.fieldDescription} key={0}>
          <h3>League</h3>
        </div>
        <div className={`${FormStyles.fields} MultiSelectFormInput-wrapper`} key={1}>
          {session.is_super ? (
            <MultiSelectFormInput
              isRequired
              control={control}
              value={getValues('league')}
              name="league"
              label="League"
              optionLabel="display"
              optionValue="id"
              fetchOptions={(searchVal) => LeagueModel.searchLeague(searchVal)}
              multiple={false}
              setValue={setValue}
              onChange={checkCRC}
            />
          ) : isNoLeagueAvail ? (
            TournamentModel.getDivisionsMessage(formData)
          ) : (
            <SelectFormInput
              value={formData?.league}
              name="league"
              label="League"
              setValue={setValue} getValues={getValues}
              onChange={checkCRC}
              placeholder="Select a league"
              options={session
                .getAdminInfo()
                .leagues.map((l) => ({ value: l.id, label: l.name }))}
            />
          )}
        </div>

        <div className={FormStyles.fieldDescription}>
          <h3>General Information</h3>
          <p>This is the name of the tournament</p>
        </div>
        <div className={`general-information ${FormStyles.fields}`}>
          <TextFormInput
            value={formData?.name}
            name="name"
            label="Tournament Name"
            isRequired
            register={register} setValue={setValue}
            errors={errors}
            onChange={checkCRC}
          />
          <SelectFormInput 
            value={formData?.status}
            name="status"
            label="Status"
            setValue={setValue} 
            placeholder="Select status"
            options={STATUS}
            onChange={checkCRC}
          />
          <div className="tournament-path">
            <TextFormInput
              value={formData?.url_path}
              onChange={() => {
                setCheckUrlPathText("");
                checkCRC();
              }}
              name="url_path"
              label="Path Name"
              register={register} setValue={setValue}
              helperText={checkUrlPathText}
            />
            <Tooltip title="Check URL availability">
              <button className="button icon_button">
                <CheckUrlPathIcon className="CheckUrlPathIcon"
                  onClick={checkUrlPath}
                />
              </button>
            </Tooltip>
            
          </div>
          <RulFile />
        </div>
        

        <div className={FormStyles.fieldDescription}>
          <h3>Time Zone</h3>
          <p>Time zone of the tournament.</p>
        </div>
        <div className={FormStyles.fields}>
          <SelectFormInput
            value={formData?.time_zone || Utils.getBrowserTimeZone()}
            name="time_zone"
            label="Tournament Time Zone"
            setValue={setValue}
            placeholder="Select status"
            options={TIME_ZONES}
            onChange={checkCRC}
          />
        </div>

        <div className={FormStyles.fieldDescription}>
          <h3>Important Dates</h3>
          <p>Important dates for the tournament.</p>
        </div>
        <div className={`${FormStyles.fields} important-dates`}>
          <DateFormInput1 ref={startDateRef}
            name="dates.start_date"
            hasTime isRequired
            label="Start Date"
            setValue={setValue}
            value={formData?.dates?.start_date || getValues('dates.start_date')}
            endDate={formData?.dates?.end_date || getValues('dates.end_date')}
            onChange={v => validateDate()}/>
          <DateFormInput1 ref={endDateRef}
            name="dates.end_date"
            hasTime
            label="End Date"
            setValue={setValue}
            value={formData?.dates?.end_date || getValues('dates.end_date')}
            startDate={formData?.dates?.start_date || getValues('dates.start_date')}
            onChange={v => validateDate()}/>
          <DateFormInput1 ref={closeDateRef}
            name="dates.close_date"
            hasTime
            label="Registration Close Date"
            setValue={setValue}
            value={formData?.dates?.close_date || getValues('dates.close_date')}
            startDate={formData?.dates?.start_date || getValues('dates.start_date')}
            endDate={formData?.dates?.end_date || getValues('dates.end_date')}
            onChange={v => validateDate()}/>
          <DateFormInput1 ref={atheleteChangeDateRef}
            name="dates.member_change_date"
            hasTime
            label="Athelete Change Date"
            setValue={setValue}
            value={formData?.dates?.member_change_date || getValues('dates.member_change_date')}
            startDate={formData?.dates?.start_date || getValues('dates.start_date')}
            endDate={formData?.dates?.end_date || getValues('dates.end_date')}
            onChange={v => validateDate()}/>
          <DateFormInput1 ref={coachChangeDateRef}
            name="dates.coach_change_date"
            hasTime
            label="Coach Change Date"
            setValue={setValue}
            value={formData?.dates?.coach_change_date || getValues('dates.coach_change_date')}
            startDate={formData?.dates?.start_date || getValues('dates.start_date')}
            endDate={formData?.dates?.end_date || getValues('dates.end_date')}
            onChange={v => validateDate()}/>
          <DateFormInput1 ref={refundDateRef}
            name="dates.refund_date"
            hasTime
            label="Refund Date"
            setValue={setValue}
            value={formData?.dates?.refund_date || getValues('dates.refund_date')}
            startDate={formData?.dates?.start_date || getValues('dates.start_date')}
            endDate={formData?.dates?.end_date || getValues('dates.end_date')}
            onChange={v => validateDate()}/>
        </div>

        <div className={FormStyles.fieldDescription}>
          <h3>Admins</h3>
        </div>
        <div className={`${FormStyles.fields} MultiSelectFormInput-wrapper`}>
          <MultiSelectFormInput
            isRequired
            control={control}
            value={getValues("admins") || formData.admins}
            name="admins"
            label="Admins"
            optionLabel="display"
            optionValue="id"
            fetchOptions={searchVal => UserModel.searchUser(searchVal)}
            setValue={setValue}
            onChange={checkCRC}
          />
        </div>
        <div className={FormStyles.fieldDescription}>
          <h3>Currency</h3>
        </div>
        <div className={FormStyles.fields}>
          <SelectFormInput
            value={formData?.currency || "USD"}
            name="currency"
            label="Currency"
            setValue={setValue}
            placeholder="Select currency"
            options={CURRENCIES}
            onChange={checkCRC}
          />
        </div>

        {/* <div className={FormStyles.fieldDescription}>
          <h3>GIH Transaction Fee</h3>
        </div>
        <div className={FormStyles.fields}>
          <CheckboxFormInput
            register={register} setValue={setValue}
            value={formData?.include_transaction_fee}
            name={`include_transaction_fee`}
            label="Include with registration fee?"
          />
        </div> */}

        <div className={FormStyles.fieldDescription}>
          <h3>Waiver</h3>
        </div>
        <div className={FormStyles.fields}>
          <TextAreaFormInput
            label="Waiver"
            value={formData?.waiver || ''}
            onChange={checkCRC}
            name={`waiver`}
            placeholder="add waiver here...."
            register={register} setValue={setValue}/>
        </div>
        
        <div className={FormStyles.fieldDescription}>
          <h3>
            Payment Information
            <AddIcon
              className={FormStyles.add_icon}
              onClick={() => {
                let field = "payment_info";
                let list = getValues(field) || [];
                list.push({});
                setValue(field, list, { shouldDirty: true });
                setRefresh(!refresh);
              }}
            />
          </h3>
          <p>Enter your payment gateway information</p>
        </div>
        <div className={FormStyles.fields}>
          <PaymentInfos
            data={getValues("payment_info") || formData.payment_info}
            register={register}
            refresh={() => setRefresh(!refresh)} setValue={setValue}
            errors={errors?.payment_info}
          />
        </div>

        <div className={FormStyles.fieldDescription}>
          <h3>T-shirt</h3>
          <p>Set t-shirt for this tournament</p>
        </div>
        <div className={FormStyles.fields}>
          <TShirt
            getValues={getValues} setValue={setValue}
            data={formData.t_shirt}
            register={register} setError={setError} errors={errors}
            control={control}
            onChange={checkCRC}
          />
        </div>

        {/* <div className={FormStyles.fieldDescription}>
          <h3>Registration Requirements</h3>
          <p>Set the registration requirements</p>
        </div>
        <div className={FormStyles.fields}>
          <RegistrationRequirements
            value={formData.registration_requirements}
            register={register} setValue={setValue}
            errors={errors?.publish}
            control={control}
            teams={
              formData.getTeams &&
              formData.getTeams().map((t) => ({ id: t.id, display: t.name }))
            }
          />
        </div> */}

        <div className={FormStyles.fieldDescription}>
          <h3>Publish</h3>
          <p>Set publish for this tournament</p>
        </div>
        <div className={FormStyles.fields}>
          <Publish
            value={formData.publish}
            register={register} setValue={setValue}
            errors={errors?.publish}
          />
        </div>

        <div className={FormStyles.fieldDescription}>
          <h3>
            Locations
            <AddIcon
              className={FormStyles.add_icon}
              onClick={() =>
                handleSubListAdd(setFormData, formData, "locations")
              }
            />
          </h3>
          <p>Set locations for this tournament</p>
        </div>
        <div className={FormStyles.fields}>
          <Locations
            setFormData={setFormData}
            formData={formData}
            register={register} setValue={setValue} 
            errors={errors} setError={setError} clearErrors={clearErrors}
          />
        </div>

        <div className={FormStyles.fieldDescription}>
          <h3>
            Contacts
            <AddIcon
              className={FormStyles.add_icon}
              onClick={() => {
                let field = "contact_infos";
                let list = getValues(field) || [];
                list.push({});
                setValue(field, list, { shouldDirty: true });
                setRefresh(!refresh);
              }}
            />
          </h3>
          <p>Set contact information for this league</p>
        </div>
        <div className={FormStyles.fields}>
          <ContactInfos useTitle
            data={getValues("contact_infos") || formData.contact_infos}
            register={register} setValue={setValue}
            errors={errors} setError={setError}
            refresh={() => setRefresh(!refresh)}
          />
        </div>

        <div className={FormStyles.fieldDescription}>
          <h3>
            Social Medias
            <AddIcon
              className={FormStyles.add_icon}
              onClick={() => {
                let field = "social_medias";
                let list = getValues(field) || [];
                list.push({});
                setValue(field, list);
                setRefresh(!refresh);
              }}
            />
          </h3>
          <p>Set social medias of this tournament</p>
        </div>
        <div className={FormStyles.fields}>
          <SocialMedias
            data={getValues("social_medias") || formData.social_medias}
            register={register} setValue={setValue}
            errors={errors.social_medias}
            refresh={() => setRefresh(!refresh)}
          />
        </div>
        <div className={FormStyles.fieldDescription}>
          <h3>
            Round Robin Points
          </h3>
        </div>
        <div className={FormStyles.fields}>
          <CheckboxFormInput
            register={register} setValue={setValue}
            value={formData?.use_round_robin_points}
            onChange={v => formData.use_round_robin_points = v}
            name={`use_round_robin_points`}
            label="Use Round Robin Points"
            errors={errors && errors?.use_round_robin_points}
          />
        </div>

        <div className={FormStyles.fieldDescription} />
        <div className={FormStyles.fields}>
          {hasChange && <span className="warning">Data has changed</span>}
          <FormActionButtons
            setMessage={setMessage}
            isSubmitting={isSubmitting}
            doCopy={data&&data.id? doCopy:undefined}
            doDelete={doDelete}
            message={message}
            id={formData.id}
          />
        </div>
      </form>
    </div>
    ;
}

const ActionsMenu = ({ setFormData }) => {
  const [actionsMenu, setActionsMenu] = useState(null);
  const isActionsMenuOpen = Boolean(actionsMenu);

  const handleActionsMenuOpen = (event) => {
    setActionsMenu(event.currentTarget);
  };
  const handleActionsMenuClose = () => {
    setActionsMenu(null);
  };
  return (
    <div className={FormStyles.actionMenuWrapper}>
      <Button
        id="actions-menu-button"
        aria-controls={isActionsMenuOpen ? "actions-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={isActionsMenuOpen ? "true" : undefined}
        onClick={handleActionsMenuOpen}
      >
        <IonIcon icon={ellipsisVertical} className={FormStyles.actionsMenuIcon} />
      </Button>
      <Menu
        id="actions-menu"
        anchorEl={actionsMenu}
        open={isActionsMenuOpen}
        onClose={handleActionsMenuClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          onClick={() => {
            let s = TournamentSchema().model;
            setFormData(s);
            handleActionsMenuClose();
          }}
        >
          New
        </MenuItem>
        <MenuItem onClick={handleActionsMenuClose}>Clone</MenuItem>
        <MenuItem onClick={handleActionsMenuClose}>Delete</MenuItem>
      </Menu>
    </div>
  );
};

function TShirt({ setValue, data, register, setError, onChange }) {
  const [tShirt, setTShirt] = useState({});
  const [refresh, setRefresh] = useState(false);
  useEffect(() => {
    setTShirt(data);
    if (data) {
      setValue('t_shirt', data);
    }else {
      setValue('t_shirt', {sizes: []});
    }
  }, [data]);
  return (
    <div className="TShirt">
      <div className={FormStyles.fieldDescription}>
        <h3>Expire Date Time</h3>
      </div>
      <div
        className={FormStyles.fields}
      >
        <DateFormInput1 
          name="t_shirt.expiration_date"
          hasTime
          label="Expiration Date"
          setValue={setValue}
          value={tShirt?.expiration_date}
          onChange={onChange}
        />
      </div>
      <div className={FormStyles.fieldDescription}>
        <h3>
          Sizes
          <AddIcon
            className={FormStyles.add_icon}
            onClick={() => {
              tShirt.sizes.push({});
              setTShirt({...tShirt});
              onChange();
            }}
          />
        </h3>
      </div>
      {(tShirt?.sizes || []).map((t, i) => {
        return (
          <div className={`${FormStyles.fields} Size`} key={i} style={{ display: "grid" }}>
            <div>
              <TextFormInput
                value={t.name}
                name={`t_shirt.sizes[${i}].name`}
                label="Name"
                register={register} setValue={setValue}
                isRequired
                onChange={v => {
                  v && setError(`t_shirt.sizes[${i}].name`, undefined);
                  onChange();
                }}
              />
              <TextFormInput
                value={t.code}
                name={`t_shirt.sizes[${i}].code`}
                label="Code"
                register={register} setValue={setValue}
                isRequired
                onChange={v => {
                  v && setError(`t_shirt.sizes[${i}].code`, undefined);
                  onChange();
                }}
              />
            </div>
            <RemoveSubFormIcon
              right
              onClick={() => {
                tShirt.sizes.splice(i, 1);
                setValue('t_shirt', tShirt);
                setRefresh(!refresh);
                onChange();
              }}
              label={`T-Shirt ${i + 1}`}
            />
          </div>
        );
      })}
    </div>
  );
}

function RegistrationRequirements({
  teams,
  value,
  register,
  errors,
  control,
  setValue,
}) {
  const [getTeams, setTeams] = useState(teams);
  const [refresh, setRefresh] = useState(false);
  useEffect(() => {
    setTeams(teams);
  }, [teams]);

  const filterTeams = (searchVal) => {
    return getTeams?.filter(
      (t) =>
        !searchVal || t.display.toLowerCase().includes(searchVal.toLowerCase())
    );
  };

  return (
    <div>
      <CheckboxFormInput
        register={register}
        value={value?.team}
        name={`registration_requirements.team`}
        label="League Team Membership"
        errors={errors && errors?.team}
      />
      <CheckboxFormInput
        register={register}
        value={value?.league}
        name={`registration_requirements.league`}
        label="League Membership"
        errors={errors && errors?.league}
      />
      <MultiSelectFormInput
        control={control}
        value={value?.restricted_to_teams || []}
        name="restricted_to_teams.restricted_to_teams"
        label="Restricted to League Teams"
        optionLabel="display"
        optionValue="id"
        fetchOptions={(searchVal) => filterTeams(searchVal)}
        onChange={(v) => {
          value.restricted_to_teams = v;
          setRefresh(!refresh);
        }}
      />
    </div>
  );
}

function Publish({ value, register, errors, setValue }) {
  const defaultCompetitorColumns = headCells_registration.filter(hc => hc.default).map(hc => hc.id);
  const [competitorsColumns, setCompetitorsColumns] = useState(defaultCompetitorColumns);
  const [isCompetitors, setIsCompetitors] = useState(false);
  useEffect(() => {
    if (!value) {
      return;
    }
    if (value.competitor_columns) {
      setCompetitorsColumns(value.competitor_columns.split('|'));
    }
    setIsCompetitors(value.competitors);
  }, [value]);

  return (
    <div className="Publish">
      <div className="competitors">
        <CheckboxFormInput
          register={register} setValue={setValue}
          value={value?.competitors}
          onChange={v => setIsCompetitors(v)}
          name={`publish.competitors`}
          label="Competitors"
          errors={errors && errors?.competitors}
        />
        {isCompetitors && 
          <div className="registration-columns">
            {headCells_registration.filter(hc => hc.publish).map((hc, i) => {
              return <CheckboxFormInput key={i}
                name={`publish.registration.${hc.id}`}
                value={competitorsColumns.includes(hc.id)}
                label={`${hc.label}`}
                onChange={v => {
                  let cols = competitorsColumns.filter(c => c !== hc.id);
                  if (v) {
                    cols.push(hc.id);
                  }
                  value.competitor_columns = cols.join('|');
                  setValue('publish.competitor_columns', value.competitor_columns);
                  setCompetitorsColumns(cols);
                }}
              />;
            })}
          </div>}
      </div>
      <CheckboxFormInput
        register={register} setValue={setValue}
        value={value?.brackets}
        name={`publish.brackets`}
        label="Brackets"
        errors={errors && errors?.brackets}
      />
      <CheckboxFormInput
        register={register} setValue={setValue}
        value={value?.schedules}
        name={`publish.schedules`}
        label="Schedules"
        errors={errors && errors?.schedules}
      />
      <CheckboxFormInput
        register={register} setValue={setValue}
        value={value?.results}
        name={`publish.results`}
        label="Results"
        errors={errors && errors?.results}
      />
      <CheckboxFormInput
        register={register} setValue={setValue}
        value={value?.teams}
        name={`publish.teams`}
        label="Teams"
        errors={errors && errors?.teams}
      />
      <CheckboxFormInput
        register={register} setValue={setValue}
        value={value?.gyms}
        name={`publish.gyms`}
        label="Gyms"
        errors={errors && errors?.gyms}
      />
    </div>
  );
}

function Locations({ register, setValue, setFormData, formData, clearErrors, setError, errors }) {
  // console.log('errors:', errors);
  return <div className="Locations">
    {(formData.locations || []).map((l, i) => {
      const {country='', state='', city='', region='', suburb='', address='', zip=''} = l;
      return <div className="location" key={i}>
        <AddressForm
          border={true}
          address={{country, state, city, region, suburb, address, zip}}
          name={`locations.${i}.`}
          register={register}
          setValue={setValue}
          isRequired={['address', 'city']}
        />
        <RemoveSubFormIcon
          onClick={() =>
            handleSubListDelete(setFormData, formData, "locations", i)
          }
          label={`Location ${i + 1}`}
        />
      </div>
    })}
  </div>
}

