import './CartsPage.scss';

import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { IonIcon, IonLoading, IonSpinner } from "@ionic/react";
import UserModel from '../../serverUtils/models/UserModel';
import React, { useEffect, useState, useRef, forwardRef, useImperativeHandle } from 'react';
import { useHistory } from 'react-router-dom';
import TournamentModel from '../../serverUtils/models/TournamentModel';
import GymModel from '../../serverUtils/models/GymModel';
import LeagueModel from '../../serverUtils/models/LeagueModel';
import TeamModel from '../../serverUtils/models/TeamModel';
import Utils from '../../serverUtils/Utils';
import TableFormInput, { CellRenderer, TableCellEditable } from '../../components/FormInput/TableFormInput';
import { FormControlLabel, FormLabel, Link, Radio, RadioGroup, TableCell, TableRow } from '@mui/material';
import { RequestCart, RequestUtils } from '../../serverUtils/requests';
import { PAYMENT } from '../../components/Form/PaymentInfos';
import CartModel from '../../serverUtils/models/CartModel';
import {
    trashBinOutline as deleteIcon,
} from "ionicons/icons";
import { default as CheckBoxIcon } from '@mui/icons-material/CheckBox';
import AlertPane from '../../components/FormInput/AlertPane';
import { useStore } from '../../Store';
import TextFormInput from '../../components/FormInput/TextFormInput';
import { TournamentSchema, convertDate } from "../../serverUtils/Models";
import { getDateFormat } from "../../components/FormInput/DateFormInput1";
import { default as DeleteIcon } from "@mui/icons-material/Delete";
import { FormControl } from "@mui/base";
const { STORE_ITEM_TYPE } = require("../../serverUtils/models/modelMethods");



const CartPage = forwardRef(({ cart: _cart, disableDeleteCart = false, selected, setSelected, onSubmitOverride }, ref) => {
    const history = useHistory();
    const session = useStore(state => state.session);
    const setSession = useStore(state => state.setSession);
    const [cart, setCart] = useState();
    const [message, setMessage] = useState();
    const [cartWarning, setCartWarning] = useState();
    const [waiverMessage, setWaiverMessage] = useState('');
    const [refresh, setRefresh] = useState(false);
    const [hasTShirt, setHasTShirt] = useState(false);
    const cartSessionTimeoutRef = useRef();    
    const isGateWayOpen = useRef(false);
    const [isCheckPrice, setIsCheckPrice] = useState(false);

    useImperativeHandle(ref, () => ({
        deleteCart,
    }));

    const getBusiness = async (singleRegCarts) => {
        if (isGateWayOpen.current) {
            return;
        }
        let b;
        // if (!_cart.getBusiness) {
            if (_cart.ref_type === CartModel.REF_TYPE.tournament) {
                b = await TournamentModel.getBusinessInfo(_cart.ref);
                let crcFeeInfo = Utils.listObjectKeys(TournamentSchema().model);
                b.fee_info_crc = Utils.getCRC(b.fee_info, crcFeeInfo);
                TournamentModel.convertTournamentDates(b);
                if (b.dates.close_date && new Date().getTime () > b.dates.close_date){
                    _cart.items = _cart.items.filter(i => i.item_type !== STORE_ITEM_TYPE.tournament_registration);
                    if (_cart.items.length === 0){
                        return deleteCart(_cart);
                    }
                }else {
                    const registered = (session && b && session.getRegistrations(b.id)) || [];
                    let submittedSkus = registered.map(r => r.division);
                    let priceHasChange = false;
                    for (let item of _cart.items) {
                        let fee;
                        if (item.sku.includes(',')) {
                            fee = TournamentModel.getDivisionComboPricing(item.sku.split(','), b); 
                            if (fee) {
                                let submittedSku = Utils.intersection(fee.combo.split(','), submittedSkus);
                                let unit_price = item.unit_price;
                                if (submittedSku.length > 0){
                                    submittedSku = submittedSku.pop();
                                    let c = singleRegCarts.find(c => c.items.find(i => i.sku === submittedSku));
                                    if (c) {
                                        let i = c.items.find(i => i.sku === submittedSku);
                                        let new_unit_price = fee.amount - i.unit_price;
                                        if (new_unit_price !== unit_price) {
                                            item.unit_price = new_unit_price;
                                            priceHasChange = true;
                                        }
                                    }
                                }else if (fee.amount !== unit_price){
                                    item.unit_price = fee.amount;
                                    priceHasChange = true;
                                }              
                            }
                        }else {
                            fee = TournamentModel.getDivisionFee({
                                division: {id: item.sku}, 
                                isNumber: true, 
                                tournament: b,
                                ts: new Date().getTime(),
                                fee_info: b.fee_info
                            });
                            if (fee !== item.unit_price){
                                item.unit_price = fee;
                                priceHasChange = true;
                            }
                        }
                        console.log(fee);
                    }

                    if (priceHasChange){
                        setCartWarning('warning: Price has changed and has been updated.');
                        RequestCart.updateCart(_cart).then(r => console.log('update cart: ', r));
                    }
                }
                if (b && !b.trans_fee) {
                    let l = await LeagueModel.getLeagueInfo(b.league);
                    b.trans_fee = l ? l.trans_fee : 0;
                }

            }else if (_cart.ref_type === CartModel.REF_TYPE.gym) {
                b = await GymModel.getGymInfo(_cart.ref);
            }else if (_cart.ref_type === CartModel.REF_TYPE.league) {
                b = await LeagueModel.getLeagueInfo(_cart.ref);
            }else if (_cart.ref_type === CartModel.REF_TYPE.team) {
                b = await TeamModel.getTeamInfo(_cart.ref);
            }
            _cart.getBusiness = () => b;
        // }
        _cart.is_waiver = !getWaiver(_cart);
        setCart(_cart);
        return b;
    }

    useEffect(() => {
        setCart(_cart);
        if (session && _cart) {
            CartModel.getCartsByRefMembership(_cart.ref)
                .then(carts => {
                    let singleRegcarts = carts.filter(c => 
                        c.items.find(i => 
                            i.item_type===STORE_ITEM_TYPE.tournament_registration && i.sku.split(',').length===1)
                    );
                    getBusiness(singleRegcarts).then(businsess => {
                        setRefresh(!refresh);
                    });
                    setHasTShirt(carts.find(c => c.t_shirt));
                });
            cartSessionTimeoutRef.current && clearTimeout(cartSessionTimeoutRef.current);
            cartSessionTimeoutRef.current = setTimeout(() => {
                console.log('check price: ', new Date());
                !isGateWayOpen.current && setIsCheckPrice(!isCheckPrice);
            }, 60000);
        }
    }, [session, _cart, isCheckPrice]);

    const onSubmit = (async result => {
        setWaiverMessage('');
        if (result === 'is_waiver') {
            return setWaiverMessage('You must agree and check the waiver to continue.');
        }
        if (onSubmitOverride){
            return onSubmitOverride(result);
        }
        console.log(cart);
        cart.payment_status = typeof result === 'object'? JSON.stringify(result) : result;
        let r = await CartModel.submitCart(cart);
        if (r && r.id){
            let carts = session.getCarts().filter(c => c.id !== r.id);
            session.getCarts = () => carts;
            setMessage('success: Successfully submitted cart.');
            await UserModel.getSession(setSession);
            setTimeout(() => {
                document.location.href = `/${cart.ref_type}s/${cart.ref}`;
            }, 2000);
            return r.id;
        }else {
            setMessage('error: Error submitting cart.  Please try again.')
        }
    });

    const deleteCart = async (_c = cart) => {
        if (!_c) {
            return;
        }
        let result = await CartModel.deleteCart(_c.id);
        if (result && result.id) {
            let carts = session.getCarts().filter(c => c.id !== result.id);
            session.getCarts = () => [...carts];
            setMessage('sucess: Successfully deleted cart.');
            setTimeout(() => {
                setCart(null);
                setSession({...session});
            }, 3000);
        }
    }

    const getWaiver = c => (c && c.getBusiness && c.getBusiness() && c.getBusiness().waiver) || '';
    let waiver = getWaiver(cart);
    const getTournamentDate = () => {
        if (cart.getBusiness()?.dates?.start_date) {
            return ` - ${Utils.formatDate(cart.getBusiness()?.dates?.start_date)}`;
        }
        return '';
    }
    const canProceedCart = () => session && session.status === 'A';
    return <div className={'CartPage'} 
                onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    setSelected(cart);
                }}>
        <AlertPane message={cartWarning} setMessage={setCartWarning} isFloat timeOut={30000}/>
        {!session||!cart||!cart.getBusiness || !cart.getBusiness()? <IonLoading/> :
            <div className={'cart'}>
                {cart.ref_display ?
                    <Link onClick={() => history.push(`/${cart.ref_type}s/${cart.ref}`)} className="entity-link">
                        <span className="cart-name">{cart.ref_display}</span>
                        <span className="tournament-date">{getTournamentDate()}</span>
                    </Link> :
                    <span>{cart.getBusiness().name}</span>}
                {!disableDeleteCart && 
                    <button className="button button-cart"
                        onClick={() => deleteCart(cart)}>
                        <IonIcon icon={deleteIcon} />
                        Delete Cart (all items)</button>}
                <CartItems cart={cart} 
                    setCart={setCart} 
                    session={session} 
                    deleteCart={!disableDeleteCart && (() => deleteCart(cart))}
                    hasTShirt={hasTShirt}
                    />
                <hr/>
                {canProceedCart() && waiver && <div className="waiver-wrapper">
                    <h5>Terms and Conditions</h5>
                    <p className="waiver">{waiver}</p>
                    <div className="buttons">
                        <button disabled={session && session.status !== 'A'} className="button" 
                            onClick={e => {
                                if (session && session.status !== 'A') {
                                    return;
                                }
                                cart.is_waiver = true;
                                setCart({ ...cart });
                                setRefresh(!refresh);
                            }}>
                            <h5>
                                {cart.is_waiver && <CheckBoxIcon />}
                                <span>I agree</span>
                            </h5>
                        </button>
                        {!cart.is_waiver && <button disabled={session && session.status !== 'A'} className="button" 
                            onClick={() => setMessage('warning: You must agree to the terms and condiditons above before you can submit your registration')}>
                            <h5>I disagree</h5>
                        </button>}
                    </div>
                    <AlertPane message={message} timeOut={3000} setMessage={setMessage}/>
                    <span className="message" style={{color: 'red'}}>{waiverMessage}</span>
                </div>}
                {!canProceedCart() && 
                    <AlertPane omitClose message={'warning: Your GIH status is not active.  You will to complete your email validation before you can register for this tournament.'}/>}
                
                <div elevation={3} style={{ visibility: cart.is_waiver ? 'unset' : 'collapse' }}>
                    <hr key={0} />
                    <h3 key={1}>Payment Options</h3>
                    <PaymentOptions key={2} 
                        cart={cart} 
                        onSubmit={onSubmit} 
                        selected={selected} 
                        onGatewayOnpen={() => isGateWayOpen.current = true}/>
                </div>
            </div>
        }
    </div>
    
});

const headCells = [

    {
        id: 'description',
        numeric: false,
        disablePadding: true,
        label: 'Item',
        align: 'left'
    },
    {
        id: 'unit_price',
        numeric: false,
        disablePadding: true,
        label: 'Price',
    },
    {
        id: 'quantity',
        disablePadding: true,
        label: 'Qty',
        align: 'center'
    },
    {
        id: 'tax',
        numeric: false,
        disablePadding: true,
        label: 'Tax',
    },
    {
        id: 'shipping',
        numeric: false,
        disablePadding: true,
        label: 'Shipping',
    },
    {
        id: 'sub_total',
        numeric: false,
        disablePadding: true,
        label: 'Sub Total',
    },
]

const tableComparator = (a, b, orderBy) => {

}

const TRANS_FEE_ITEM = {
    sku: 'GIH_TRANS_FEE',
    name: 'Transaction Fee',
    unit_price: 2.00,
    quantity: 1,
    item_type: 'transaction_fee',
    description: 'Non-refundable transaction fee',
}

export const deleteCartItem = async (cart, item) => {
    cart.items = cart.items.filter(i => i.sku !== item.sku);
    if (cart.items.length === 0) {
        return -1;
    }
    let response = await RequestCart.updateCart(cart);
    let result = RequestUtils.getResponseData(response);
    if (result && result.id) {
        return 1;
    }
    return 0;
}

const CartItems = ({ cart, setCart, session, deleteCart, hasTShirt }) => {
    const [message, setMessage] = useState();
    const [promo, setPromo] = useState();
    const [promoMessage, setPromoMessage] = useState();
    const [refresh, setRefresh] = useState(false);
    const [cell, setCell] = useState();
    const setSession = useStore(state => state.setSession);
    const tableRef = useRef(); 
    useEffect(() => {
        let { trans_fee } = cart.getBusiness();
        if (trans_fee && parseFloat(trans_fee) > 0 && !cart.items.find(i => i.sku === 'GIH_TRANS_FEE')) {
            let tf = { ...TRANS_FEE_ITEM, amount: parseFloat(trans_fee) };
            cart.items.unshift(tf);
            setRefresh(!refresh);
        }
    }, [cart]);

    const doDelete = async (item) => {
        setMessage('');
        let r = await deleteCartItem(cart, item);
        if (r === 1){
            setTimeout(() => {
                setSession({...session});
            }, 2000);
            setCart({...cart});
        }else if (r === -1) {
            deleteCart();
        }else {
            setMessage('Error updating cart.');
        }
    }

    const getTotal = () => {
        let total = 0;
        cart.items.forEach(it => {
            let amount = (it.quantity||1) * (it.unit_price||0);
            total += amount + (it.tax||0) + (it.shipping||0);
        });
        if (cart.promo) {
            total -= cart.promo.amount;
        }
        return total;
    }

    const Description = ({row}) => {
        let descs = (row.description || '').split(':');
        if (row.item_type === STORE_ITEM_TYPE.tournament_registration && descs.length > 1) {
            let comboName = descs[0].split('-');
            if (comboName.length > 1) {
                comboName = comboName[0];
            }else {
                comboName = 'Combo';
            }
            return <div className="Description">
                <span className="combo-name">{comboName}:</span>
                <ul>
                    {descs.map((d, i) => <li key={i}>{d}</li>)}
                </ul>
            </div>
        } 
        return row.cart_description || row.description;
    }

    let currency = (cart.getBusiness() && cart.getBusiness().currency) || '';
    return <div className="CartItems-wrapper" onClick={e => e.stopPropagation()}>
        <AlertPane message={message} />
        <TableFormInput name="CartItems"
            title="Items" ref={tableRef} id="sku" isDense omitSelectAll
            isEditable
            headCells={headCells}
            comparator={tableComparator}
            data={cart.items}
            renderTRow={({ row = {}, isSelected, index, handleClick }) => {
                const isItemSelected = isSelected(row.sku);
                const labelId = `enhanced-table-checkbox-${index}`;
                let amount = (row.quantity || 1) * (row.unit_price || 0);
                row.sub_total = amount + (row.tax || 0) + (row.shipping || 0);
                let isTransFeeRow = row.sku === TRANS_FEE_ITEM.sku;
                const isNotEditable = () => ['transaction_fee', 'tournament_registration'].includes(row.item_type);
                return <TableRow key={index} className="TableRow"
                    hover
                    onClick={(event) => {
                        event.stopPropagation();
                        !isTransFeeRow && handleClick(event, row.sku);
                    }}
                    role={!row.trans_fee && "checkbox"}
                    aria-checked={!row.trans_fee && isItemSelected}
                    tabIndex={-1}
                    selected={!row.trans_fee && isItemSelected}
                >
                    <TableCell className="index">
                        <DeleteIcon className="DeleteIcon" onClick={e => {
                            e.stopPropagation();
                            doDelete(row);
                        }}/>
                        {index + 1}
                    </TableCell>
                    <TableCell className="description">
                        <Description row={row} />
                    </TableCell>
                    <CellRenderer display={`${Utils.toFloatString(amount, 2)}${currency}`} align="center" />
                    {isNotEditable() ? <CellRenderer display={row.quantity || 1} align="right" /> : 
                    <TableCellEditable setCell={setCell}
                        field="quantity"
                        index={index}
                        value={row.quantity || 1}
                        row={row}
                        label={'Qty'}
                        setValue={v => row.quantity = v} />}
                    <CellRenderer display={`${Utils.toFloatString(row.tax, 2)}${currency}`} align="center" />
                    <CellRenderer display={`${Utils.toFloatString(row.shipping, 2)}${currency}`} align="center" />
                    <CellRenderer display={`${Utils.toFloatString(row.sub_total, 2)}${currency}`} align="center" />
                </TableRow>
            }} />
        {!hasTShirt && <TShirt cart={cart} />}
        {cart.getBusiness().getPromo && cart.getBusiness().getPromo().length > 0 &&
            <div className="Promo">
                <h5>Promo Code: </h5>
                <div className="promo-fields">
                    <div style={{ maxWidth: 200 }}>
                        <TextFormInput
                            name="promo"
                            onChange={e => {
                                setPromo(e.target.value.toUpperCase());
                            }}
                            errorText={promoMessage}
                            value={promo}
                            label="Enter Promo Code"
                        />
                    </div>
                    <button className="button"
                        onClick={() => {
                            setPromoMessage('');
                            if (!promo) {
                                return setPromoMessage('Promo field is empty');
                            }
                            TournamentModel.applyPromo({ code: promo, session, cart })
                                .then(() => {
                                    if (!cart.promo) {
                                        setPromoMessage('Promo is invalid')
                                    }
                                })
                        }}
                    >Apply Promo Code</button>
                </div>
            </div>}
        <h5 className="Promo-message">{cart.promo && `Promo Discount: ${cart.promo.amount.toFixed(2)}${currency}`}</h5>
        <h4 className="Total">Total: {Utils.toFloatString(getTotal(), 2)}{currency}</h4>
    </div>

}

const PaymentOptions = ({cart={}, onSubmit, onGatewayOnpen}) => {
    const initPaymentType = () => {
        if (cart?.payment?.payment_type) {
            return cart?.payment?.payment_type
        }
        let type = Object.keys(cart.getBusiness()?.payment_info || {});
        if (type.length > 0) {
            return cart.getBusiness()?.payment_info[type[0]].payment_type;
        }
    }
    const [paymentType, setPaymentType] = useState(initPaymentType());

    useEffect(() => {
        if (!cart.payment) {
            cart.payment = {};
        }
        cart.payment.payment_type = paymentType;
    }, [paymentType]);

    const {total} = getCartDetails(cart);
    return total === 0? 
        <div className="zero-balace-payment">
            <span className="warning">Your Balance is zero.  Click continue to complete cart submission.</span>
            <button className="button" 
            onClick={e => {
                e.stopPropagation();
                onSubmit('balance zero transaction');
            }}>Continue</button>
        </div>:
        <div className="PaymentOptions" onClick={e => e.stopPropagation()}>
            <FormControl>
                <FormLabel id="payment-options">Payment Options</FormLabel>
                <RadioGroup
                    aria-labelledby="payment-options-radio-buttons-group"
                    name="payement-option-controlled-radio-buttons-group"
                    value={paymentType}
                    onChange={e => setPaymentType(e.target.value)}
                >
                    {cart.getBusiness()?.payment_info.map((p, i) => {
                        return <div key={i} className="payment-type">
                            <FormControlLabel value={p.payment_type} control={<Radio />} label={p.label} />
                        </div>
                    })}
                </RadioGroup>
            </FormControl>
            <div className="payment">
                {paymentType==='paypal' && <PayPal cart={cart} onSubmit={onSubmit} onGatewayOnpen={onGatewayOnpen}/>}
                {paymentType==='inperson' && <InPerson cart={cart} onSubmit={onSubmit}/>}
                {paymentType==='square' && <Square cart={cart} onSubmit={onSubmit}/>}
            </div>
        </div>
}

const getCartDetails = (cart) => {
    function getDescription(item_type){
        if (!item_type || item_type === STORE_ITEM_TYPE.store_item) {
            return '';
        }
        return item_type.split('_').map(w => Utils.capitalize(w)).join(' ');
    }
    let total = 0;
    let purchase_units = cart.items.map(item => {
        let amount = item.unit_price * (item.quantity || 1);
        total += amount;
        return {
            amount: {
                value: amount
            },
            reference_id: `${cart.id}-${item.sku}`,
            description: cart.ref_display + ` ${getDescription(item.item_type)}`,
            custom_id: item.sku
        };
    }).filter(i => i);
    
    return {total, purchase_units};
}

export const PayPal = ({cart, onSubmit, onGatewayOnpen}) => {
    const [message, setMessage] = useState();
    const paypalDialogRef =useRef();
    useEffect(() => {
        let pi = cart.getBusiness().payment_info.find(pi => pi.payment_type==='paypal');
        loadSDK(pi.account, pi.clientId || pi.location_id);
    }, [cart]);

    const renderPayPal = () => {
        paypalDialogRef.current.innerHTML = '';
        let buttonConfig = {
            createOrder: function (data, actions) {
                onGatewayOnpen && onGatewayOnpen();
                return actions.order.create({
                    purchase_units: getCartDetails(cart).purchase_units,
                });
            },
            onClick: function (data, actions) {
                return actions.resolve();
            },
            onApprove: function (data, actions) {
                return actions.order.capture().then(function (details) {
                    console.log('Transaction completed by ' + details.payer.name.given_name);
                    onSubmit(details);
                });
            }
        }
        window.paypal && window.paypal.Buttons(buttonConfig).render(paypalDialogRef.current);
    }

    const loadSDK = (merchantId, clientId) => {
        let url = `https://www.paypal.com/sdk/js?client-id=${clientId}&merchant-id=${merchantId}`;
        let isLoaded = false;
        let scripts = document.body.querySelectorAll('script');
        for (let i = 0; i < scripts.length; i++) {
            let script = scripts[i];
            if (script.src === url) {
                isLoaded = true;
                break;
            }
            if (script.src.startsWith('https://www.paypal.com/sdk/js?') && script.src !== url) {
                script.parentNode.removeChild(script);
            }
        }
        if (!isLoaded) {
            Utils.loadExternalJS(url)
                .then(r => {
                    console.log('loadPayPalSDK:', url, r, window.paypal && window.paypal.getCorrelationID());
                    if (!document.body.querySelectorAll && document.body.querySelectorAll('script').find(s => s.src.includes('https://www.paypal.com/sdk'))) {
                        return setMessage('Error cannot load the PayPal SDK.  Try restarting your browser.');
                    }
                    renderPayPal();
                });
        } else {
            renderPayPal();
        }

    }

    return (
        <div className="PayPal">
            <span className="message" style={{color: 'red'}}>{message}</span>
            <div id="payment_dialog" ref={paypalDialogRef}>
                <IonSpinner className="spinner"/>
            </div>
        </div>
    )
}

const InPerson = ({cart, onSubmit}) => {
    const getEntityOwnerType = () => {
        if (cart.ref_type === CartModel.REF_TYPE.tournament) {
            return 'tournament promoter';
        }
        if (cart.ref_type === CartModel.REF_TYPE.league) {
            return 'league owner';
        } 
        if (cart.ref_type === CartModel.REF_TYPE.gym) {
            return 'gym owner';
        } 
        if (cart.ref_type === CartModel.REF_TYPE.team) {
            return 'team owner';
        } 
    }
    const _message = `By clicking InPerson button,
                    your purchases are added to our system.  The ${getEntityOwnerType()} will coordinate your fees
                    and purchase product.  Once your payment is confirmed, your status will be updated.
                    A notification email will be send to for acknowledge of this purchase.
                    If you have already paid, please give the app time to update your status.`;
    let inperson = cart.getBusiness().payment_info.find(pi => pi.payment_type === PAYMENT.inperson.value);                
    let message = _message || inperson.cart_notes;
    return (
        <div className="inperson-checkout" style={{ display: 'inline-flex' }}>
            <button className="button" onClick={() => {
                onSubmit(cart.is_waiver? false:'is_waiver');
            }}>Checkout</button>
            <span style={{marginLeft: 20, fontStyle: 'italic'}}>
                {message}
            </span>
        </div>
    )
}

const Square = ({cart, onSubmit}) => {
    const loadSDK = async () => {
        if (!window.SqPaymentForm) {
            const url = "https://js.squareupsandbox.com/v2/paymentform";
            let scripts = document.body.querySelectorAll('script');
            for (let script of scripts) {
                if (script.src.includes('https://js.squareupsandbox.com/v2')){
                    return;
                }
            }
            let result = await Utils.loadExternalJS(url)
            console.log('loadSquareSDK:', url, result, window.paypal && window.paypal.getCorrelationID());
        }
        let paymentForm = window.SqPaymentForm && new window.SqPaymentForm({
            // Initialize the payment form elements
    
            //TODO: Replace with your sandbox application ID
            applicationId: cart.payment.account,
            inputClass: 'sq-input',
            autoBuild: false,
            // Customize the CSS for SqPaymentForm iframe elements
            inputStyles: [{
                fontSize: '16px',
                lineHeight: '24px',
                padding: '16px',
                placeholderColor: '#a0a0a0',
                backgroundColor: 'transparent',
            }],
            // Initialize the credit card placeholders
            cardNumber: {
                elementId: 'sq-card-number',
                placeholder: 'Card Number'
            },
            cvv: {
                elementId: 'sq-cvv',
                placeholder: 'CVV'
            },
            expirationDate: {
                elementId: 'sq-expiration-date',
                placeholder: 'MM/YY'
            },
            postalCode: {
                elementId: 'sq-postal-code',
                placeholder: 'Postal'
            },
            // SqPaymentForm callback functions
            callbacks: {
                /*
                * callback function: cardNonceResponseReceived
                * Triggered when: SqPaymentForm completes a card nonce request
                */
                cardNonceResponseReceived: function (errors, nonce, cardData) {
                    if (errors) {
                        // Log errors from nonce generation to the browser developer console.
                        console.error('Encountered errors:');
                        errors.forEach(function (error) {
                            console.error('  ' + error.message);
                        });
                        alert('Encountered errors, check browser developer console for more details');
                        return;
                    }
                    //TODO: Replace alert with code in step 2.1
                    // alert(`The generated nonce is:\n${nonce}`);
                    const idempotency_key = uuidv4();
                    UserModel.processSquarePayment({
                        nonce: nonce,
                        idempotency_key: idempotency_key,
                        amount: purchaseDetails.total,
                        ref: cart.ref,
                        ref_type: cart.ref_type
                    }).then(onSubmit);
                }
            }
        });
        paymentForm.build();
    }
    const uuidv4 = () => {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) =>{
            const r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }
    useEffect(() => {
        loadSDK();
    }, [cart]);

    const _message = ` Your credit card information will be directed to the Square payment gateway for process.
                        Once your payment is confirmed, your status will be updated.
                        A notification email will be send to for acknowledge of this purchase.
                        If you have already paid, please give the app time to update your status.`;
    
    let square = cart.getBusiness().payment_info.find(pi => pi.payment_type === PAYMENT.square.value);
    let message = square.cart_notes || _message;
    let purchaseDetails = getCartDetails(cart);

    return <div className="atom-squarepaymentform">
            <div id="form-container">
                <div id="sq-card-number"></div>
                <div className="third" id="sq-expiration-date"></div>
                <div className="third" id="sq-cvv"></div>
                <div className="third" id="sq-postal-code"></div>
                <button id="sq-creditcard" className="button-credit-card"
                        onClick={event => {
                            if (!cart.is_waiver){
                                return onSubmit('is_waiver');
                            }
                            if (cart.payment.account){
                                this.onGetCardNonce(event);
                            }
                        }}>
                    Pay {`${cart.getBusiness().currency} ${purchaseDetails.total}`}</button>
                <span className="notes">{message || square.notes}</span>
            </div>
        </div>;
}

const Stripe = () => {
    const [succeeded, setSucceeded] = useState(false);
    const [error, setError] = useState(null);
    const [processing, setProcessing] = useState('');
    const [disabled, setDisabled] = useState(true);
    const [clientSecret, setClientSecret] = useState('');
    const stripe = useStripe();
    const elements = useElements();

    const handleChange = async (event) => {
        setDisabled(event.empty);
        setError(event.error ? event.error.message : '');
    };

    const handleSubmit = async (ev) => {
        ev.preventDefault();
        setProcessing(true);

        const payload = await stripe.confirmCardPayment(clientSecret, {
            payment_method: {
                card: elements.getElement(CardElement)
            }
        });

        if (payload.error) {
            setError(`Payment failed ${payload.error.message}`);
            setProcessing(false);
        } else {
            setError(null);
            setProcessing(false);
            setSucceeded(true);
        }
    };

    const fetchPaymentIntent = async () => {
        const response = await fetch('http://localhost:4242/create-payment-intent', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ amount: 2000 }) // Example amount in cents
        });
        const { clientSecret } = await response.json();
        setClientSecret(clientSecret);
    };

    useEffect(() => {
        fetchPaymentIntent();
    }, []);

    return (
        <form id="payment-form" onSubmit={handleSubmit}>
            <CardElement id="card-element" onChange={handleChange} />
            <button disabled={processing || disabled || succeeded} id="submit">
                <span id="button-text">
                    {processing ? <div className="spinner" id="spinner"></div> : "Pay now"}
                </span>
            </button>
            {error && <div className="card-error" role="alert">{error}</div>}
            {succeeded && <p className="result-message">Payment succeeded!</p>}
        </form>
    );
}

const TShirt = ({cart}) => {
    const [business, setBusiness] = useState();
    const [refresh, setRefresh] = useState(false);
    useEffect(() => {
        cart && setBusiness(cart.getBusiness());
    }, [cart]);
    const isExpired = () => {
        if (!business.t_shirt.expiration_date) {
            return false;
        }
        let expiredDate = convertDate(business.t_shirt.expiration_date, business.time_zone);
        return new Date().getTime() > expiredDate;
    };

    if (!business || !business.t_shirt || business.t_shirt.sizes.length === 0 || isExpired()) {
        return '';
    }
    return <div className="TShirt">
        <h5>Select T-Shirt Size</h5>
        <span className="expired-date">Last Date for t-shirt <b>{getDateFormat(convertDate(business.t_shirt.expiration_date), true)}</b></span>
        <RadioGroup className="options" onClick={e => e.stopPropagation()}
            aria-labelledby="t_shirt"
            value={cart.t_shirt || business.t_shirt.sizes[0].code}
            name="t_shirt" 
            onChange={e=> {
                cart.t_shirt = e.target.value;
                setRefresh(!refresh);
            }}
        >
            {business.t_shirt.sizes.map((t, i) => {
                return <FormControlLabel key={i} value={t.code} control={<Radio  />} label={t.name} />
            })}
        </RadioGroup>
    </div>
}

export default CartPage;