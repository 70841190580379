import { useHistory } from "react-router-dom";
import {
  IonContent,
  IonFab,
  IonFabButton,
  IonIcon, IonLabel,
  IonPage,
  IonSpinner,
  useIonViewWillEnter
} from "@ionic/react";
import pageStyles from "../Page.module.scss";
import { pencil, personOutline } from "ionicons/icons";
import { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import "./GymPage.scss";
import GymModel from "../../serverUtils/models/GymModel";
import { homeOutline } from "ionicons/icons";
import GymStore from "./GymStore";
import { FormImage } from "../../components/Form/Form";
import CartModel from "../../serverUtils/models/CartModel";
import UserModel from "../../serverUtils/models/UserModel";
import LoadingScreen from "../../components/LoadingScreen/LoadingScreen";
import { RequestCommon, RequestUtils } from "../../serverUtils/requests";
import GymMemberships from "./GymMemberships";
import Photos from "../../components/Form/Photos";
import { Common, ENTITY } from "../../serverUtils/Models";
import { useStore } from "../../Store";
import AlertPane from "../../components/FormInput/AlertPane";
import { Tabs } from "../../components/Tabs/Tabs";
import { PageRequestButtons } from "../PageRequestButtons";
import { STORE_ITEM_TYPE } from "../../serverUtils/models/modelMethods";
import { getFlag } from "../UserPage/UserPage";
import { default as RegisteredIcon } from "@mui/icons-material/HowToReg";
import UserChip from "../../components/UserChip/UserChip";
import TextAreaFormInput from "../../components/FormInput/TextAreaFormInput";
import { Checkbox, TextField, Tooltip } from "@mui/material";
import { ThemeProvider } from "@emotion/react";
import Theme from "../../components/FormInput/Theme";
import { MIME_TYPE } from "../DashboardPage/DashboardPage";
import GymStandings from "./GymStandings";
import { STATES, REGIONS, COUNTRIES } from "../../components/Form/AddressForm";

const TABS = [
  { html: "Map", url: "map" },
  { html: "Members", url: "memberships" },
  { html: "Standings", url: "standings" },
  { html: "Photos", url: "photos" },
  { html: "Store", url: "store" },
];

const GymPage = (props) => {
  const history = useHistory();
  const [gym, setGym] = useState();
  const session = useStore((state) => state.session);
  const setSession = useStore((state) => state.setSession);
  const [message, setMessage] = useState('');
  const [sectionIndex, setSectionIndex] = useState();
  const [isRegistered, setIsRegistered] = useState(false);
  const [flag, setFlag] = useState();
  const [membershipStatus, setMembershipStatus] = useState();
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    let params = RequestUtils.getURLParameters();
    if (params.tab) {
      setSectionIndex(TABS.findIndex((t) => t.url === params.tab));
    }
  }, []);

  useEffect(() => {
    if (session && gym) {
      getMembershipStatus(gym);
    }
  }, [session, gym]);

  const getData = async (id) => {
    let g = await GymModel.getGym(id);
    if (g) {
      let f = await getFlag(g.country);
      setFlag(f && <IonIcon className={`flag`} icon={f} />);
      setGym(g);
    }
  };

  useEffect(() => {
    getData(RequestUtils.getURLId("gyms"));
  }, [session]);

  useIonViewWillEnter(async () => {
    getData(RequestUtils.getURLId("gyms"));
  }, [session]);

  const getMembershipStatus = () => {
    let id = RequestUtils.getURLId('gyms');
    let status =
      UserModel.getMembershipStatus(
        session,
        "gyms",
        id,
        STORE_ITEM_TYPE.gym_registration,
      );

      if (status) {
        setIsRegistered(true);
      } 
      setMembershipStatus(status);
      return status;
  };

  const addToCart = async () => {
    setMessage("");
    let items = [
      {
        sku: gym?.id,
        description: `${gym?.name} gym membership registration`,
        quantity: 0,
        unit_price: gym.registration_fee,
        item_type: CartModel.STORE_ITEM_TYPE?.gym_registration,
      },
    ];
    let result = await CartModel.addToCart(
      items,
      session,
      gym,
      CartModel.REF_TYPE.gym,
      setSession
    );
    if (result && result.id) {
      setTimeout(() => {
        setMessage("");
        history.push("/carts");
      }, 5000);
    } else {
      setMessage("Error updating cart");
    }
  };

  const isClaimed = () => gym && gym.admins.filter(a => a.id).length > 0;
  const getLocation = () => {
    let state = STATES?.[gym.country]?.find(s => s.value === gym.state)?.label;
    let region = REGIONS?.[gym.country]?.find(r => r.value === gym.region)?.label;
    let country = gym.country && COUNTRIES.find(c => c.two_code === gym.country)?.name;
    let location = [gym.city, state, region, country];
    return location.filter(e => e).join(', ');
  }

  let img = gym && gym.getImage && gym?.getImage()?.data?.join('');
  return (
    <IonPage>
      {!gym ? (
        <LoadingScreen />
      ) : (
        <IonContent>
          <div className="GymPage">
            <div className="page-title">
              <h1>
                {img && <div className="image-icon"><img src={img} /></div>}
                {gym && UserModel.isAdmin(session, ENTITY.gym, gym.id) && (
                  <IonFab horizontal="end">
                    <IonFabButton className="entity-edit"
                      routerLink={`/gyms/${props.match.params.id}/edit`}
                    >
                      <IonIcon icon={pencil} className="button"/><span>Edit</span>
                    </IonFabButton>
                  </IonFab>
                )}
                {gym.name}
                <span className="entity-type">@gym</span>
              </h1>
            </div>
            <PageRequestButtons
              session={session}
              onClick={isClaimed() && (() =>
                history.push(
                  `/messages?id=${gym.id}&message_type=gym&label=${gym.name}`
                )
              )}
              register={isRegistered? <Tooltip title="You are registered with this gym">
                <RegisteredIcon className="RegisteredIcon"/></Tooltip> :
                isClaimed() && <button
                  className="button"
                  onClick={async () => {
                    await UserModel.membershipRegistration(
                      GymModel,
                      gym,
                      session.gyms,
                      setMessage,
                      addToCart
                    );
                  }} 
                  fill="solid" color="primary">
                    Request for Association
                  </button>}
              claimProfile={session && session.status==='A' && !isClaimed() && 
                <ClaimProfile infos={gym.claim_ownership_infos} ref_id={gym.id} ref_type="Gym"
                  onDone={info => {
                    if (info) {
                      gym.claim_ownership_infos = gym.claim_ownership_infos || [];
                      gym.claim_ownership_infos.push(info);
                    }
                    setRefresh(!refresh);
                  }}
                />}
            />

            <ul className={'infoSection'}>
              <li className={pageStyles.pageContentInfoItem}>
                <IonIcon
                  slot="icon-only"
                  icon={homeOutline}
                  className={pageStyles.pageContentInfoIcon}
                />
                <IonLabel className={pageStyles.pageContentInfoLabel}>
                  Location
                </IonLabel>
                <IonLabel className={`location ${pageStyles.pageContentInfoValue}`}>
                  {getLocation()} {flag}
                </IonLabel>
              </li>

              <li className={pageStyles.pageContentInfoItem}>
                <IonIcon
                  slot="icon-only"
                  icon={personOutline}
                  className={pageStyles.pageContentInfoIcon}
                />
                <IonLabel className={pageStyles.pageContentInfoLabel}>
                  Created By
                </IonLabel>
                <IonLabel className={pageStyles.pageContentInfoValue}>
                  <UserChip membership={gym.getCreatedBy && gym.getCreatedBy()}/>
                </IonLabel>
              </li>

              <li className={pageStyles.pageContentInfoItem}>
                <IonIcon
                  slot="icon-only"
                  icon={personOutline}
                  className={pageStyles.pageContentInfoIcon}
                />
                <IonLabel className={pageStyles.pageContentInfoLabel}>
                  Admins
                </IonLabel>
                <IonLabel className={pageStyles.pageContentInfoValue}>
                  {gym.getAdmins().map(a => <UserChip membership={a}/>)}
                </IonLabel>
              </li>
          </ul>
            <Tabs
              index={sectionIndex}
              tabs={TABS}
              sections={[
                <div className={`section`}>
                  {/*{tournament && tournament?.locations.map(l => <GoogleMap location={l}/>)}*/}
                </div>,
                <GymMemberships gym={gym} />,
                <GymStandings gym={gym}/>,
                <Photos
                  entity={gym}
                  entity_type={ENTITY.gym}
                  model={GymModel}
                  session={session}
                />,
                <GymStore gym={gym} />,
              ]} />
          </div>
        </IonContent>
      )}
    </IonPage>
  );
};

export const ClaimProfile = ({ref_id, ref_type, onDone}) => {
  const [info, setInfo] = useState({});
  const [isClaimOwnerhip, setIsClaimOwnership] = useState(false);
  const [errors, setErrors] = useState({});
  const [message, setMessage] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isExternalFile, setisExternalFile] = useState(false);
  const fileInputRef = useRef();
  const FILE_MAX_SIZE = 200 * 1024;
  const loadLocalFile = (files) => {
    if (files.length === 0) {
      return;
    }
    
    let file = fileInputRef.current.files[0];
    if (file.size > FILE_MAX_SIZE) {
      setErrors({...errors, supporting_file: "Maximun size is 200K bytes"});
      return fileInputRef.current.value = '';
    }else {
      delete errors.supporting_file;
      setErrors({...errors});
    }
    let ext = file.name.split('.');
    ext = ext.length>1? ext.pop():'';
    let ftypes = Object.keys(MIME_TYPE);
    if (!ftypes.includes(ext)) {
      return setMessage(`error: Only allow file types ${ftypes.join(', ')}`);
    }
    let reader = new FileReader();
    reader["readAsDataURL"](file);
    reader.addEventListener(
      "load",
      () => {
        setInfo({...info, supporting_file: {
          data: reader.result, 
          ext, 
          name: file.name, 
          lastModifiedDate: file.lastModifiedDate.getTime(),
          size: file.size
        }});
      },
      false
    );
  }

  const submitRequest = async () => {
    if (!info.explanation) {
      return setErrors({...errors, explanation: 'This field is required'});
    }
    setIsSubmitting(true);
    let r;
    try{
      if (info.supporting_file && !isExternalFile) {
        r = await RequestCommon.uploadFile(info.supporting_file, p => console.log(p));
        if (!r || r.error) {
          return setMessage(`error: Error uploading supporting file.`);
        }
      }

      let req = {ref_type, ref_id: ref_id, 
        supporting_file: isExternalFile? info.supporting_file : r && r.id, 
        explanation: info.explanation};
      r = await Common.requestClaimProfile(req);
      if (r && !r.error) {
        setMessage('info: Your request has been submitted.  There is a grace period of 5 business days before your request can be reviewed by the GIH team.  Once approved you are allowed to perform admin privileges.')
        onDone(req);
      }else {
        setMessage('error: Error submitting your request.  Please try again.');
      }
    }catch(e) {
      setIsSubmitting(false);
    }
  }

  const isDone = () => message && message.startsWith('info:');
  return !isClaimOwnerhip? 
  <button className="button"
      onClick={() => setIsClaimOwnership(true)}>
      Claim Profile Ownership
    </button> :
  (info? 
    <ThemeProvider theme={Theme}>
      <div className="ClaimProfile">
        <TextAreaFormInput value={info.explanation} 
          isRequired
          name="explanation"
          label="Explantion *" 
          errors={errors}
          onChange={e => {
            setInfo({...info, explanation: e.target.value});
          }}/>
        <div className="supporting-file">
          <input
            ref={fileInputRef}
            hidden
            accept="*"
            type="file"
            onChange={e => {
              loadLocalFile(fileInputRef.current.files);
            }}
          />
          <TextField className="supporting-file"
            value={info?.supporting_file? info.supporting_file.name : ''}
            onClick={() => !isExternalFile && fileInputRef.current.click()}
            onChange={e => {
              if (isExternalFile) {
                info.supporting_file = e.target.value;
                setInfo({...info});
              }
            }}
            label={`Support (${Object.keys(MIME_TYPE).join(', ')})`}
            error={errors.supporting_file? true : false}
            helperText={errors.supporting_file || ''}
          />
          <div className="external-file">
            <Checkbox onChange={e => {
              info.supporting_file ='';
              setisExternalFile(e.target.checked);
            }}/>
            <span>External file (ex. https://domain.com/file.txt)</span>
          </div>
        </div>
        <AlertPane message={message}/>

        <div className="buttons">
          {!isDone() && <button className="button" onClick={submitRequest}>
            Submit request for profile ownership {isSubmitting && <IonSpinner className="spinner"/>}
          </button>}
          <button className="button" 
            onClick={() => {
              if (isDone()){
                onDone(info);
              }
              fileInputRef.current.value = '';
              setInfo({});
              setMessage('');
              setIsClaimOwnership(false);
            }}>
            {isDone()? 'Done':'Cancel'}
          </button>
        </div>
      </div> 
    </ThemeProvider>
    : '')
    
}

export default GymPage;
