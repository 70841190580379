import Utils from "./Utils";
import { RequestCommon, RequestUtils } from "./requests";

export const ENTITY = {
    gym: 'gym',
    team: 'team',
    league: 'league',
    membership: 'membership',
    tournament: 'tournament'
};

export const convertDate = (ts) => {
    if (!ts) {
        return '';
    }
    if (!isNaN(ts)){
        return parseInt(ts);
    }
    //2022-03-26T01:23:00    +01:00
    //2019-01-01T00:00:00.000+00:00
    return new Date(ts).getTime();
}

export function setDataModel(blank, selected){
    Object.keys(selected).forEach(key => {
        let val = selected[key];
        if (val == null || (Array.isArray(val) && (val.length===0 || val.filter(v => v).length===0))) {
            delete selected[key];
        }
    });
    Object.assign(blank, selected);
    return Object.assign(selected, blank);
}

function getModel(json){
    function convert(_json) {
        let keys = Object.keys(_json);
        for (let k of keys) {
            let v = _json[k];
            if (Array.isArray(v)) {
                for (let _j of v){
                    v = convert(v);
                }
            }else if (typeof v === 'object') {
                v = convert(_json[k]);
            } else {
                if (v === 'Date') {
                    v = ''; //new Date();
                }else if (v === 'Boolean'){
                    v = false;
                }else if (v === 'Number') {
                    v = 0;
                }else{
                    v = '';
                }
            }
            _json[k] = v;
        }
        return _json;
    }
    function convertGraphQL(_json, graphql, level){
        function format(k){
            return level > 0? Array(level).fill(' ').join('') + k : k;
        }
        for (let k of Object.keys(_json)) {
            let v = _json[k];
            let isArray = Array.isArray(v);
            if (!Array.isArray(_json))
                graphql.push(format(k));
            if (isArray) {
                for (let i = 0; i < v.length; i++) {
                    if (typeof v[i] === 'object') {
                        let _graphql = convertGraphQL(v, [], level + 1);
                        graphql = [...graphql, ..._graphql];
                    }
                }
            }else if (typeof v === 'object'){
                graphql = [...graphql, format('{'), ...convertGraphQL(v, [], level + 1), format('}')];
            }
        }

        return graphql;
    }
    function convertGraphQLMin(){
        if (json === 'membership'){
            return `id first_name last_name dob gender teams{id status} gyms{id status} leagues{id status} status gi_level weight weight_unit`;
        }
        if (json === 'tournament'){
            return `id name dates{start_date end_date close_date coach_change_date member_change_date refund_date} status`;
        }
        if (json === 'image'){
            return `id ref image_type length`;
        }
        return `id name status`;
    }
    //C:\pdo\git\gih-server\graphql_gih\models\json
    let jData = require(`../../../gih-server/graphql_gih/models/json/${json}.json`);
    let model = convert(Utils.copy(jData));
    // console.log(json, '\n', JSON.stringify(_json, null, 2));
    let graphql = convertGraphQL(Utils.copy(jData), [], 0);
    // console.log(json, '\n', graphql.join('\n'));
    graphql.unshift('id');
    let graphql_min = convertGraphQLMin();
    return {model, graphql: graphql.join(' '), graphql_min};
}

export const FacilitySchema = () => getModel('facility');
export const AreaSchema = () => getModel('area');


export const AgeGroupSchema = () => getModel('age_group');
export const BanSchema = () => getModel('ban');
export const BracketSchema = () => getModel('bracket');
export const BulkRegistrationSchema = () => getModel('bulk_registration');
export const CalendarEventSchema = () => getModel('calendar_event');
export const DivisionSchema = () => getModel('division');
export const DivisionComboSchema = () => getModel('division_combo');
export const DivisionTagSchema = () => getModel('division_tag');
export const GroupScoreSchema = () => getModel('group_score');
export const GymSchema = () => getModel('gym');
export const GymTransactionSchema = () => getModel('gym_transaction');
export const LeagueSchema = () => getModel('league');
export const LeagueTransactionSchema = () => getModel('league_transaction');
export const MatchSchema = () => getModel('match');
export const MembershipSchema = () => getModel('membership');
export const MessageSchema = () => getModel('message');
export const PlacementSchema = () => getModel('placement');
export const PromoSchema = () => getModel('promo');
export const RankSchema = () => getModel('rank');
export const RegistrationSchema = () => getModel('registration');
export const TeamSchema = () => getModel('team');
export const TeamTransactionSchema = () => getModel('team_transaction');
export const TournamentSchema = () => getModel('tournament');
export const ScheduleSchema = () => getModel('schedule');
export const WeightClassSchema = () => getModel('weight_class');
export const ImageSchema = () => getModel('image');
export const CartSchema = () => getModel('cart');
export const StoreItemSchema = () => getModel('store_item');
export const FriendSchema = () => getModel('friend');
export const NotificationSchema = () => getModel('notification');
export const ClaimRequestSchema = () => getModel('claim_request');
export const TransactionCreditSchema = () => getModel('transaction_credit');

export class Common {
    static unapproveClaimProfile = async ref_id => {
        const response = await RequestCommon.unapproveClaimProfile(ref_id);
        return RequestUtils.getResponseData(response);
    }

    static approvedClaimProfile = async params => {
        const response = await RequestCommon.approvedClaimProfile(params);
        return RequestUtils.getResponseData(response);
    }

    static formatResult(result) {
        const {claim_requests, memberships_doc, teams_doc, gyms_doc} = result;
        return claim_requests.map(c => {
            c.getBy = () => memberships_doc.find(m => m.id === c.by);
            c.getEntity = () => c.ref_type === 'Gym'? gyms_doc.find(g => g.id === c.ref_id) : teams_doc.find(t => t.id === c.ref_id);
            c.getAppovedBy = () => memberships_doc.find(m => m.id === c.approved_by);
            return c;
        });
    }
    static getRequestClaimProfiles = async (approved, search) => {
        const response = await RequestCommon.getClaimRequests(approved, search);
        let result = RequestUtils.getResponseData(response);
        return Common.formatResult(result);
    }

    static getRequestClaimProfile = async (id) => {
        const response = await RequestCommon.getClaimRequest(id);
        let result = RequestUtils.getResponseData(response);
        return Common.formatResult(result).pop();
    }
    
    static requestClaimProfile = async (params) => {
        const response = await RequestCommon.requestClaimProfile(params);
        return RequestUtils.getResponseData(response);
    }
}


