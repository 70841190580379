/*
 * gih_mode 
%7B%22port%22%3A8080%2C%22server_url%22%3A%22http%3A%2F%2Flocalhost%3A3001%2Fgraphql%22%2C%22auto_sync%22%3Atrue%2C%22graph_server_connected%22%3Afalse%2C%22local_server_url%22%3A%22%2Fgraphql%22%2C%22version%22%3A%22v1.0%22%2C%22is_graphql_online%22%3Atrue%7D */

import { IonButtons, IonContent, IonPage, IonSpinner } from '@ionic/react';
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import LocalServerModel from '../../serverUtils/models/LocalServerModel';
import Utils from '../../serverUtils/Utils';
import PageStyles from './../Page.module.scss';
import {
  Alert, Card,
  CardContent, Link,
  List,
  ListItem,
  ListItemText,
  ListSubheader, TableCell,
  TableRow, TextField,
  Tooltip,
  Typography
} from '@mui/material';
import { useStore } from '../../Store';
import './LocalServer.scss';
import { default as AssignIcon } from '@mui/icons-material/DriveFileRenameOutline';
import { default as SaveIcon } from '@mui/icons-material/Save';
import { default as ReloadStationsIcon } from '@mui/icons-material/Sync';
import { default as ReInitRTCIcon } from '@mui/icons-material/Cast';
import { default as ClearNotificationIcon } from '@mui/icons-material/BackspaceSharp';
import { default as CollapseIcon} from '@mui/icons-material/DoubleArrow';
import { default as SyncCloudServerIcon} from '@mui/icons-material/CloudUpload';
import { default as LockTournamentIcon} from '@mui/icons-material/Lock';
import { useHistory } from 'react-router-dom';
import LoadingList from '../../components/LoadingList/LoadingList';
import TournamentSchedules from '../../components/TournamentForm/TournamentSchedules';
import TableFormInput from '../../components/FormInput/TableFormInput';
import UserModel from '../../serverUtils/models/UserModel';
import { default as SendOutlinedIcon } from '@mui/icons-material/SendOutlined';
import Registrations from '../../components/TournamentForm/Registrations';
import BracketWrapper from "../../components/Bracket/BracketWrapper";
import AlertPane from "../../components/FormInput/AlertPane";
import { getStationFromCookie } from "../../LocalServerApp";
import SelectFormInput from "../../components/FormInput/SelectFormInput";
import Poolings from "../../components/TournamentForm/Poolings";
import TournamentModel from "../../serverUtils/models/TournamentModel";
import { STATUS } from "../../components/Bracket/Bracket";
import { isKeyEnter } from "../../components/Form/Form";

let sse;
let isInit = false;
const stations = {};
export const getRTCConnState = (rtcConn) => {
  let rtcState = rtcConn && rtcConn?.connectionState;
  let iceState = rtcConn && rtcConn?.iceConnectionState;
  if (rtcState === 'connected' && iceState === 'connected') return 'green';
  if (rtcState === 'connecting') return 'orange';
  if (rtcState === 'new') return 'yellow';
  return 'red';
}
export const isMaster = () =>
  document.location.host.includes(LocalServerModel.LOCAL_SERVER.localhost);

export const getStationName = (name) => {
  let st = getStationFromCookie();
  return name || st && (st.label || st.station);
}

export const resetLocalStation = async () => {
  let st = getStationFromCookie();
  await LocalServerModel.sendMessage({
    tournament_id: Utils.cookie(LocalServerModel.LOCAL_SERVER.local_tournament),
    from: st.station,
    reset: true,
  });
  Utils.deleteCookie(LocalServerModel.LOCAL_SERVER.local_station);
  setTimeout(() => window.location.reload(), 2000);
};

const isStationNameSet = (s) => {
  return s && s.label;
}

export const duplicate_station_error = 'duplicate_station_error';
export const initStation = ({ localServer, setLocalServer, receive }) => {
  let station = getStationFromCookie();
  let devServerUrl = Utils.cookie('gih_debug');
  let url = `${devServerUrl || ''}/local_server_sse?id=${station.station}`;
  console.log('Init sse url: ', url);
  const source = new EventSource(url);
  source.addEventListener('error', function (event) {
    // Handle the error here
    console.error('EventSource error:', event);
    receive && receive(duplicate_station_error);
  });
  let message;
  source.onmessage = (event) => {
    const { data } = event;
    try {
      message = JSON.parse(data);
      setLocalServer && setLocalServer({ ...localServer, is_graphql_online: message.is_graph_server_online });
      receive && receive(message);
    } catch (e) {
      console.log(e);
    }
  };

  if (isMaster()) {
    return !isInit && setTimeout(() => {
      LocalServerModel.sendMessage({
        tournament_id: Utils.cookie(LocalServerModel.LOCAL_SERVER.local_tournament),
        to: LocalServerModel.LOCAL_SERVER.ping,
      });
    }, 200);
  }
  
  !isInit && 
    setTimeout(() => {
      LocalServerModel.sendMessage({
        tournament_id: Utils.cookie(LocalServerModel.LOCAL_SERVER.local_tournament),
        to: LocalServerModel.LOCAL_SERVER.ping,
        from: station.station,
        label: (message && message.label) || station.label,
        type: (message && message.type) || station.type,
      });
    }, 200);
  return source;
};

const sendStationInitData = (station, to, tour) => {
  if (!station || !station.type) {
    return;
  }
  if (station.type === LocalServerModel.STATION_TYPE.score_card) {
    return sendInitScoreCard(station, to, tour);
  }
  if (station.type === LocalServerModel.STATION_TYPE.weight_in) {
    return sendInitWeightIn(station, to, tour);
  }
  if (station.type === LocalServerModel.STATION_TYPE.schedule) {
    return sendInitSchedule(station, to, tour);
  }
  if (station.type === LocalServerModel.STATION_TYPE.results) {
    return sendInitResults(station, to, tour);
  }
};

const sendInitResults = (station, to, tour) => {
  LocalServerModel.sendMessage({
    tournament_id: tour.id,
    type: LocalServerModel.STATION_TYPE.results,
    to, label: station.label,
    raw: JSON.stringify(tour.getPlacements())
  })
}

const sendInitSchedule = (station, to, tour) => {
  tour.getSchedules().then(schedules => {
    LocalServerModel.sendMessage({
      tournament_id: tour.id,
      type: LocalServerModel.STATION_TYPE.schedule,
      to, label: station.label,
      raw: JSON.stringify(schedules)
    });
  });
}

const sendInitWeightIn = (station, to, tour) => {
  return LocalServerModel.sendMessage({
    tournament_id: tour.id,
    type: LocalServerModel.STATION_TYPE.weight_in,
    to, tournament: Utils.cookie(LocalServerModel.LOCAL_SERVER.local_tournament),
    raw: JSON.stringify(tour.getRegistrations()),
    label: station.label
  });
}

const sendInitScoreCard = (station, to, tour) => {
  let message = {
    tournament_id: tour.id,
    type: LocalServerModel.STATION_TYPE.score_card,
    label: station.label,
    to, tournament: Utils.cookie(LocalServerModel.LOCAL_SERVER.local_tournament),
  }
  let {data} = station;
  if (data && Object.keys(data).length > 0) {
    message.data = JSON.stringify(station.data);
    return LocalServerModel.sendMessage(message);
  }
};

const stationsRef = React.createRef();
export default function LocalServer() {
  const addNotification = useStore((state) => state.addNotification);
  const history = useHistory();
  const localServer = useStore(state => state.local_server);
  const isBracketEntriesUpdate = useStore(state => state.isBracketEntriesUpdate);
  const setLocalServer = useStore((state) => state.setLocalServer);
  const station = useStore(state => state.station);

  const tournament = useStore(state => state.local_tournament);
  const setTournament = useStore(state => state.setLocalTournament);
  const [isSyncing, setIsSyncing] = useState(false);
  const [message, setMessage] = useState();
  const [collapse, setCollapse] = useState(new Set());
  const bracketRef = useRef();
  const schedulesRef = useRef();
  const registrationsRef = useRef();
  const poolingsRef = useRef();

  const receive = (message, tour) => {  //master receive hook
    if (message.from === LocalServerModel.STATION_TYPE.master){
      return;
    }
    if (message === duplicate_station_error) {
      setMessage(`error: Duplicate station [${Utils.cookie(LocalServerModel.LOCAL_SERVER.local_station)}]`)
    }
    let {to, from, label, type, data, 
         rtc, rtc_candidate, notification, 
         raw, stop_camera, stations: Stations } = message;
    
    if (!to && !from && !type) {
      return;
    }

    console.log(
      '\nsse_receive:',
      Utils.formatDateTime(new Date().getTime(), 'hh:mm:ss'),
      '\nmessage:',
      message
    );

    let needRefresh;
    for (let s of Object.keys(stations)){
      if (!message.clients.includes(s)){
        delete stations[s];
        needRefresh = true;
      }
    }

    let rawObj = raw && JSON.parse(raw);
    if (!to && type && rawObj) {
      for (let s of Object.keys(stations)) {
        s = stations[s];
        if (type === LocalServerModel.STATION_TYPE.weight_in) {
          let registration = tour.getRegistrations().find(r => r.id === rawObj.id);
          Object.assign(registration, rawObj);
          return registrationsRef.current.refresh();
        }
      }
    }

    let st = stations[from];
    try{
      if (from) {
        try{
          if (notification) {
            return st && addNotification([`from ${from}:`, notification]);
          }

          let isNew = false;
          if (!st) {
            stations[from] = st = { station: from };
            isNew = needRefresh = true;
          }else if (!label && !type){ //case station re-init request
            return sendStationUpdate(st, tour); 
          }
          if (to === LocalServerModel.LOCAL_SERVER.ping && 
              st.localStationRef && 
              st.localStationRef.current && 
              st.type === LocalServerModel.STATION_TYPE.camera &&
              st.rtcPeerConnection) {
            return st.localStationRef.current.sendInitRTCPeerConnection('initRTCPeerConnectionReady');
          }

          st.label = label;
          st.type = type;
          st.last_update = new Date();
          if (isNew && st) {
            return sendStationInitData(st, from, tour);
          }
          if (data) {
            st.data = Object.assign(st.data || {}, JSON.parse(data));
            console.log('station.data: ', st.label || st.station, st.data);
          }
          if (data) {
            let schedule = {...st.data.schedule,
              getBracketEntry1: () => st.data.bracket_entry1,
              getBracketEntry2: () => st.data.bracket_entry2,
            }
            schedulesRef?.current.forceUpdate(schedule);
          }
        }finally{
          if (st && st.localStationRef && st.localStationRef.current) {
            if (st.type === LocalServerModel.STATION_TYPE.camera && st.rtcPeerConnection) {
              st.localStationRef.current.rtcAnswer(from, rtc);
              st.localStationRef.current.rtcCandidate(rtc_candidate);
            }
            st.localStationRef.current.refresh();
          } 
        }
      }
    } finally {
      if (needRefresh) {
        saveStations();
        stationsRef.current && stationsRef.current.refresh();
      }
    }
  };

  const init = async () => {
    if (isInit || !localServer) {
      return;
    }
    isInit = true;
    if (!isMaster()) {
      if (!station || !station.type) {
        history.push('/local/NA');
      } else {
        history.push(`/local/${station.type}`);
      }
      return;
    }

    let tournamentId = Utils.cookie(LocalServerModel.LOCAL_SERVER.local_tournament);
    if (!tournamentId && isMaster()) {
      history.push('/tournaments');
    } else if (tournamentId) {
      let t = await LocalServerModel.getTournament(tournamentId);
      setTournament(t);
      sse = initStation({
        localServer,
        setLocalServer,
        receive: (message) => {
          receive(message, t);
        },
        history,
        forceUpdate: () => {
          stationsRef.current && stationsRef.current.refresh();
        },
      });
      
    }
  };

  useEffect(() => {
    if (!schedulesRef.current) {
      return;
    }
    schedulesRef.current.forceUpdate(typeof isBracketEntriesUpdate === 'object'? isBracketEntriesUpdate : undefined);
  }, [isBracketEntriesUpdate]);

  useEffect(() => {
    init();
  }, [localServer, stations, tournament, stationsRef]);

  const reloadStations = () => {
    for (let s of Object.keys(stations)) {
      delete stations[s];
    }
    isInit = false;
    LocalServerModel.sendMessage({
      tournament_id: tournament.id,
      to: LocalServerModel.LOCAL_SERVER.ping
    });
    // init();
  };

  const saveStations = async () => {
    let tourId = Utils.cookie(LocalServerModel.LOCAL_SERVER.local_tournament);
    if (!tourId) {
      return;
    }
    let r = await LocalServerModel.saveStations(stations, tourId);
    if (!r || r.error) {
      setMessage('error: Error saving stations');
    }
  }

  const save = async () => {
    setMessage('');
    tournament.caches.scheduleEntries = schedulesRef.current.getSchedules();
    let r = await LocalServerModel.saveTournament(tournament, stations);
    if (r && r.status > -1) {
      return setMessage('success: Successfully save local tournament.');
    }
    return setMessage('error: Error saving local tournament.');
  };

  const handleCollapse = (pane) => {
    if (collapse.has(pane)){
      collapse.delete(pane);
    }else {
      collapse.add(pane);
    }
    setCollapse(new Set([...collapse]));
  }

  const syncTournament = async () => {
    setIsSyncing(true);
    try{
      let regs = tournament.getRegistrations();
      let result;
      for (let reg of regs) {
          result = await TournamentModel.saveRegistration(reg);
          if (!result || result.error) {
            console.log('syncTournament: ', result && result.error, reg);
            return setMessage('error: Error syncing registrations');
          }else {
            reg.id = result.id;
          }
      }
      result = await TournamentModel.updateTournamentPool(tournament.id, tournament.poolings);
      if (!result || result.error) {
        console.log('syncTournament: ', result.error, tournament.poolings);
        return setMessage('error: Error syncing poolings.');
      }

      let bracketEntries = await tournament.getBracketEntries();
      let dIds = Utils.uniqArray(bracketEntries.map(be => be.division));
      for (let d of dIds) {
        let entries = bracketEntries.filter(e => e.division === d);
        let division = tournament.getAvailableDivisions().find(div => div.id === d);
        let response = await TournamentModel.updateBracketEntries(entries, tournament.id, d, division.bracket_type);
        if (!response || response.error) {
          console.log('syncTournament: ', result.error, entries);
          return setMessage('error: Error syncing brackets');
        }
      }

      let schedules = await tournament.getSchedules();
      for (let i = schedules.length - 1; i >= 0; i--) {
        let schedule = schedules[i];
        if (schedule.status === STATUS.Delete) {
            schedules.splice(i, 1);
        }
      }
      let divisions = [...new Set(schedules.map(s => s.division))];
      for (let d of divisions) {
        let updates = schedules.filter(s => s.division === d);
        let _updates = Utils.copy(updates)
            .map(s => {
                delete s.id;
                return s;
            });
        result = await TournamentModel.updateScheduleEntries(_updates, tournament.id, d);
        if (!result || result.error) {
          console.log('syncTournament: ', result.error, updates);
          return setMessage('error: Error syncing schedules to server');
        }
      }
      setMessage('success: Successfully sync local tournament to server');
    }finally{
      setIsSyncing(false);
    }
  }

  const lockTournament = () => {

  }

  return localServer && isMaster() ? (
    <IonPage id="LocalServer" className={PageStyles.page}>
      <IonContent>
        {tournament ? (
          <div className="LocalServer">
            <div className="flex-column sticky header">
              <div className="tournament-buttons">
                <button className="icon_button" onClick={save}><SaveIcon/></button>
                {localServer && localServer.is_graphql_online && 
                  <button className="icon_button" onClick={syncTournament}>
                    {isSyncing? <IonSpinner className="spinner"/> : <SyncCloudServerIcon/>}
                  </button>}
                {localServer && localServer.is_graphql_online && 
                  <button className="icon_button" onClick={lockTournament}><LockTournamentIcon/></button>}
              </div>
              <div className="title">
                <h1>{tournament.name}</h1>
                <span className="entity-type">{Utils.formatDate(tournament.dates.start_date)}</span>
              </div>
              <AlertPane message={message} timeOut={3000} setMessage={setMessage}/>
            </div>
            <div className={`local_server_wrapper ${collapse==='stations'}`}>
              <div className="flex-column">
                <CollapseIcon 
                  className={`CollapseIcon ${collapse.has('stations')? 'expand':''}`} 
                  onClick={() => handleCollapse('stations')}/>
                <button className="icon_button" onClick={reloadStations}>
                  <ReloadStationsIcon style={{ cursor: 'pointer' }} /></button>
                <h1 className="title">Stations</h1>
              </div>
              <div className={`module-wrapper ${collapse.has('stations')? 'expand':''}`}>
                <Stations ref={stationsRef}
                  stations={stations}
                  tournament={tournament}
                />
              </div>
            </div>
            <div className="local_server_wrapper">
              <div className="flex-column">
                <CollapseIcon 
                  className={`CollapseIcon ${collapse.has('schedules')? 'expand':''}`} 
                  onClick={() => handleCollapse('schedules')}/>
                <h1 className="title">Schedules</h1>
              </div>
              <div className={`module-wrapper ${collapse.has('schedules')? 'expand':''}`}>
                <TournamentSchedules ref={schedulesRef} tournament={tournament}/>
              </div>
            </div>
            <div className="local_server_wrapper">
              <div className="flex-column">
                <CollapseIcon 
                  className={`CollapseIcon ${collapse.has('bracket')? 'expand':''}`} 
                  onClick={() => {
                    handleCollapse('bracket');
                  }}/>
                <h1 className="title">Brackets</h1>
              </div>
              <div className={`module-wrapper ${collapse.has('bracket')? 'expand':''}`}>
                <BracketWrapper tournament={tournament} isEditMode ref={bracketRef}/>
              </div>
            </div>
            <div className="local_server_wrapper">
              <div className="flex-column">
                <CollapseIcon 
                  className={`CollapseIcon ${collapse.has('poolings')? 'expand':''}`} 
                  onClick={() => handleCollapse('poolings')}/>
                <h1 className="title">Poolings</h1>
              </div>
              <div className={`module-wrapper ${collapse.has('poolings')? 'expand':''}`}>
                <Poolings tournament={tournament} ref={poolingsRef} onChange={() => bracketRef.current.forceUpdate()}/>
              </div>
            </div>
            <div className="local_server_wrapper">
              <div className="flex-column">
              <CollapseIcon 
                  className={`CollapseIcon ${collapse.has('registrations')? 'expand':''}`} 
                  onClick={() => handleCollapse('registrations')}/>
                <h1 className="title">Registrations</h1>
              </div>
              <div className={`module-wrapper ${collapse.has('registrations')? 'expand':''}`}>
                <Registrations
                  ref={registrationsRef}
                  tournament={tournament}
                  isEditMode={true}
                  isDense={true}
                  onUpdate={row => {
                    console.log(row)
                  }} />
              </div>
            </div>
          </div>
        ) : (
          <LoadingList />
        )}
      </IonContent>
    </IonPage>
  ) : (
    <LoadingList />
  );
}

const AssignScheduleMatch = ({ tournament, schedules, station, stations, onAssigned }) => {
  const assignScheduleMatch = (schedule) => {
    let data = {
      schedule,
      bracket_entry1: schedule.getBracketEntry1(),
      bracket_entry2: schedule.getBracketEntry2(),
    }
    LocalServerModel.sendMessage({
      tournament_id: tournament.id,
      to: station.station,
      type: station.type,
      label: station.label,
      data: JSON.stringify(data),
    });
    stations[station.station].data = data;
    onAssigned(schedule);
  };

  let nonCompletes = schedules.filter(s => !s.winner);
  let divisionMap = Utils.groupBy(nonCompletes, ['division']);
  let getSchedulesByDivisions = () => {
    return Object.keys(divisionMap).filter(d => d).map(divisionId => {
      let d = tournament.getAvailableDivisions().find((d) => [d.id, d.pool].includes(divisionId));
      let dSchedules = divisionMap[divisionId].filter((s) => {
        let comp1 = s.getBracketEntry1();
        let comp2 = s.getBracketEntry2();
        return comp1 && comp2 && !comp1.result && !comp2.result;
      });
      return dSchedules.length > 0 &&
        <li key={`section-${divisionId}`}>
          <ul>
            <ListSubheader className="schedule_header">
              {d.name}
            </ListSubheader>
            {dSchedules.map((schedule, i) => (
              <ListItem key={`item-${divisionId}-${i}`}>
                <ListItemText className="schedule_item"
                  onClick={() => assignScheduleMatch(schedule)}
                  primary={
                    <div className="schedule_item_competitors">
                      <span>{schedule.getBracketEntry1().getMembership().getName()}</span>
                      <b>vs</b>
                      <span>{schedule.getBracketEntry2().getMembership().getName()}</span>
                    </div>
                  }
                />
              </ListItem>
            ))}
          </ul>
        </li>
    }).filter(s => s);
  }

  let schedulesByDivision = getSchedulesByDivisions();

  return !tournament? '' : 
    <div
      className="AssignSchedule"
      onMouseLeave={onAssigned}>
      <List
        sx={{
          width: 400,
          bgcolor: 'background.paper',
          position: 'relative',
          overflow: 'auto',
          maxHeight: 300,
          zIndex: 100,
          '& ul': { padding: 0 },
        }}
        subheader={<li />}>
        {schedulesByDivision.length === 0 ?
          <AlertPane message={'success: No more available scheduled match for this station'} /> : schedulesByDivision}
      </List>
    </div>
  ;
};

export const EmptyStation = ({ type, setStation, station, stations }) => {
  const [isEdit, setIsEdit] = useState(false);
  const [message, setMessage] = useState();

  const getLabel = () => {
    return station && (station.label ? station.label : station.station);
  }
  return (
    <Card
      sx={{ minWidth: 275, minHeight: 206 }}
      onClick={() => isMaster() && setIsEdit(!isEdit)}>
      <CardContent>
        {message && <Alert severity='error'>{message}</Alert>}
        {isEdit ? (
          <TextField
            defaultValue={getLabel()}
            onClick={(e) => e.stopPropagation()}
            id='outlined-basic'
            label='Station Name'
            variant='outlined'
            onFocus={(event) => event.target.select()}
            onKeyUp={(e) => {
              setMessage('');
              if (isKeyEnter(e)) {
                let newName = e.target.value;
                if (newName !== getLabel()) {
                  if (!Object.keys(stations || {}).includes(newName)) {
                    setStation(newName);
                    LocalServerModel.sendMessage({
                      tournament_id: Utils.cookie(LocalServerModel.LOCAL_SERVER.local_tournament),
                      to: getLabel(),
                      update: newName
                    });
                    return setIsEdit(false);
                  }
                  setMessage('error: Name choosen is invalid');
                }
              }
            }}
          />
        ) : (
          <Typography
            variant='h3'
            gutterBottom
            align='center'>
            {getLabel()}
          </Typography>
        )}
        <Typography
          variant='overline'
          display='block'
          gutterBottom
          align='center'>
          {LocalServerModel.STATION_TYPE_NAME[type]}
        </Typography>
      </CardContent>
    </Card>
  );
};

export const NotificationMessages = ({ station, onPostMessage, height = 100 }) => {
  const textRef = useRef();
  const notifications = useStore(state => state.notifications);
  const clearNotifications = useStore(state => state.clearNotifications);
  const addNotification = useStore(state => state.addNotification);

  useEffect(() => {
    notifications && scrollList();
  }, [notifications]);
  const scrollList = () => {
    let listRef = document.getElementById('listRef');
    listRef.scrollTop = listRef.scrollHeight;
  }
  const postNotification = async (notification) => {
    if (notification) {
      let message = {
        tournament_id: Utils.cookie(LocalServerModel.LOCAL_SERVER.local_tournament),
        to: station.station,
        label: station.label,
        type: station.type,
        from: LocalServerModel.STATION_TYPE.master,
        notification
      }
      await LocalServerModel.sendMessage(message);
      addNotification(message);
    }
    onPostMessage && onPostMessage();
  }

  return <div className="notifications" style={{ position: 'relative', fontSize: 'unset' }} >
    {notifications && notifications.length > 0 && 
      <ClearNotificationIcon className="ClearNotificationIcon"
        onClick={() => {
          clearNotifications(station.station);
        }}
      />}
    <div id="listRef" className="notifications darkened" style={{ height }}>
      {notifications && notifications.filter(n => [n[1].to, n[1].from].includes(station.station)).map(
        (n, i) => {
          const {to, from, notification: message} = n[1];
          const by = () => {
            let b = to != station.station? from:to;
            return <div className="by">
                    <div className="author">
                      {b!==station.station? 'from':'to'}
                    </div>
                    <div className="who">
                      {stations? stations[b]?.label || b : LocalServerModel.STATION_TYPE_NAME.MASTER}
                    </div>
                  </div>;
          }
          return <div key={i} className="notification">
            <div className="notificationTimeStamp">{n[0]}</div>
            <div className="notificationMessageWrapper">
              {by()}
              <div className="notificationMessage">{message}</div>
            </div>
          </div>
        })}
    </div>

    <div className="send">
      <TextField style={{ width: '100%' }} ref={textRef} placeholder="Type message" />
      <SendOutlinedIcon className="SendOutlinedIcon"
        onClick={async () => {
          let textEl = textRef.current.getElementsByTagName('input')[0];
          let notification = textEl.value;
          await postNotification(notification);
          textEl.value = '';
        }} />
    </div>
  </div>
} 

const STATION_HEADERS = [
  {id: 'index', disable_sort: true},
  {
    id: "station",
    disablePadding: false,
    label: "Station",
  },
  {
    id: "type",
    disablePadding: false,
    label: "Type",
  },
  {
    id: "messages",
    disablePadding: false,
    label: "Messages",
  },
  {
    id: "data",
    disablePadding: false,
    label: "Data",
  },
];

const Stations = forwardRef(({
  stations = {},
  tournament,
}, ref) => {
  const tableRef = useRef();
  const [refresh, setRefresh] = useState(false);
  const [schedules, setSchedules] = useState([]);
  useImperativeHandle(ref, () => ({
    refresh: () => setRefresh(!refresh),
  }));

  useEffect(() => {
    
  }, []);
  useEffect(() => {
    tournament.getSchedules().then(ss => setSchedules(ss));
  }, [tournament]);

  let list = Object.keys(stations).map(k => stations[k]);
  return (
    <div className="local-stations" style={{ position: 'relative' }}>
      <TableFormInput name="LocalServer"
        ref={tableRef}
        headCells={STATION_HEADERS}
        data={list}
        renderTRow={({ row, isSelected, index }) => {
          row.localStationRef = React.createRef();
          return <Station key={index}
            ref={row.localStationRef}
            index={index}
            station={row}
            isSelected={isSelected}
          />
        }}
      />
    </div>
  );
});

const sendStationUpdate = (station, tournament, cb, isTypeChange) => {
  let message = {
    tournament_id: Utils.cookie(LocalServerModel.LOCAL_SERVER.local_tournament),
    to: station.station,
    type: station.type,
    label: station.label,
  }
  if (isTypeChange) {
    message.update = "true";
  }
  if (message.type === LocalServerModel.STATION_TYPE.schedule) {
    return tournament.getSchedules().then(schedules => {
      message.raw = JSON.stringify(schedules);
      LocalServerModel.sendMessage(message);
      cb && cb();
    });
  }
  if (message.type === LocalServerModel.STATION_TYPE.weight_in) {
    message.raw = JSON.stringify(tournament.getRegistrations());
  }
  LocalServerModel.sendMessage(message);
  cb && cb();
}

const Station = forwardRef(({ station, isSelected, index }, ref) => {
  const tournament = useStore(state => state.local_tournament);
  const [message, setMessage] = useState();
  const [edit, setEdit] = useState();
  const [stationLabel, setStationLabel] = useState(station.label);
  const [refresh, setRefresh] = useState(false);
  const videoRef = useRef();
  useEffect(() => {
    if (videoRef.current) {
      sendInitRTCPeerConnection('initRTCPeerConnectionReady');
    }
  }, [videoRef.current]);

  useImperativeHandle(ref, () => ({
    rtcAnswer, rtcCandidate, sendInitRTCPeerConnection,
    refresh: () => setRefresh(!refresh)
  }));

  const sendInitRTCPeerConnection = (flag) => {
    if (station && station.type === LocalServerModel.STATION_TYPE.camera) {
      setRtcConn();
      LocalServerModel.sendMessage({
        tournament_id: Utils.cookie(LocalServerModel.LOCAL_SERVER.local_tournament),
        to: station.station,
        type: station.type,
        label: station.label,
        rtc: flag,
      });
    } 
  }

  const setRtcConn = () => {
    if (!station.rtcPeerConnection) {
      station.rtcPeerConnection = new RTCPeerConnection(null);
      station.rtcPeerConnection.onicecandidate = (event) => {
        console.log('ICE candidate:', event.candidate);
        if (event.candidate) {
          LocalServerModel.sendMessage({
            label: station.label,
            to: station.station,
            type: station.type,
            rtc_candidate: JSON.stringify(event.candidate)
          });
        }
      };
      station.rtcPeerConnection.oniceconnectionstatechange = (event) => {
        let state = station.rtcPeerConnection.iceConnectionState;
        console.log('ICE state change:', state, ' event:', event);
      };
      station.rtcPeerConnection.onconnectionstatechange = (e) => {
        let state = station.rtcPeerConnection.connectionState;
        console.log('RTC state change:', state, ' event:', e);
      }
      station.rtcPeerConnection.ontrack = (e) => { 
        console.log('Received remote stream: ', e.target.connectionState, e.target.iceConnectionState);
        const validStates = ['new', 'connected'];
        if (videoRef.current && validStates.includes(e.target.connectionState) && validStates.includes(e.target.iceConnectionState)) {
          setTimeout(() => videoRef.current.srcObject = e.streams[0], 5000);
        }else {
          sendInitRTCPeerConnection('initRTCPeerConnection');
        }
      };
    }
  }
  
  const rtcCandidate = async (candidate) => {
    if (!candidate) {
      return;
    }
    const { rtcPeerConnection } = station;
    candidate = JSON.parse(candidate);
    await rtcPeerConnection.addIceCandidate(new RTCIceCandidate(candidate));
    console.log('Add remote ICE candidate: ', candidate);
  }

  const rtcAnswer = async (from, offer) => {
    if (!offer) {
      return;
    }
    const { rtcPeerConnection } = station;
    offer = JSON.parse(offer);
    await rtcPeerConnection.setRemoteDescription(new RTCSessionDescription(offer));
    console.log('SetRemoteDescription: ', offer);
    try {
      let answer = await rtcPeerConnection.createAnswer({
        offerToReceiveAudio: 1,
        offerToReceiveVideo: 1
      });
      await rtcPeerConnection.setLocalDescription(answer);
      console.log('SetLocalDescription: ', answer);
      await LocalServerModel.sendMessage({
        to: from,
        label: station.label,
        type: LocalServerModel.STATION_TYPE.camera,
        rtc: JSON.stringify(answer)
      });
    } catch (e) {
      console.log('Error creating answer: ', e)
    }
  }

  const updateStation = (isTypeChange) => {
    station.label = stationLabel;
    station.type = station.type;
    sendStationUpdate(station, tournament, () => setEdit(false), isTypeChange);
  }

  const StationData = ({}) => {
    if (station.type === LocalServerModel.STATION_TYPE.score_card) {
      const { data } = station;
      if (data) {
        const { clock = 0, bracket_entry1, bracket_entry2, schedule } = data;
        let winner = (bracket_entry1.result || bracket_entry2.result) && bracket_entry1.result ? bracket_entry1 : bracket_entry2;
        const RenderScore = ({ point, point2, neg }) => {
          return <div>
            <span style={{ color: 'blue' }}>{point || 0}</span>:
            <span style={{ color: 'orange' }}>{point2 || 0}</span>:
            <span style={{ color: 'red' }}>{neg || 0}</span>
          </div>;
        }
        let membership1 = tournament.getMemberships().find(m => m.id === bracket_entry1.membership);
        let membership2 = tournament.getMemberships().find(m => m.id === bracket_entry2.membership);
        let display = [
          <div key={0} style={{ color: 'green' }}>{Utils.toDuration(clock)}</div>,
          <div key={1} style={{ display: 'grid' }}>
            <span style={{ color: 'blue' }} className={winner === bracket_entry1 ? 'blink' : ''}>
              {`${UserModel.getMembershipName(membership1)}`}
            </span>
            <RenderScore
              point={bracket_entry1.score}
              point2={bracket_entry1.score2}
              neg={bracket_entry1.scorep}
            />
          </div>,
          <span key={2} style={{ fontWeight: 'normal', fontWeight: 'italic' }}>
            vs
          </span>,
          <div key={3} style={{ display: 'grid' }}>
            <span style={{ color: 'red' }} className={data && winner === bracket_entry2 ? 'blink' : ''}>
              {`${UserModel.getMembershipName(membership2)}`}
            </span>
            <RenderScore
              point={bracket_entry2.score}
              point2={bracket_entry2.score2}
              neg={bracket_entry2.scorep}
            />
          </div>
        ]
        return <div className="ScoreCardData" style={{ display: 'block', fontWeight: 'bold' }}>
          <div className="scorecard">
            {display.map(d => d)}
          </div>
        </div>
      }
    }
    if (station.type === LocalServerModel.STATION_TYPE.camera) {
      return <div className="CameraData">
          <div className="videoHolderWrapper">
              <video ref={videoRef} autoPlay controls/>
              <button className={`icon_button ${getRTCConnState(station && station.rtcPeerConnection)}`} 
                onClick={() => sendInitRTCPeerConnection('initRTCPeerConnection')}>
                  <ReInitRTCIcon style={{ cursor: 'pointer' }} />
              </button>
          </div>
          
      </div>
    }
    return '';
  }

  const sendPing = (s) => {
    if (s) {
      sendStationInitData(s, s.station, tournament);
    }
  }

  return (
    <TableRow
      hover
      tabIndex={-1}
      id={station && station.station}
    >
      <TableCell padding="checkbox">
        <div style={{ display: 'flex' }}>
          <div style={{ margin: 'auto' }}>{index + 1} <ReloadStationsIcon onClick={() => sendPing(station)} style={{cursor: 'pointer'}}/></div>
          <StationButtons station={station} setMessage={setMessage} />
          {message && <Alert severity="info" className={PageStyles.floatingMessage}>{message}</Alert>}
        </div>
      </TableCell>

      <TableCell onClick={() => setEdit(true)} align="center">
        {!edit && station.label ? station.label :
          <TextField defaultValue={station.label || station.station}
            onChange={e => {
              setStationLabel(e.target.value);
            }}
            onKeyDown={e => isKeyEnter(e) && updateStation()}
          />}
      </TableCell>

      <TableCell align="center" onClick={() => setEdit(true)} >
        {!edit && station.type ? LocalServerModel.STATION_TYPE_NAME[station.type] :
          <SelectFormInput
            value={station.type || LocalServerModel.STATION_TYPE.not_set}
            options={LocalServerModel.STATION_OPTIONS}
            onChange={v => {
              station.type = v;
              updateStation(true);
            }}
          />}
      </TableCell>

      <TableCell align="center" style={{ maxHeight: 100, minWidth: 300 }}>
        <div className="NotificationsLocalServer">
          <NotificationMessages station={station}/>
        </div>
      </TableCell>

      <TableCell align="center">
        <StationData />
      </TableCell>
    </TableRow>
  );
});

const StationButtons = ({ station, setMessage }) => {
  const tournament = useStore(state => state.local_tournament);
  const [schedules, setSchedules] = useState([]);
  const [isAssignSchedule, setIsAssignSchedule] = useState();
  useEffect(() => {
    tournament.getSchedules().then(ss => {
      setSchedules(ss);
    });
  }, [tournament]);

  const getStationActionButtonTitle = () => {
    if (station && station.type === LocalServerModel.STATION_TYPE.score_card) {
      return "Assign scheduled match";
    }
  }

  const AssignMatchButton = () => {
    const [refresh, setRefresh] = useState(false);
    useEffect(() => {
      setRefresh(!refresh);
    }, [schedules]);
    return schedules && schedules.length === 0 ? '' :
      <Link>
        <Tooltip title={getStationActionButtonTitle(station.station)}>
          <AssignIcon style={{ cursor: 'pointer' }}
            disabled={isStationNameSet(station)}
            onClick={() => {
              setIsAssignSchedule(station);
            }}
          />
        </Tooltip>
      </Link>;
  }

  const RenderButtonAction = () => {
    if (isAssignSchedule && isAssignSchedule.station === station.station) {
      return <AssignScheduleMatch
        tournament={tournament}
        schedules={schedules}
        stations={stations}
        station={station}
        onAssigned={(schedule) => {
          let s = station.station
          setIsAssignSchedule(null);
          if (stations[s].schedule && stations[s].schedule.status !== 'F') {
            stations[s].schedule.status = '';
          }
          schedule.status = 'IP';
          stations[s].schedule = schedule;
        }}
      />
    }

    return '';
  }

  return isStationNameSet(station) ? 
    <IonButtons style={{ position: 'relative' }}>
      {station.type === LocalServerModel.STATION_TYPE.score_card &&
        !isAssignSchedule ?
        <AssignMatchButton />
        :
        <RenderButtonAction />
      }
    </IonButtons>
      : '';
}