import { useForm } from "react-hook-form";
import { IonButton, IonButtons, IonImg, IonInput, IonLabel, IonSpinner, IonText } from "@ionic/react";
import styles from './Form.module.scss';
import "./Form.scss";
import { Checkbox, Link, ThemeProvider, Tooltip } from "@mui/material";
import { FormControlLabel } from "@mui/material";
import { Controller } from "react-hook-form";
import Utils from "../../serverUtils/Utils";
import { Badge } from "@mui/material";
import LoadingScreen from "../LoadingScreen/LoadingScreen";
import { useEffect, useState } from "react";
import ImageModel from "../../serverUtils/models/ImageModel";
import { default as TrashIcon } from "@mui/icons-material/DeleteOutlineOutlined";
import { default as DeleteIcon } from "@mui/icons-material/HighlightOff";
import { default as SaveIcon } from "@mui/icons-material/Save";
import { default as CloneIcon } from "@mui/icons-material/FileCopy";
import { default as FilterIcon } from "@mui/icons-material/FilterList";
import { default as ClearIcon } from "@mui/icons-material/Clear";
import AlertPane from "../FormInput/AlertPane";
import Theme from "../FormInput/Theme";
import { useStore } from "../../Store";
import MessagePage from "../../pages/MessagesPage/MessagePage";
import TextFormInput from "../FormInput/TextFormInput";
import { COUNTRIES } from "./AddressForm";

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
export const isEmailFormatValid = email => emailRegex.test(email);
export const MESSAGE_STATUS = {
    READ: 'R',
    PENDING: 'P',
    DELETE: 'D'
}

export const MESSAGE_TYPE = {
    membership: 'membership',
    team: 'team',
    league: 'league',
    gym: 'gym',
    tournament: 'tournament',
    support: 'support',
    forum: 'forum',
    user: 'user'
};

export const DAY_OPTIONS = Utils.range(1, 31).map(d => ({value: d}));
const MONTHNAMES = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
export const MONTH_OPTIONS = Utils.range(1, 12).map(m => ({ value: m, label: MONTHNAMES[m - 1] }));

export function getBadge(length, label, i) {
    return <Badge color="primary" max={2000} key={i}
        badgeContent={length}>
        {label}</Badge>;
}

export const handleSubListAdd = (setFormData, formData, field, d) => {
    if (!formData[field]) {
        formData[field] = [];
    }
    formData[field].push(d || {});
    setFormData({...formData});
}

export const handleSubListDelete = (setFormData, formData, field, i, setValue) => {
    if (!formData[field]) {
        return;
    }
    formData[field].splice(i, 1);
    let fd = {...formData}
    setFormData && setFormData(fd);
    setValue && setValue(field, fd[field], { shouldDirty: true });
}

export function FormCheckBox({ label, name, value, control, register, onChange }) {
    return (
        <FormControlLabel control={<Controller
            name={name}
            control={control} register={register}
            render={({ field: props }) => (
                <Checkbox
                    {...props}
                    checked={value? true:false}
                    onChange={(e) => {
                        onChange && onChange({ field: name, isChecked: e.target.checked });
                    }}
                />
            )}
        />} label={label}/>
    )
}

export const IMAGE_TYPE = {
    profile: 'profile_image',
    team: 'team_image',
    league: 'league_image',
    gym: 'gym_image',
    affiliation: 'affiliation_image',
    facility: 'facility_image',
    tournament: 'tournament_image',
    store_item: 'store_item',
    photo: 'photo',
    support: 'support',
    team_icon: 'team_icon',
    league_icon: 'league_icon',
};

export const reloadPage = (setLoading, cb) => {
    setLoading(true);
    setTimeout(() => {
        cb && cb();
        setTimeout(() => {
            setLoading(false);
        }, 100);
    }, 1000);
}

export const NO_SELECTION = 'NO_SELECTION';

export function getOptions(obj){
    return Object.keys(obj).map(k => ({value: obj[k], label: k.replace(/_/g, ' ')}));
}

export const SOCIAL_MEDIA_TYPE = [
    {value: 'Website'},
    {value: 'Facebook'},
    {value: 'Instagram'},
    {value: 'Twitter'},
    {value: 'LinkedIn'},
    // {value: 'Snapchat'},
    // {value: 'Tumblr'},
    {value: 'Pinterest'},
    // {value: 'Sina Weibo'},
];

export const STATUS = [
    {
        value: 'A',
        label: 'Active',
    },
    {
        value: 'P',
        label: 'Pending'
    },
    {
        value: 'B',
        label: 'Banned'
    }
]


export const CURRENCIES = [...COUNTRIES.filter(c => c.currency_code)
    .map(c => {
        return {
            value: c.currency_code,
            label: `${c.currency_code} - ${c.currency_symbol} - ${c.name}`,
        };
    })];


export default function Form() {
    const {register, handleSubmit, formState: {errors}} = useForm();
    const onSubmit = data => console.log(data);

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className={styles.fieldWrapper}>
                <IonLabel position="fixed">Fixed Label</IonLabel>
                <div className={styles.inputWrapper}>
                    <IonInput defaultValue="test" {...register("example", {required: true})} placeholder="placeholder"/>
                    {errors.example &&
                        <IonText color="danger" className={styles.errorMessage}>This field is required</IonText>}
                </div>
            </div>
            <div className={styles.fieldWrapper}>
                <IonLabel position="fixed">Fixed Label</IonLabel>
                <div className={styles.inputWrapper}>
                    <IonInput {...register("exampleRequired", {required: true})} />
                    {errors.exampleRequired &&
                        <IonText color="danger" className={styles.errorMessage}>This field is required</IonText>}
                </div>
            </div>

            <IonButton type="submit">Save</IonButton>
        </form>
    );
}

export function FormImage({image: _image, className, size}) {
    const [image, setImage] = useState(_image);
    useEffect(() => {
        if (_image) {
            ImageModel.getImageById(_image.id, size).then(setImage);
        }else {
            setImage(null);
        }
    }, [_image]);

    return !image? <LoadingScreen /> :
        <IonImg className={className} 
            src={image && image?.data.join('')}/>;
}

export function RemoveSubFormIcon({ onClick, label, top = 0, right }) {
    return <ThemeProvider theme={Theme}>
        <div style={{
            marginBottom: 40,
            display: 'flex',
            top,
            position: 'relative',
            alignItems: 'end',
            float: right ? 'right' : 'left',
        }}>
            <DeleteIcon className={styles.delete_icon}
                onClick={onClick} />
            <div style={{
                marginLeft: 20,
                textDecoration: 'underline',
                fontSize: 'small',
                fontStyle: 'italic',
                color: 'rgba(0, 0, 0, 0.5)'
            }}>{label}</div>
        </div>
    </ThemeProvider>
}

export const stopFormSubmitByEnter = e => {
    if (e.code === 'Enter') {
        e.stopPropagation();
        e.preventDefault();
    }
}

export function FormActionButtons({ doDelete, doCopy, message, setMessage, isSubmitting, id }) {
    return <div className="FormActionButtons">
        <AlertPane message={message} timeOut={5000} setMessage={setMessage}/>
        <IonButtons className="IonButtons">
            <button className="button" type="submit">
                {isSubmitting === 'save' ? <IonSpinner name="crescent" className="spinner"/> : [<SaveIcon key={0} />, 'Save']}
            </button>
            {id && doDelete && <span className="button" onClick={doDelete}>
                {isSubmitting === 'delete' ? <IonSpinner name="crescent" className="spinner"/> : [<TrashIcon key={0} />, 'Delete']}
            </span>}
            {id && doCopy && <span className="button" onClick={doCopy}>
                {isSubmitting === 'clone' ? <IonSpinner name="crescent" className="spinner"/> : [<CloneIcon key={0} />, 'Clone']}
            </span>}
        </IonButtons>
    </div>
}

export function UnAthorizedMessage() {
    const session = useStore(state => state.session);
    const MESSAGE = <p>
        <b>Unauthorized page</b>.  Please <Link href={`/login`}>login</Link> to access.
    </p>
    return session ? '' : <MessagePage message={MESSAGE} />;
}

export const isKeyEnter = e => {
    return ['NumpadEnter', 'Enter'].includes(e.code) || e.keyCode === 13;
};
export const FilterInput = ({entity, label='', onFilterChange, onFilter}) => {
    const searchStrings = useStore(state => state.searchStrings);
    const setSearchStrings = useStore(state => state.setSearchStrings);
    const [filter, setFilter] = useState();
    useEffect(() => {
        setFilter(searchStrings[entity]);
        onFilter(searchStrings[entity]);
    }, [entity]);

    const doFilter = (isReset) => {
        isReset && setFilter('');
        onFilter(isReset? '':filter);
    }

    return <ThemeProvider theme={Theme}>
        <div className="FilterButton">
            <TextFormInput label={label} name="filter"
                value={filter}
                onChange={v => {
                    onFilterChange && onFilterChange(v);
                    if (!v && onFilter) {
                        return doFilter(true);
                    }
                    setSearchStrings(entity, v);
                    setFilter(v);
                }}
                onKeyUp={e => {
                    isKeyEnter(e) && doFilter();
                }}
            />
            <div className="helper">
                <Link onClick={doFilter} className="Link">enter to search</Link>
            </div>
        </div>
    </ThemeProvider>;
}

export const divScroller = ({ divRef, mouseEvent }) => {
    if (!divRef || !divRef.current) {
      return;
    }
  
    const { current } = divRef;
    const { scrollLeft, scrollWidth, clientWidth } = current;
    const viewWidth = current.getBoundingClientRect().width;
    const scrollLeftMax = scrollWidth - clientWidth;
  
    if (scrollLeftMax <= 0) {
      return;
    }
  
    const edge = 100;
    const scrollInc = 10;
  
    if (viewWidth - mouseEvent.clientX < edge && scrollLeft < scrollLeftMax) {
      current.style.cursor = 'ew-resize';
      current.scrollLeft += scrollInc;
    } else if (mouseEvent.clientX < edge + 20 && scrollLeft > 0) {
      current.style.cursor = 'ew-resize';
      current.scrollLeft -= scrollInc;
    } else {
      current.style.cursor = 'auto';
    }
};
  

export const hasTableFormChange = (data, CRC_Fields, crc_save_field) => {
    if (Array.isArray(data)) {
        let r = data.filter(r => {
            let update = Utils.getCRC(r, CRC_Fields);
            let isChanged = r.crc !== update;
            isChanged && console.log('isChanged: ', r.crc, update, Utils.serialize(r, CRC_Fields));
            return (r.id && r.id.toString().startsWith('-')) || isChanged;
        });
        return r.length > 0;
    }
    let crc = Utils.getCRC(data, CRC_Fields);
    let currentCrc = data[crc_save_field || 'crc'];
    if (!currentCrc) {
        return false;
    }
    if (crc !== currentCrc) {
        console.log(crc, '-', currentCrc);
    }
    return currentCrc !== crc;
}

export const filterMultSelectFormInput = (searchValue, options) => {
    return options.filter(a => {
        if (!searchValue) {
          return true;
        }
        let words = searchValue.toLowerCase().split(' ');
        return words.every(word => a.label.toLowerCase().includes(word));
    });
}

export const getBackHistory = (browsingHistory, skip) => {
    while (true) {
        let bh = browsingHistory.pop();
        if (!bh) {
            return;
        }
        if (!bh.includes(skip)) {
            return bh;
        }
    }
}