import { Alert, ThemeProvider } from "@mui/material";
import Theme from "./Theme";
import { useEffect, useRef, useState } from "react";
import "./AlertPane.scss";
import {default as CloseIcon} from "@mui/icons-material/CloseOutlined";

export default function AlertPane({ children='', setMessage, message, isFloat, isModal, timeOut, severity, omitClose }) {
  const [refresh, setRefresh] = useState(false);
  const timeoutRef = useRef();
  useEffect(() => {
    if (message && timeOut && setMessage) {
      timeoutRef.current && clearTimeout(timeoutRef.current);
      timeoutRef.current = setTimeout(() => {
        setMessage('');
      }, timeOut);
    }else if (setMessage) {
      setMessage(message || '');
    }
    setRefresh(!refresh);
  }, [timeOut, message]);
  let isString = typeof message === 'string';
  const getSeverity = () => {
    if (severity && ["error", "info", "success", "warning"].includes(severity)) {
      return severity;
    }
    if (!isString) {
      return;
    }
    if (message.startsWith('error:')) {
      return 'error';
    }
    if (message.startsWith('info:')) {
      return 'info';
    }
    if (message.startsWith('warning:')) {
      return 'warning';
    }
    return 'success';
  }

  return message ?
    <ThemeProvider theme={Theme}>
      <div className={`Message ${isFloat? 'float':''} ${isModal? 'modal':''}`}>
        {(!omitClose && !isModal) && 
          <CloseIcon className="CloseIcon" 
            onClick={() => {
              setMessage && setMessage('');
            }}/>}
        <Alert variant="filled"
          severity={severity || getSeverity()} >
          <span className="text">
            {isString ? message.split(': ')[1] : message}
          </span>
        </Alert>
        {children}
      </div>
    </ThemeProvider> : '';
}
