import { BRACKET } from "./Bracket";
import Utils from "../../serverUtils/Utils";

export const BYE = 'bye';
export const WINS_BY = {
    BYE: { value: 'BYE', label: 'Bye' },
    WIN: { value: 'WIN', label: 'Unspecified Win' },
    SCO: { value: 'SCO', label: 'Score' },
    SUB: { value: 'SUB', label: 'Submission not specified' },
    ARM: { value: 'ARM', label: 'Arm lock' },
    CHO: { value: 'CHO', label: 'Choke' },
    LEG: { value: 'LEG', label: 'Leg lock' },
    OTH: { value: 'OTH', label: 'Other' },
    DQM: { value: 'DQM', label: 'DQ in match' },
    DQO: { value: 'DQO', label: 'DQ by Overweight' },
    DQN: { value: 'DQN', label: 'DQ by No show' },
    DQC: { value: 'DQC', label: 'Cannot continue' },
    REF: { value: 'REF', label: 'Referee Decision' },
    TAGR: { value: 'TAGR', label: 'Teammate Agreement' },
};

export const WINS_BY_OPTIONS = Object.keys(WINS_BY).map(wb => WINS_BY[wb]);

export function calculateTotalRounds(competitors = 0, bracket_type = BRACKET.Single_Elimination) {
    if (competitors === 0) {
        return 0;
    }
    if ([BRACKET.Single_Elimination].includes(bracket_type)) {
        return Math.ceil(Math.log2(competitors));
    }

    if (BRACKET.Double_Elimination_Loser_3RD === bracket_type) {
        if (competitors < 2) {
            return 0;
        }
        return Math.log2(roundToNextPowerOf2(competitors)) * 2;
    }

    if (bracket_type === BRACKET.Round_Robin) {
        return (competitors%2!==0? competitors+1:competitors) - 1;
    }

}

export function calculateTotalNumberOfCompetitors(num) {
    if (num <= 0) {
        return 0;
    }

    let power = Math.ceil(Math.log2(num));
    let closestPower = 2 ** power;

    return closestPower;
}

function roundToNextPowerOf2(value) {
    if (value <= 0) {
        return 1; // The smallest power of 2 is 2^0, which is 1.
    }

    // Calculate the next power of 2 using bitwise operations.
    let powerOf2 = 1;
    while (powerOf2 < value) {
        powerOf2 *= 2;
    }

    return powerOf2;
}

export function calculateTotalEntriesByRound(round, numberOfCompetitors, bracket_type = BRACKET.Single_Elimination) {
    function getSizeEliminination() {
        let baseNodes = 2;
        let i = 0;
        if (numberOfCompetitors > 2) {
            for (i = 2; i < 16; i++) {
                if (Math.pow(numberOfCompetitors, 1 / i) <= 2) {
                    baseNodes = Math.pow(2, i);
                    break;
                }
            }
        }
        return baseNodes;
    }

    function getSizeRR() {
        if (numberOfCompetitors < 2) {
            return 0; // Round Robin requires at least 2 teams
        }
        return Math.ceil(numberOfCompetitors / 2) * 2;
    }

    function getSizeDD() {
        let base = roundToNextPowerOf2(numberOfCompetitors) / 2;
        let r = (Math.log(base) / Math.log(2)) - round;
        return Math.pow(2, r);
    }

    let baseSize;
    if (bracket_type === BRACKET.Round_Robin) {
        return getSizeRR();
    } else if (bracket_type === BRACKET.Double_Elimination_Loser_3RD) {
        return getSizeDD();
    } else {
        baseSize = getSizeEliminination();
    }
    return Math.floor(baseSize / (2 ** round));
}

export function getDivisionSize(division, tournament) {
    return tournament.getRegistrations()
        .filter(r => r.division === division || (r.pool && r.pool.split('|').includes(division))).length;
}

export function fillRoundEmpties(roundEntries, r = 0, selectedDivisionObj) {
    roundEntries.sort((a, b) => Utils.sorter(a, b, 'index'));
    roundEntries = Utils.uniqArrayByKey(roundEntries, 'index');
    let highestIndex = calculateTotalEntriesByRound(r, selectedDivisionObj.getRegistrations().length);
    for (let i = 0; i < highestIndex; i++) {
        if (!roundEntries.find(e => e && e.index === i)) {
            roundEntries.splice(i, 0, null);
        }
    }
    return roundEntries;
}

export function buildRoundsFromEntries(currentDivisionBracketEntries, selectedDivisionObj) {
    const initRoundsObj = [];
    currentDivisionBracketEntries.forEach(bracketEntry => {
        if (!initRoundsObj[bracketEntry.round]) {
            initRoundsObj[bracketEntry.round] = [];
        }
        initRoundsObj[bracketEntry.round].push(bracketEntry);
    });
    for (let r = 0; r < initRoundsObj.length; r++) {
        let round = fillRoundEmpties(initRoundsObj[r], r, selectedDivisionObj);
        initRoundsObj[r] = round;
    }
    console.log(initRoundsObj);
    return initRoundsObj;
}

export async function getData(selectedDivisionObj, bracketEntries, setRounds, getInitialBracket) {
    if (!bracketEntries) {
        return;
    }
    let currentDivisionBracketEntries = bracketEntries?.filter(r => r.division === selectedDivisionObj.id);

    if (currentDivisionBracketEntries.length > 1) {
        const obj = buildRoundsFromEntries(currentDivisionBracketEntries, selectedDivisionObj);
        setRounds(obj);
    } else {
        getInitialBracket();
    }
}

export const createInitialRound = (tournament, selectedDivisionObj) => {
    let competitors = selectedDivisionObj.getRegistrations(true);
    const totalNumberOfCompetitors = calculateTotalNumberOfCompetitors(competitors.length);

    // TODO: sort competitors for round 1 according to seed with byes
    const sortByMostOccurrences = (arr) => {
        const sortArrayBinaryPattern = (arr) => {
            let binaryPattern = [];
            let left = 0;
            let right = arr.length - 1;

            while (left <= right) {
                binaryPattern.push(arr[left]);
                if (left !== right) {
                    binaryPattern.push(arr[right]);
                }
                left++;
                right--;
            }
            return binaryPattern;
        }
        const getGymId = g => g && g.getMembership().getGym() ? g.getMembership().getGym().id : '';
        const occurrencesMap = arr.reduce((map, current) => {
            let gymId = getGymId(current);
            map.set(gymId, (map.get(gymId) || 0) + 1);
            return map;
        }, new Map());
        console.log(occurrencesMap)
        let byeCount = calculateTotalEntriesByRound(0, totalNumberOfCompetitors) - arr.length;
        let byeMap = {};
        let byeLeft = byeCount;
        for (const gym of occurrencesMap) {
            if (byeLeft <= 0) {
                break;
            }
            let weight = gym[1] / arr.length;
            let count = Math.ceil(weight * byeCount);
            byeMap[gym[0]] = count = count > byeLeft ? byeLeft : count;
            byeLeft -= count;
        }

        let sortedArray = arr.slice().sort((a, b) => {
            let gymA = getGymId(a);
            let gymB = getGymId(b);
            return occurrencesMap.get(gymB) - occurrencesMap.get(gymA);
        });


        let pairs = new Array(sortedArray.length + byeCount);
        let curGym;
        let curGymCount = 0;
        const placingLoop = (bi) => {
            for (let i=bi, j=0; i<pairs.length; i+=2, j++){
                let entry;
                if (sortedArray.length === 0){
                    entry = {
                        division: selectedDivisionObj.id,
                        tournament: tournament.id,
                        membership: BYE,
                        round: 0,
                        index: i,
                    }
                }else {
                    entry = sortedArray.shift();
                    if (!curGym) {
                        curGym = getGymId(entry);
                        curGymCount = 1;
                    }else if (curGym === getGymId(entry)){
                        curGymCount++;
                    }
                    if (curGymCount % Math.ceil(pairs.length/3) === 0){
                        let leftover = sortedArray.filter(e => getGymId(e) === curGym);
                        sortedArray = [...sortedArray.filter(e => getGymId(e)!== curGym), ...leftover];
                        curGymCount = 0;
                        curGym = null;
                    }
                }
                let index = i;
                if (bi===0 && j%2 !== 0) {
                    index = pairs.length - i;
                }
                pairs[index] = entry;
            }
        }
        placingLoop(0);
        placingLoop(1);
        
        return pairs;
    }

    competitors = sortByMostOccurrences(competitors);
    const bracketEntries = competitors.map((competitor, i) => {
        if (competitor === BYE) {
            return competitor;
        }
        let membership = competitor?.membership && tournament.getMemberships().find(m => m.id === competitor.membership);
        return {
            division: selectedDivisionObj.id,
            tournament: tournament.id,
            membership: competitor?.membership || null,
            getMembership: () => membership,
            round: 0,
            index: i,
        };
    });

    return bracketEntries.flat();
};

export const createRoundRobinRounds = (tournament, selectedDivisionObj) => {
    let participants = selectedDivisionObj.getRegistrations().map(r => r.id);
    if (!Array.isArray(participants) || participants.length < 2) {
        throw new Error('Invalid input. Participants should be an array with at least two elements.');
    }

    let n = participants.length;
    let rounds = [];

    // If the number of participants is odd, add a "bye" participant to make it even
    if (n % 2 !== 0) {
        participants.push(null);
        n++;
    }

    for (let round = 0; round < n - 1; round++) {
        const matches = [];

        for (let i = 0; i < n / 2; i++) {
            const match = [participants[i], participants[n - 1 - i]];
            matches.push(match);
        }

        rounds.push(matches);

        // Rotate participants (except the first one) for the next round
        participants.splice(1, 0, participants.pop());
    }

    rounds = rounds.map((round, r) => {
        round = round.flat();
        for (let i = 0; i < round.length; i++) {
            let reg = round[i];
            let competitor = reg && selectedDivisionObj.getRegistrations().find(r => r.id === reg);
            round[i] = {
                division: selectedDivisionObj.id,
                tournament: tournament.id,
                membership: competitor ? competitor?.membership : BYE,
                getMembership: () => competitor && competitor.getMembership(),
                round: r,
                index: i,
            }
        }
        return [round, Array.from({ length: round.length / 2 })];
    });

    return rounds.map(r => r.flat().filter(r => r)).flat();
}
