import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableFormInput from '../FormInput/TableFormInput';
import Checkbox from '@mui/material/Checkbox';
import Utils from '../../serverUtils/Utils';
import { IonButtons, IonButton, IonSpinner } from '@ionic/react';
import { default as DeleteIcon } from "@mui/icons-material/DeleteOutlineOutlined";
import { default as DeletePoolIcon } from "@mui/icons-material/DeleteForeverRounded";
import { default as SaveIcon } from "@mui/icons-material/Save";
import { default as EditIcon } from "@mui/icons-material/Edit";
import { default as AddIcon } from "@mui/icons-material/AddCircleOutline";
import { default as UndoIcon } from "@mui/icons-material/Undo";
import { default as RedoIcon } from "@mui/icons-material/Redo";
import { default as MoveIcon } from "@mui/icons-material/ChevronRight";
import { default as ExpandIcon } from "@mui/icons-material/ArrowRight";
import { default as CollapseIcon } from "@mui/icons-material/ArrowDropDown";

import React, { useState, forwardRef, useImperativeHandle, useEffect, useRef } from 'react';
import { BRACKET, BRACKET_TYPES, STATUS } from '../Bracket/Bracket';
import SelectFormInput from '../FormInput/SelectFormInput';
import { Tooltip, TextField, Menu, MenuItem, ListItemText, Link } from '@mui/material';
import './Poolings.scss';
import TournamentModel from '../../serverUtils/models/TournamentModel';
import UserModel from '../../serverUtils/models/UserModel';
import AlertPane from '../FormInput/AlertPane';
import { useStore } from "../../Store";
import { calculateTotalRounds } from "../Bracket/bracketUtils";
import { RequestUtils } from "../../serverUtils/requests";
import { convertWeight } from "../../pages/TournamentPage/DivisionRegistration";
import { useHistory } from 'react-router-dom';
import Icon from "../Icon";
import { Registration_CRC_Fields } from "./Registrations";

const UNDO_STACK = [];
let REDO_STACK = [];
const Poolings = React.forwardRef(({ tournament, onChange, tabsRef, tabObjRefs }, ref) => {
    const localServer = useStore(state => state.local_server);
    const setIsBracketEntriesUpdate = useStore(state => state.setIsBracketEntriesUpdate);
    const [message, setMessage] = useState('');
    const [refresh, setRefresh] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const CRC_Fields = ['poolings', 'pool', 'bracket_type', 'group_score', 'is_third'];
    const [expands, setExpands] = useState([POOL_AVAILABLES]);
    useEffect(() => {
        tournament.poolings = tournament.poolings || [];
        tournament.poolings.forEach(p => p.getBracketType = () => p.bracket_type);
        tournament.poolings_crc = Utils.getCRC({poolings: tournament.poolings}, CRC_Fields);
        tournament.registration_pool_crc = Utils.getCRC({pool: tournament.getRegistrations()}, CRC_Fields);
    }, []);

    useEffect(() => {
        if (RequestUtils.getURLParameters().tab === 'poolings') {
            let {id} = RequestUtils.getURLParameters();
            tournament.poolings.find(p => p.pool === id) && setExpands([id]);
        }
    }, [RequestUtils.getURLParameters().tab]);

    useImperativeHandle(ref, () => ({
        refresh: () => setRefresh(!refresh),
        checkCRC,
        doSave,
    }));
    
    const checkCRC = () => {
        let currentCRC = Utils.getCRC({poolings: tournament.poolings}, CRC_Fields);
        let currentRegPoolCRC = tournament.getRegistrations().find(r => r.crc !== Utils.getCRC(r, Registration_CRC_Fields));
        let r = currentCRC !== tournament.poolings_crc || currentRegPoolCRC;
        setMessage(r? 'warning: Data has changed' : '');
        return r;
    }

    const doSave = async (skipTabRefCheck) => {
        setIsSaving(true);
        !skipTabRefCheck && await tabObjRefs.current.save('poolingsRef');
        try{
            let result = await TournamentModel.updateTournamentPool(tournament.id, tournament.poolings);
            if (!result || result.error) {
                return setMessage('Error updating poolings.');
            }
            if (!skipTabRefCheck) {
                await tabObjRefs.current.registrationsRef.current.doSave();
                tournament.poolings_crc = Utils.getCRC({poolings: tournament.poolings}, CRC_Fields);

                setMessage('success: Successfully updated server.');
            }
        }finally {
            setIsSaving(false);
            checkCRC();
        }
    }

    const doAdd = () => {
        if (!tournament) {
            return;
        }
        pushUndo();

        tournament.poolings = tournament.poolings || []
        if (tournament.poolings.find(p => !p.pool)) {
            return;
        }
        let pool = `New Pool ${tournament.poolings.length + 1}`;
        let bracket_type = BRACKET.Single_Elimination;

        let p = { 
            id: pool,
            pool, 
            bracket_type,
            getRegistrations: () => getRegs(pool, tournament),
            getBracketType: () => bracket_type,
            getFinalRound: () => calculateTotalRounds(getRegs(pool, tournament).length, bracket_type) - 1,
            getTournament: () => tournament,
         };
        tournament.poolings.unshift(p);
        let availsDivisions = [...tournament.divisions, ...tournament.poolings];
        tournament.getAvailableDivisions = () => {
            return availsDivisions;
        }
        setExpands([p.pool]);
        checkCRC();
        onChange && onChange();
    }

    const doDeleteAll = () => {
        tournament && tournament.getRegistrations && tournament.getRegistrations()?.forEach(r => r.pool = null);
        tournament.poolings = [];
        setRefresh(!refresh);
        onChange && onChange();
        checkCRC();
        triggerUpdate();
    }

    const updateState = (state) => {
        const {poolings, registrations} = JSON.parse(state);
        tournament.poolings = poolings;
        tournament.getRegistrations = () => registrations;
        triggerUpdate();
        setRefresh(!refresh);
        checkCRC();
        onChange && onChange();
    }

    const doUndo = () => {
        let last = UNDO_STACK.pop();
        if (last) {
            REDO_STACK.push(last);
        }
        updateState(last);
        console.log('undo: ', UNDO_STACK.length);
        console.log('redo: ', REDO_STACK.length);
        checkCRC();
    }

    const doRedo = () => {
        let prev = REDO_STACK.pop();
        if (prev) {
            UNDO_STACK.push(prev);
        }
        updateState(prev);
        console.log('undo: ', UNDO_STACK.length);
        console.log('redo: ', REDO_STACK.length);
        checkCRC();
    }

    const triggerUpdate = () => {
        poolRefs.forEach(ref => {
            ref?.current.updateRegistrations();
        });
        setRefresh(!refresh);
        setIsBracketEntriesUpdate();
        checkCRC();
    }

    const pushUndo = () => {
        UNDO_STACK.push(takeSnap(tournament));
        REDO_STACK = [];
    }

    const poolRefs = [];
    const availRef = React.useRef();
    poolRefs.push(availRef);

    return (
        <div className="Poolings" >
            <IonButtons className="buttons" slot="end">
                {!localServer && 
                    <button className="button" onClick={e => {
                        e.stopPropagation();
                        doSave();
                    }}>
                        <SaveIcon />Save {isSaving && <IonSpinner className="spinner"/>}
                    </button>}
                <button className="button" onClick={doAdd}>
                    <AddIcon />New Pool
                </button>
                <button className="button" onClick={doDeleteAll}>
                    <DeleteIcon />Delete All Pools
                </button>
                {UNDO_STACK.length > 0 &&
                    <button className="button" onClick={doUndo} fill="solid" color="primary">
                        <UndoIcon />Undo
                    </button>}
                {REDO_STACK.length > 0 &&
                    <button className="button" onClick={doRedo} fill="solid" color="primary">
                        <RedoIcon />Redo
                    </button>}
            </IonButtons>
            <AlertPane message={message} setMessage={setMessage} isFloat/>
            <div className="pools-wrapper" >
                <div className={`pool-wrapper`}>
                    <div className="availables">
                        <Pool tournament={tournament} expands={expands}
                            pushUndo={pushUndo}
                            ref={availRef} poolRefs={poolRefs} tabsRef={tabsRef}
                            checkCRC={checkCRC}
                            data={{pool: POOL_AVAILABLES}}
                            triggerUpdate={triggerUpdate}
                            setExpands={isExpand => {
                                if (isExpand){
                                    setExpands([...expands, POOL_AVAILABLES])
                                }else {
                                    setExpands(expands.filter(e => e !== POOL_AVAILABLES));
                                }
                            }}
                        />
                    </div>
                </div>
                    {tournament && tournament.poolings && tournament?.poolings.map((p, i) => {
                        let ref = React.createRef();
                        poolRefs.push(ref);
                        return <div key={i} className={`pool-wrapper`}>
                            <div className={`pool-${p.pool}`} 
                                     onClick={e => {
                                        e.stopPropagation();
                                     }}>
                                <Pool tournament={tournament} data={p} expands={expands}
                                    pushUndo={pushUndo}
                                    triggerUpdate={triggerUpdate}
                                    ref={ref} poolRefs={poolRefs} tabsRef={tabsRef}
                                    checkCRC={checkCRC}
                                    setExpands={isExpand => {
                                        if (isExpand){
                                            setExpands([...expands, p.pool])
                                        }else {
                                            setExpands(expands.filter(e => e !== p.pool));
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    })}
            </div>
        </div>
    );
});

const headCells = [
    {
        id: 'first_name',
        numeric: false,
        disablePadding: true,
        label: 'First',
    },
    {
        id: 'last_name',
        numeric: false,
        disablePadding: true,
        label: 'Last',
    },
    {
        id: 'pool',
        align: 'center',
        numeric: false,
        disablePadding: true,
        label: 'Pool',
    },
    {
        id: 'gym',
        numeric: false,
        disablePadding: false,
        label: 'Gym',
    },
    {
        id: 'gender',
        numeric: true,
        disablePadding: false,
        label: 'Gender',
    },
    {
        id: 'gi_level',
        numeric: false,
        disablePadding: false,
        label: 'Level',
    },
    {
        id: 'age',
        numeric: false,
        disablePadding: false,
        label: 'Age',
    },
    {
        id: 'weight',
        numeric: false,
        disablePadding: false,
        label: 'Weight',
    },
    {
        id: 'division',
        numeric: false,
        align: 'left',
        disablePadding: false,
        label: 'Division',
    },
];

const POOL_AVAILABLES = 'Availables';
const filterReg = (r, pool) => {
    if (pool === POOL_AVAILABLES) {
        return true;
    }
    if (r.pool) {
        return r.pool.split('|').includes(pool);
    }
    return false;
}
export const getRegs = (pool, tournament) => {
    if (!pool || !tournament || !tournament.getRegistrations) {
        return [];
    }
    if (pool === POOL_AVAILABLES) {
        return tournament.getRegistrations().filter((obj, index, arr) => {
            return index === arr.findIndex((o) => o.membership === obj.membership);
        });
    }
    return tournament.getRegistrations().filter(r => r && filterReg(r, pool));
}

const takeSnap = (tournament) => JSON.stringify({
    poolings: tournament.poolings,
    registrations: tournament.getRegistrations()
});

const Pool = forwardRef(({ tournament, data, triggerUpdate, setExpands, poolRefs, tabsRef, checkCRC, expands, pushUndo }, ref) => {
    const history = useHistory();
    const ageSize = [0, 99];
    const weightSize = [0, 300];
    const [ageRange, setAgeRange] = useState(ageSize);
    const [weightRange, setWeightRange] = useState(weightSize);
    const [message, setMessage] = useState('');
    const [registrations, setRegistrations] = useState([]);
    const [isExpand, setIsExpand] = useState(data.pool===POOL_AVAILABLES);
    const [refresh, setRefresh] = useState(false);
    const poolName = useRef('');
    const tableRef = useRef();
    const divRef = useRef();

    const updateRegistrations = () => {
        let regs = getRegs(data.pool, tournament);
        let div = tournament.getAvailableDivisions().find(d => d.id === data.pool);
        if (div) {
            div.getRegistrations = () => regs;
        }
        setRegistrations(regs);
    }

    const hasEntries = reg => {
        if (data.pool === POOL_AVAILABLES) {
            return;
        }
        let r = tournament.caches.bracketEntries.filter(be => be.membership === reg.membership && be.division === data.pool);
        return r.length > 1;
    }

    useEffect(() => {
        if (isExpand) {
            divRef.current.scrollIntoView({
                behavior: 'smooth', 
                block: 'center',     
            });
        }
    }, [isExpand]);

    useEffect(() => {
        setIsExpand(data && expands && expands.includes(data.pool)? true:false);
    }, [expands]);

    useEffect(() => {
        let regs = getRegs(data && data.pool, tournament);
        setRegistrations(regs);
    }, [data && data.pool]);

    useImperativeHandle(ref, () => ({
        updateRegistrations,
        setIsExpand,
        getPoolName: () => data.pool,
    }));

    const updatePool = (r, pool) => {
        let pools = (r.pool || '').split('|').filter(r => r);
        if (pools.includes(pool)) {
            return;
        }
        pushUndo();
        pools = new Set(pools);
        pools.add(pool);
        r.pool = [...pools].join('|');
        checkCRC();
        return true;
    }

    const drop = (ev) => {
        ev.preventDefault();
        let from = JSON.parse(ev.dataTransfer.getData("text"));
        let r = tournament.getRegistrations().find(r => r.id===from.id);
        if (data.pool === POOL_AVAILABLES){
            tournament.getRegistrations().forEach(_r => _r.membership === r.membership && (_r.pool = null));
        }else if (data.pool) {
            return updatePool(r, data.pool);
        }
        if (data.pool) {
            updateRegistrations();
            triggerUpdate(ref);
        }
    }

    const drag = (ev) => {
        const {id, pool} = ev.target;
        ev.dataTransfer.setData("text", JSON.stringify({id, pool}));
    }

    const EditName = () => {
        const [isAlert, setIsAlert] = useState(null);
        if (!poolName.current) {
            return <div className="Pool-Header">
                <span onClick={() => {
                    poolName.current = data.pool;
                    setRefresh(!refresh);
                }}>
                    {data.pool !== POOL_AVAILABLES && <Tooltip title="Edit pool name">
                        <EditIcon />
                    </Tooltip>}
                    {data.pool} {data.bracket_type && ` - ${BRACKET_TYPES.find(b => b.value===data.bracket_type).label}`}
                </span>
            </div>;
        }
        data.getBracketType = () => data.bracket_type || BRACKET.Single_Elimination;
        data.getRegistrations = () => getRegs(poolName.current, tournament);

        const setName = e => {
            e.stopPropagation();
            try {
                setMessage('');
                if (!poolName.current) {
                    return;
                }
                if (data.pool !== poolName.current){
                    if (tournament.poolings.find(p => p.pool===poolName.current)) {
                        return setMessage('error: Pool name not available.');
                    }
                    tournament && tournament.getRegistrations && tournament.getRegistrations().forEach(r => {
                        if (r.pool) {
                            let pools = r.pool.split('|');
                            if (pools.includes(data.pool)) {
                                pools = pools.filter(p => p !== data.pool);
                                pools.push(poolName.current);
                                r.pool = pools.join('|');
                            }
                        }
                    });
                    data.pool = data.id = poolName.current;
                }
                poolName.current = null;
                checkCRC();
            }finally {
                setRefresh(!refresh);
            }
        }

        const updateBracketType = e => {
            e.stopPropagation();
            tournament.getBracketEntries().then(bes => {
                tournament.caches.bracketEntries = bes.filter(be => be.division !== data.pool);
                checkCRC();
            });
        }

        return <div className="EditName">
            {isAlert && <AlertPane message={'warning: This will reset the registrants of this division.'} isModal >
                <IonButton onClick={updateBracketType} fill="solid">Continue</IonButton>
                <IonButton onClick={e => {
                    e.stopPropagation();
                    data.bracket_type = isAlert;
                    setIsAlert(null);
                }} fill="solid">Cancel</IonButton>
            </AlertPane>}
            <TextField label={"Enter Pool Name"}
                defaultValue={poolName.current}
                name="pool"
                onChange={e => {
                    poolName.current = e.target.value;
                    checkCRC();
                }}
                onClick={e => e.stopPropagation()}
            />
            <SelectFormInput
                value={data.bracket_type = data.bracket_type ? data.bracket_type : BRACKET.Single_Elimination}
                name="bracket_type"
                label="Bracket Type"
                placeholder="Select bracket type"
                options={BRACKET_TYPES}
                onChange={v => {
                    setIsAlert(data.bracket_type);
                    data.bracket_type = v;
                }}
            />
            <IonButton onClick={setName} fill="solid">Done</IonButton>

        </div>;
    }

    const doDelete = (e) => {
        e.stopPropagation();
        setMessage('');
        pushUndo();
        tournament && tournament.getRegistrations && tournament.getRegistrations().forEach(r => {
            if (r.pool) {
                r.pool = r.pool.split('|').filter(p => p!==data.pool).join('|');
            }
        });
        let i = tournament.poolings.indexOf(data);
        tournament.poolings.splice(i, 1);
        triggerUpdate(ref);
        setMessage('');
        checkCRC();
    }

    const hasEntryRegs = regs => {
        let hes = []
        for (let reg of regs) {
            if (hasEntries(reg)) {
                hes.push(reg);
            }
        }
        if (hes.length > 0){
            setMessage(`info: ${hes.map(r => UserModel.getMembershipName(r.getMembership)).join(', ')} matches have started.`);
            return true;
        }
    }

    const doMove = (pool) => {
        setMessage('');
        const regs = tableRef.current.selected;
        if (!regs || regs.length === 0) {
            return setMessage('info: No reg is selected.');
        }
        if (hasEntryRegs(regs)){
            return;
        }
        
        let msg = '';
        try{
            pushUndo();
            let isAvailPool = pool === POOL_AVAILABLES;
            if (isAvailPool){
                removeSelecteds();
            }else {
                let uniqueRegs = Utils.uniqArrayByKey(tournament.getRegistrations().filter(r => regs.includes(r.id)), 'id');
                let alreadyInPools = [];
                uniqueRegs.forEach(r => {
                    let result = updatePool(r, pool);
                    if (result && data.pool !== POOL_AVAILABLES) {
                        removeRegFromPool(r, data.pool);
                    }else {
                        alreadyInPools.push(r);
                    }
                });
                if (alreadyInPools.length > 0){
                    msg = `info: ${alreadyInPools.map(r => UserModel.getMembershipName(r.getMembership())).join(', ')} already in pool ${pool}`;
                }
                updateBracketEntries(data.pool, uniqueRegs.map(r => r.membership));
            }
        }finally {
            checkCRC();
            triggerUpdate(ref);
            setMessage(msg);
        }
}

    const doResetRegistrants = () => {
        setMessage('');
        pushUndo();
        tournament && tournament.getRegistrations && tournament.getRegistrations().forEach(r => {
            if (r.pool) {
                if (data.pool === POOL_AVAILABLES) {
                    r.pool = '';
                } else {
                    r.pool = r.pool.split('|').filter(p => p !== data.pool).join('|');
                }
            }
        });
        triggerUpdate(ref);
    }

    const removeRegFromPool = (reg, pool) => {
        if (reg) {
            if (reg.pool) {
                reg.pool = reg.pool.split('|').filter(p => p !== pool).join('|');
            }
        }
    }

    const updateBracketEntries = (pool, memberships) => {
        tournament.caches.bracketEntries = tournament.caches.bracketEntries.filter(be => be.division !== pool || be.division===pool && !memberships.includes(be.membership));
    }

    const removeSelecteds = (e) => {
        e && e.stopPropagation();
        let regs = tournament.getRegistrations().filter(r => tableRef.current.selected.includes(r.id));
        for (let reg of regs) {
            removeRegFromPool(reg, data.pool);
        }
        updateBracketEntries(data.pool, regs.map(r => r.membership));
        triggerUpdate(ref);
        tableRef.current.setSelected([]);
    }

    const ToolbarButtons = ({index}) => {
        const [refresh, setRefresh] = useState(false);
        const [moveMenu, setMoveMenu] = useState(null);
        useEffect(() => {
            setRefresh(!refresh);
        },  [tournament.poolings && tournament.poolings.length]);

        const handleMoveMenuOpen = (e) => {
            e.stopPropagation();
            setMoveMenu(e.currentTarget);
        };
        const handleMoveMenuClose = (e) => {
            e && e.stopPropagation();
            setMoveMenu(null);
        };

        let list = tournament.poolings.filter(p => p.pool !== data.pool);
        const SelectPools = ({}) => {
            return list.map((p, i) => {
                 return <MenuItem key={i}>
                    <ListItemText onClick={e => {
                        handleMoveMenuClose(e);
                        doMove(p.pool);
                    }}>
                        <span>
                        <div className="flex-column gap">
                            {p.pool}
                        </div>
                        </span>
                    </ListItemText>
                 </MenuItem>;
            });
            
        }

        const isMoveMenuOpen = Boolean(moveMenu);
        return <div className="ToolBar">
            <IonButtons>
                {data.pool !== POOL_AVAILABLES && 
                    <Tooltip title="Remove selected registants">
                        <button className="button icon_button"  onClick={removeSelecteds} >
                            <DeleteIcon/>
                        </button>
                    </Tooltip>}
                {tournament.poolings && tournament.poolings.length > 0 && list.length > 0 && 
                    <div className="move-menu">
                        <Tooltip title={"Move the selected registrants to the selected pool"}>
                            <button className="button icon_button"  
                                onClick={handleMoveMenuOpen} >
                                <MoveIcon />
                            </button>
                        </Tooltip>
                        <Menu
                            anchorEl={moveMenu}
                            open={isMoveMenuOpen}
                            onClose={handleMoveMenuClose}
                            MenuListProps={{'aria-labelledby': 'basic-button'}}
                        >
                            <SelectPools />
                        </Menu>
                    </div>}
                
            </IonButtons>
            {/* {pool==POOL_AVAILABLES && <RangeFilter />} */}
            <AlertPane message={message} isFloat setMessage={setMessage} timeOut={2000} />
        </div>;
    }
    const tableComparator = (a, b, orderby) => {
        if (['age', 'weight'].includes(orderby)) {
            return Utils.sorter(a, b, orderby);
        }
    }

    const getFilterData = ({ headCell, filterString }, filterData, isFilter, isRange) => {
        filterString = filterString.toLowerCase();
        let words = Utils.separateWords(filterString);
        if (headCell.id === "gi_level") { //OR search
            return (filterData || registrations).filter(r => {
                let level = (r.gi_level || '').toLowerCase();
                let words = filterString.trim().split(' ');
                return words.find(w => level.includes(w));
            })
        }
        if (headCell.id === "age") {
            return (filterData || registrations).filter(r => {
                return isRange(filterString, () => {
                    let m = r.getMembership();
                    return m && Utils.getAge(m.dob);
                });
            });
        }
        if (headCell.id === "weight") {
            return (filterData || registrations).filter(r => {
                return isRange(filterString, () => {
                    let m = r.getMembership();
                    return m && m.weight;
                });
            });
        }
        if (headCell.id === "pool") { //OR search
            return (filterData || registrations).filter(r => {
                let pools = r.pool.split('|');
                let abbrs = abbrPool(pools).map(a => a.abbr).join(' ').toLowerCase();
                return words.find(w => abbrs.includes(w));
            })
        }
        if (headCell.id === "gender") { 
            return (filterData || registrations).filter(r => {
                return r.getMembership().gender.toLowerCase().includes(filterString.toLowerCase());
            })
        }
        if (headCell.id === "division") { 
            return (filterData || registrations).filter(r => {
                let div = r.getDivision();
                let s = filterString.toLowerCase();
                return div && words.find(w => s.includes(w));
            })
        }
    }

    const abbrPool = (pools) => {
        let abbrs = [];
        for (let p of pools) {
            if (p === data.pool) {
                continue;
            }
            let words = p.split(' ');
            if (words.length === 1){
                let word = words.pop();
                abbrs.push({abbr: `${word.charAt(0)}${word.charAt(word.length-1)}`, pool: p});
            }else {
                abbrs.push({abbr: words.map(n => n.charAt(0).toUpperCase()).join(''), pool: p});
            }
        }
        return abbrs;
    }

    const getHeaderCell = () => {
        return headCells;
    }

    const filterStatusAgeWeight = () => {
        let regs = registrations.filter(r => {
            if (r.status===STATUS.Active && r.getMembership && r.getMembership()) {
                let age = Utils.getAge(r.getMembership().dob);
                let weight = r.getMembership().weight;
                let result = ageRange[0]<=age && age <= ageRange[1] && weightRange[0] <= weight && weight <= weightRange[1];
                return result;
            }
            return false;
        });
        return regs;
    }

    let isAvailablePool = data.pool === POOL_AVAILABLES;
    return (
        <div className={`Pool ${!isExpand? 'collapse':''}`} ref={divRef}
            pool={data? data.pool:POOL_AVAILABLES}
            onDrop={drop}
            onDragOver={(ev) => {
                return ev.preventDefault();
            }}>
            <div className="header">
                <h6 className="pool-name"
                    onClick={e => {
                        e.stopPropagation();
                        setExpands(true);
                        setIsExpand(true);
                    }}>{
                    !isAvailablePool? data.pool : POOL_AVAILABLES}
                </h6>
                <b className="count">
                    [{filterStatusAgeWeight().length}]
                </b>
                {!isAvailablePool && 
                    <button className="button icon_button">
                        <Tooltip title="Delete this pool">
                            <DeletePoolIcon onClick={doDelete}/>
                        </Tooltip>
                    </button>}
                {!isAvailablePool && 
                    <Tooltip title="Goto bracket">
                        <button className="button icon_button"
                            onClick={e => {
                                e.stopPropagation();
                                history.push(`/tournaments/${tournament.id}/edit?tab=brackets&id=${encodeURIComponent(data.pool)}&chip=pool`);
                                tabsRef.current.setActiveTabByName('brackets');
                            }}
                        ><Icon name="bracket"/></button>
                    </Tooltip>}
                {!isExpand && <div className="expand" onClick={() => {
                    setExpands(true);
                    setIsExpand(true);
                }}><ExpandIcon className="ExpandIcon"/> <span>Expand</span></div>}
                {isExpand && <div className="expand" onClick={() => {
                    setExpands(false);
                    setIsExpand(false);
                }}><CollapseIcon className="CollapseIcon"/> <span>Collapse</span></div>}
            </div>
            {isExpand && 
                <TableFormInput name="Poolings"
                    title={data ? <EditName /> : POOL_AVAILABLES} isDense ref={tableRef}
                    title1={<span/>}
                    getFilterData={getFilterData}
                    isEditable={true}
                    toolbarButtons={() => <ToolbarButtons pool={data.pool} index={0}/>}
                    toolbarButtons1={() => <ToolbarButtons pool={data.pool} index={1}/>}
                    headCells={getHeaderCell()}
                    comparator={tableComparator}
                    data={filterStatusAgeWeight()}
                    pageSize={-1}
                    renderTRow={({row, isSelected, index, handleClick}) => {
                        const membership = row.getMembership() || {};
                        const isItemSelected = isSelected(row.id);
                        const labelId = `enhanced-table-checkbox-${index}`;
                        let division = tournament.getDivisions().find(d => d.id === row.division);
                        const getDivWeight = () => {
                            if (!division) {
                                return;
                            }
                            if (division.getWeightClass && division.getWeightClass() && membership.weight_unit === division.getWeightClass().weight_unit) {
                                return `${membership.weight} ${membership.weight_unit||''}`;
                            }
                            return convertWeight(membership.weight, membership.weight_unit==='lbs'? 'kgs':'lbs');
                        }
                        const PoolAbbr = ({poolNames}) => {
                            if (!poolNames){
                                return '';
                            }
                            let pools = poolNames.split('|');
                            let abbrs = abbrPool(pools);

                            const expandPool = (e, a) => {
                                e.stopPropagation();
                                poolRefs.forEach(pr => {
                                    pr.current.setIsExpand(pr.current.getPoolName() === a.pool);
                                });
                            }
                            return <Tooltip title={pools.join(', ')}>
                                <div>
                                    {abbrs.map((a, i) => <b key={i} className="PoolAbbr" onClick={e => expandPool(e, a)}>{a.abbr}</b>)}
                                </div>
                            </Tooltip>;
                        }

                        const MemberName = ({name}) => {
                            return <TableCell
                                draggable={true}
                                onDragStart={drag}
                                component="th"
                                id={row.id}
                                pool={data? data.pool:POOL_AVAILABLES}
                                scope="row"
                                padding="none">
                                <Link className="Link" onClick={e => {
                                    e.stopPropagation();
                                    history.push(`/tournaments/${tournament.id}/edit?tab=competitors&id=${row.id}`);
                                    tabsRef.current.setActiveTabByName('competitors');
                                }} >{membership?.[name]}</Link>
                            </TableCell>;
                        }
                        return <TableRow
                            hover
                            onClick={(event) => handleClick(event, row.id)}
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={row.id}
                            selected={isItemSelected}
                        >
                            <TableCell padding="checkbox">
                                <Checkbox
                                    color="primary"
                                    checked={isItemSelected}
                                    inputProps={{
                                        'aria-labelledby': labelId,
                                    }}
                                />
                            </TableCell>
                            <MemberName name="first_name" />
                            <MemberName name="last_name" />
                            <TableCell align="center"><PoolAbbr poolNames={row.pool}/></TableCell>
                            <TableCell align="left">{membership.getGym && membership.getGym()?.name}</TableCell>
                            <TableCell align="center">{membership.gender}</TableCell>
                            <TableCell align="center">{membership.gi_level}</TableCell>
                            <TableCell align="center">{Utils.getAge(membership.dob)}</TableCell>
                            <TableCell align="center">{getDivWeight()}</TableCell>
                            <TableCell align="left">{division && division.name}</TableCell>
                        </TableRow>
                    }}
                />}
        </div>
    )
});

export default Poolings;
