import React, { createRef, forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { getThreadName, MessageThreadName, RenderSend, SendMemberMessage, ThreadFilter } from "./MessagesPage";
import Utils from "../../serverUtils/Utils";
import { Accordion, AccordionDetails, AccordionSummary, Paper, Tooltip } from "@mui/material";
import { default as ExpandIcon } from "@mui/icons-material/ExpandMore";
import { default as CollapseIcon } from "@mui/icons-material/ChevronRight";
import { STATUS } from "../../components/Bracket/Bracket";
import { RequestMessage, RequestUtils } from "../../serverUtils/requests";
import { ThemeProvider } from "@emotion/react";
import Theme from "../../components/FormInput/Theme";
import './Messages.scss';
import MessageModel from "../../serverUtils/models/MessageModel";
import { MESSAGE_TYPE } from "../../components/Form/Form";
import { default as ForwardIcon } from "@mui/icons-material/Shortcut";
import { default as CopyIcon } from "@mui/icons-material/ContentCopy";
import Cancel from "@mui/icons-material/Cancel";
import { IonSpinner } from "@ionic/react";
import MultiSelectFormInput from "../../components/FormInput/MultiSelectFormInput";
import UserModel, { getMembershipName } from "../../serverUtils/models/UserModel";
import AlertPane from "../../components/FormInput/AlertPane";
import { useStore } from "../../Store";
import { useHistory } from 'react-router-dom';
import { default as MessageIcon } from '@mui/icons-material/MailOutline';


const urlParams = RequestUtils.getURLParameters();
const isTabMessages = urlParams.tab === 'messages';
const EntityMessagesPage = ({entity, getMessages, onMessageCount}) => {
  const history = useHistory();
  const tournament_messages_refresh = useStore(state => state.tournament_messages_refresh);
  const session = useStore(state => state.session);
  const [threads, setThreads] = useState({});
  const [threadFilter, setThreadFilter] = useState('');
  const [selected, setSelected] = useState();
  const [refresh, setRefresh] = useState(false);
  const [isThreadLoading, setIsThreadLoading] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [isSendMessage, setIsSendMessage] = useState(false);
  const refs = {};
  const membershipsDoc = useRef([]);
  const renderSendRef = useRef();
  const messagesRef = useRef();
  const divRef = useRef();
  const initThread = useRef();

  useEffect(() => {
    entity && 
    getMessages(entity.id).then(ms => {
        const {messages, memberships_doc} = ms;
        messagesRef.current = messages;
        membershipsDoc.current = memberships_doc || [];
        messages.forEach(m => {
          m.getFrom = () => membershipsDoc.current.find(doc => doc.id === (m.from!==entity.id? m.from : m.to));
        })
        initThread.current = Utils.groupBy(messages, ["from"]);
        if (urlParams.id) {
          if (initThread.current[urlParams.id]) {
            setIsLoading(false);
            setIsThreadLoading(true);
            getConversation(urlParams.id, true).then(messages => {
              setIsThreadLoading(false);
              initThread.current[urlParams.id] = messages;
              setThreads(initThread.current);
              setSelected(urlParams.id);
            });
          }else {
            isTabMessages && RequestUtils.insertURLParam('id', null, history);
            setThreads(initThread.current);
          }
        }else {
          setThreads(initThread.current);
        }
        // onMessageCount(Utils.sumArray(messages.map(m => m.count)));
    });
  }, [entity, tournament_messages_refresh]);

  useEffect(() => {
    setTimeout(() => setIsLoading(false), 2000);
  }, []);

  const getConversation = async (t, isDataOnly) => {
    let response = await MessageModel.getMessageConversation(t, entity.id);
    if (response && !response.error) {
      initThread.current[t] = threads[t] = response.map(m => {
        m.getFrom = () => {
          return membershipsDoc.current.find(d => d.id === t);
        }
        return m;
      });
      if (isDataOnly) {
        return threads[t];
      }
      
      setRefresh(!refresh);
      let found = messagesRef.current.find(d => d.from === t);
      found.count = 0;
      onMessageCount(Utils.sumArray(messagesRef.current.map(m => m.count)), true, t);
    }             
  }

  const sendMessage = async (text) => {
      let fm = threads[selected][0];
      let from = Utils.copy(fm.getFrom());
      let message = {
          message_type: fm.message_type,
          to: (fm.from === entity.id ? fm.to : fm.from) || fm.id,
          content: text,
          created_on: new Date().getTime(),
          first_name: fm.first_name,
          last_name: fm.last_name || '',
          status: STATUS.Pending,
          from: entity.id,
          getFrom: () => from,
      };
      let response = await RequestMessage.sendMessageRequest(message);
      let result = RequestUtils.getResponseData(response);
      if (result && result.id) {
        message.id = result.id;
        threads[selected].unshift(message);
        threads[selected] = [...threads[selected]];
        setRefresh(!refresh);
        renderSendRef.current.textFormInputRef.setInputValue('');
      }
  }

  const orderThreads = () => {
    let orders = Object.keys(threads).sort((b, a) => Utils.sorterDate(threads[a][0].created_on, threads[b][0].created_on));
    return orders;
  }
  return <ThemeProvider theme={Theme}>
    {isLoading? <IonSpinner className="spinner"/> :
      <div className="EntityMessagesPage" ref={divRef}>
        {isSendMessage? 
          <SendMemberMessage entity={entity} 
            onDone={thread => {
              setIsSendMessage(false);
              if (thread) {
                let from = thread.getFrom();
                membershipsDoc.current.push(from);
                setSelected(thread.from);
                getConversation(thread.from);
              }
            }}/> : 
          <button className="button SendMessage-button" 
            onClick={() => {
              setSelected('');
              setIsSendMessage(true);
              isTabMessages && RequestUtils.insertURLParam('from', 'send', history);
            }}>
            <MessageIcon />Send a Message</button>}
        <div className="messages">
          <ThreadFilter label="Thread Filter"
            filter={threadFilter}
            onFilter={searchText => {
              setThreadFilter(searchText);
              if (searchText) {
                setIsLoading(true);
                MessageModel.searchMessageThreads(entity.id, searchText)
                  .then(messageObj => {
                    console.log(messageObj);
                    const {memberships_doc, messages} = messageObj;
                    membershipsDoc.current = [...membershipsDoc.current, ...memberships_doc];
                    messagesRef.current = [...messagesRef.current, messages];
                    messageObj.messages.forEach(m => {
                      m.getFrom = () => {
                        return memberships_doc.find(doc => doc.id === m.from);
                      }
                    });
                    let ths = Utils.groupBy(messageObj.messages, ["from"]);
                    setThreads(ths);
                    setIsLoading(false);
                  });
              }else {
                setThreads(initThread.current);
              }
            }}
          />
            {threads && orderThreads(threads).map((t, i) => {
              let isSelected = t === selected;
              let ref = refs[t] = React.createRef();
              const getUnreadCount = () => {
                try{
                  return threads[t].length>0 && threads[t][0].count;
                }catch(e) {
                  return 0;
                }
              }
              const messageThreadNameRef = createRef();
              const messageThreadsRef = createRef();
              return <Accordion key={i} expanded={isSelected} 
                onChange={(_, ex) => {
                  if (ex) {
                    setIsThreadLoading(true);
                    setTimeout(() => {
                      getConversation(t).then(() => setIsThreadLoading(false));
                    }, 2000);
                  } 
                }}>
                <AccordionSummary >
                  <div ref={ref} className="thread" style={{ fontWeight: isSelected ? 'bold' : '' }}
                    id={t}
                    onClick={() => {
                      if (selected === t) {
                        setSelected(null);
                        isTabMessages && RequestUtils.insertURLParam('id', '', history);
                      } else {
                        setSelected(t);
                        isTabMessages && RequestUtils.insertURLParam('id', t, history);
                      }
                    }}>{i+1}
                    {isSelected? <ExpandIcon />:<CollapseIcon />} 
                    <MessageThreadName ref={messageThreadNameRef} 
                      isSelected={isSelected}
                      messages={threads[selected]}
                      length={getUnreadCount()} 
                      thread={t}
                      name={getThreadName(threads[t])}  to={entity} created_on={threads[t].length>0 && threads[t][0].created_on}
                      />
                  </div>
                </AccordionSummary>
                {isSelected && <AccordionDetails >
                  <RenderSend ref={renderSendRef} sendMessage={sendMessage} threads={threads} />
                  <ThreadFilter label="Message Filter" 
                    helper="enter to filter"
                    onFilter={searchText => {
                      if (messageThreadsRef.current) {
                        messageThreadsRef.current.setMessageFilter(searchText||'');
                      }
                    }}
                  />
                  <MessageThreads selected={selected} ref={messageThreadsRef} 
                    isThreadLoading={isThreadLoading}
                    threads={threads} 
                    entity={entity} 
                    fromName={getThreadName(threads[t]) && getThreadName(threads[t]).name} 
                    session={session}/>
                </AccordionDetails>}
              </Accordion>
            })}
          </div>
      </div>}
  </ThemeProvider>;
}

export default EntityMessagesPage;

const MessageThreads = forwardRef(({selected, threads, entity, session, fromName, isThreadLoading}, ref) => {
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState([]);
  const [isForwardUsers, setIsForwardUsers] = useState(null);
  const divRef = useRef();

  useEffect(() => {
    let _messages = selected &&
      threads[selected] && threads[selected].sort((a, b) => Utils.sorterDate(b, a, 'created_on'))
        .filter(m => m.content && !isHide(m.content));
      setMessages(_messages);
    setTimeout(() => {
      setLoading(false);
      divRef.current.scrollIntoView({
        behavior: 'smooth', 
        block: 'start',     
      });
    }, 2000);
  }, [threads[selected]]);

  useImperativeHandle(ref, () => ({
    setMessageFilter: messageFilter => {
      let _messages = threads[selected].sort((a, b) => Utils.sorterDate(b, a, 'created_on'))
        .filter(m => m.content && !isHide(m.content, messageFilter));
      setMessages(_messages);
    },
  }));

  const isHide = (content, messageFilter) => {
    if (!messageFilter) {
      return false;
    }
    let words = messageFilter.toLowerCase().split(' ');
    return !words.every(word => content.toLowerCase().includes(word));
  }
  
  return isThreadLoading? <IonSpinner className="spinner"/> :
    <div className={`MessageThreads`} ref={divRef}>
      {selected &&
        messages.map((m, i) => {
          let created_on = Utils.formatDateTime(m.created_on);
          let isIncoming = m.from !== entity.id;
          const getSender = () => {
            if (isIncoming) {
              return fromName;
            }
            if (entity) {
              return entity.name;
            }
            return getMembershipName(session);
          }
          return (
            <Paper
              key={i}
              elevation={m.from !== entity.id ? 6 : 2}
              className={
                `content ${isIncoming ? 'incoming' : 'outgoing'}`
              }>
              <div className="sender">
                <div className="from date">
                  {getSender()}
                </div>
                <div className={`date`} >
                  {created_on}
                </div>
              </div>
              <p className="content-text">{m.content}</p>
              <div className="buttons">
                <Tooltip title="Copy message to clipboard">
                  <CopyIcon onClick={e => {
                    e.stopPropagation();
                    copyMessageToClipboard(m, fromName, entity.name, r => setMessage(r));
                  }}/></Tooltip>
                <ForwardButton onClick={() => setIsForwardUsers(m)}/>
              </div>
              {isForwardUsers === m && 
                <ForwardMessage 
                  fromName={fromName}
                  toName={entity.name}
                  session={session} entity={entity}
                  isSend={true} 
                  created_on={m.created_on} 
                  message={m.content}
                  onDone={r => {
                    if (r) {
                      setMessage(r);
                      if (r.startsWith('error:')) {
                        return;
                      }
                    }
                    setIsForwardUsers(null);
                  }}
                  />}
              <AlertPane message={message} setMessage={setMessage} timeOut={4000} isFloat/>
            </Paper>
          );
        })}
    </div>;
});

export const messageOutTemplate = (fromName, toName, created_on, content) => {
  return `[from: ${fromName} to: ${toName} ] on ${Utils.formatDateTime(created_on)}\nMessages: ${content}`;
}

export const copyMessageToClipboard = (t, fromName, toName, onDone) => {
  if (navigator.clipboard) {
    navigator.clipboard.writeText(messageOutTemplate(fromName, toName, t.created_on, t.content))
      .then(() => onDone('success: content has been copied to clipboard'));
  }else{
    onDone('error: Error copying to message to clipboard')
  }
}

export const ForwardButton = ({onClick, isSend}) => {
  return <Tooltip title="Forward message to other members">
    {isSend? 
      <button className="button icon_button"><ForwardIcon onClick={onClick}/></button> :
      <ForwardIcon onClick={onClick}/>}
  </Tooltip>;
}

export const ForwardMessage = ({isSend, onDone, fromName, toName, created_on, message, session, entity}) => {
  const [forwardUsers, setForwardUsers] = useState([]);

  const forward = async (content) => {
    let message = {
      message_type: MESSAGE_TYPE.membership,
      tos: forwardUsers.map(m => m.id),
      content,
      created_on: new Date().getTime(),
      status: 'P',
      from: entity? entity.id : session.id,
      created_by:  session.id,
    };
    let response = await MessageModel.sendBulkMessage(message);
    if (response && !response.error) {
        return true;
    }else {
    }
  }

  return <div className="ForwardMessage">
    <MultiSelectFormInput
      value={forwardUsers}
      name="memberships"
      label="Memberships"
      optionLabel="display"
      optionValue="id"
      fetchOptions={searchVal => UserModel.searchUser(searchVal)}
      onChange={v => setForwardUsers(v)}
    />
    <ForwardButton isSend={isSend}
      onClick={members => {
        forward(`Forward ${messageOutTemplate(fromName, toName, created_on, message)}`)
          .then(r => {
            onDone(r? 'success: Successfully fowward message.' : 'error: Error fowarding message.');
          });
      }}/>
    <Cancel onClick={e => {
      e.stopPropagation();
      onDone();
    }}/>
  </div>;
}