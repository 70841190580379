import { IonContent, IonPage, IonSpinner } from "@ionic/react";
import LoadingScreen from "../../components/LoadingScreen/LoadingScreen";
import { useEffect, useState } from "react";

import './DashboardPage.scss';
import classNames from "classnames";
import Icon from "../../components/Icon";
import { useStore } from "../../Store";
import Utils from "../../serverUtils/Utils";
import { Link, TableCell, TableRow, TextField, ThemeProvider } from "@mui/material";
import UserChip from "../../components/UserChip/UserChip";
import { Common, convertDate } from "../../serverUtils/Models";
import TableFormInput from "../../components/FormInput/TableFormInput";
import { BYE, WINS_BY } from "../../components/Bracket/bracketUtils";
import AddCircle from "@mui/icons-material/AddCircle";
import { getTournamentDates, getTournamentLocation } from "../TournamentPage/TournamentPage";
import { Tabs } from "../../components/Tabs/Tabs";
import { RequestCommon, RequestUtils } from "../../serverUtils/requests";
import { getGenderLabel } from "../../components/LeagueInformationForm/WeightClasses";
import { GI_LEVEL_OPTIONS } from "../../components/LeagueInformationForm/Ranks";
import UserModel from "../../serverUtils/models/UserModel";
import AlertPane from "../../components/FormInput/AlertPane";
import { default as ExpandIcon } from "@mui/icons-material/ExpandCircleDown";
import { default as DashIcon } from "@mui/icons-material/Remove";
import { default as ClearIcon } from "@mui/icons-material/Clear";
import TextAreaFormInput from "../../components/FormInput/TextAreaFormInput";
import Checkbox from '@mui/material/Checkbox';
import Theme from "../../components/FormInput/Theme";
import { Badge } from "@mui/material";
import { getThreadName } from "../MessagesPage/MessagesPage";
import TournamentModel from "../../serverUtils/models/TournamentModel";

const TABS = [
    { html: "Competitor", url: "competitor" },
    { html: "Organizer", url: "organizer" },
    { html: "Profile Ownership Claims", url: "profileOwnership", is_super: true },
  ];
  
export default function DashboardPage() {
    const session = useStore(state => state.session);
    const [sectionIndex, setSectionIndex] = useState(0);
    useEffect(() => {
        let params = RequestUtils.getURLParameters();
        if (params.tab) {
            setSectionIndex(TABS.findIndex(t => t.url === params.tab));
        }
    }, []);
    const [matches, setMatches] = useState();
    useEffect(() => {
        if (session){
            session.getMatches(session.id)
                .then(matches => {
                    setMatches(matches);

                });
        } 
    }, [session]);

    const DefaultOrgs = () => {
        if (!session) {
            return '';
        }
        let gym = session.getPrimaryGym();
        let team = session.getPrimaryTeam();
        return <div className="orginization">
            {gym && <div><b>Primary Gym</b>: <Link href={`/gyms/${gym.id}`}>{gym.name}</Link></div>}
            {team && <div><b>Primary Team</b>: <Link href={`/teams/${team.id}`}>{team.name}</Link></div>}
        </div>
    }

    const MyMessages = () => {
        return <ul>
            {getMessageThreads(session).map((t, i) => <li key={i}>
                <Link href={`/messages?from=${encodeURIComponent(t.id)}`} className="Link">{t.name}</Link>
                <Badge color="primary" badgeContent={t.unreads}/>
            </li>)}
        </ul>
    }

    return <IonPage>
        {session ? <IonContent >
            <div className={`DashboardPage`}>
                <div className="header">
                    <div className={`profileImage`}>
                        <img src={session.getImage()?.data.join("")} />
                    </div>
                    <h1 className={`title`}>{session.first_name}'s Dashboard</h1>
                </div>
                <DefaultOrgs />
                
                <Tabs
                    index={sectionIndex}
                    tabs={TABS.filter(t => session.is_super || !t.is_super)}
                    sections={[
                        <div className={`grid competitor`}>
                            <div className={`module MyStats`}>
                                <h2 className={`moduleName`}>My Stats</h2>
                                <MyStats matches={matches} />
                            </div>
                            <div className={`module moduleSpan2 MySMessages`}>
                                <h2 className={`moduleName`}>Messages</h2>
                                <MyMessages />
                            </div>
                            <div className={`module moduleSpan3 MyTournaments`}>
                                <h2 className={`moduleName`}>Tournaments</h2>
                                <MyTournaments session={session} />
                            </div>
                            <div className={`module moduleSpan3 MyMatches`}>
                                <h2 className={`moduleName`}>Matches</h2>
                                <MyMatches session={session} />
                            </div>
                            <div className={`module MyTeams`}>
                                <h2 className={`moduleName`}>Teams</h2>
                                <MyTeams/>
                            </div>
                            <div className={`module MyGyms`}>
                                <h2 className={`moduleName`}>Gyms</h2>
                                <MyGyms/>
                            </div>
                            <div className={`module MyLeagues`}>
                                <h2 className={`moduleName`}>Leagues</h2>
                                <MyLeagues />
                            </div>
                        </div>
                        ,
                        <div className={`grid organizer`}>
                            <div className={`module moduleSpan3 MyTournaments`}>
                                <h2 className={`moduleName`}>Tournaments</h2>
                                <MyTournaments session={session} isAdmin={true}/>
                            </div>
                            <div className={`module moduleSpan3 MyLeagues`}>
                                <h2 className={`moduleName`}>Leagues</h2>
                                <MyLeagues isAdmin={true}/>
                            </div>
                            <div className={`module moduleSpan3 MyTeams`}>
                                <h2 className={`moduleName`}>Teams</h2>
                                <MyTeams isAdmin={true}/>
                            </div>
                            <div className={`module moduleSpan3 MyGyms`}>
                                <h2 className={`moduleName`}>Gyms</h2>
                                <MyGyms isAdmin={true}/>
                            </div>
                        </div>,
                        session && session.is_super && <ClaimProfiles />
                    ].filter(t => t)}
                />
            </div>

        </IonContent> : <LoadingScreen/>}
    </IonPage>
}

export const getMessageThreads = session => {
    if (!session || !session.getMessages) {
        return [];
    }
    let ths = session.getMessages();
    return ths.map(t => {
        let threadName = getThreadName(t);
        if (threadName) {
            let unreads = t.count;
            return {link: threadName.link, unreads, id: t.from, name: threadName.name};
        }
    }).filter(t => t);
}

const ClaimProfiles = () => {
    const [claims, setClaims] = useState([]);
    const [approved, setAppoved] = useState(false);
    const [search, setSearch] = useState('');
    const [message, setMessage] = useState();
    const headCells = [
        {
            id: "type",
            label: "Type",
        },
        {
            id: "name",
            label: "Name",
        },
        {
            id: "date",
            label: "Date",
        },
        {
            id: "by",
            label: "By",
        },
        {
            id: "explanation",
            label: "Explanation",
        },
        {
            id: "supporting_file",
            label: "Suporting File",
        },
        {
            id: "approve_by",
            label: "Approved",
        },
    ];
    useEffect(() => {
        Common.getRequestClaimProfiles(approved, search)
            .then(claims => {
                setClaims(claims);
            });
    }, [approved, search]);

    const Claim = ({claim, hasRequests=[]}) => {
        const [isExpand, setIsExpand] = useState(false);
        function base64ToBlob(base64, mime) {
            const byteCharacters = atob(base64);
            const byteArrays = [];
          
            for (let offset = 0; offset < byteCharacters.length; offset += 512) {
              const slice = byteCharacters.slice(offset, offset + 512);
              const byteNumbers = new Array(slice.length);
          
              for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
              }
          
              const byteArray = new Uint8Array(byteNumbers);
              byteArrays.push(byteArray);
            }
          
            return new Blob(byteArrays, { type: mime });
        }
          
        function downloadBase64File(base64, filename, mime='') {
            const blob = base64ToBlob(base64, mime);
            const url = URL.createObjectURL(blob);
          
            const a = document.createElement('a');
            a.href = url;
            a.download = filename;
            document.body.appendChild(a);
            a.click();
          
            setTimeout(() => {
              document.body.removeChild(a);
              window.URL.revokeObjectURL(url);
            }, 0);
        }

        const download = async () => {
            let f = await RequestCommon.getFile(claim.supporting_file);
            if (!f || f.error) {
                return setMessage('error: Error downloading supporting file');
            }
            let data = f.data.join('');
            data = data.split(',');
            let mime = data[0].split(';')[0].split(':')[1]
            downloadBase64File(data[1], f.name, mime);
        }

        const approve = async (c) => {
            let r = await Common.approvedClaimProfile(c);
            if (r && !r.error) {
                document.location.reload();
            }else {
                setMessage('error: Error approving request')
            }
        }

        const unapprove = async (c) => {
            let r = await Common.unapproveClaimProfile(c.ref_id);
            if (r && !r.error) {
                document.location.reload();
            }else {
                setMessage('error: Error approving request')
            }
        }


        const Row = ({c, isSub}) => <TableRow className="Claim"
                hover
                role="checkbox"
                tabIndex={-1}
            >
                <TableCell >
                    <div className="first-column">
                        {hasRequests.length>1 && hasRequests.find(r => r.approved_by) && !isSub && <ExpandIcon onClick={() => setIsExpand(!isExpand)} className={`ExpandIcon ${!isExpand? 'collapse':''}`}/>}
                        {isSub && <DashIcon className="DashIcon tab"/>}
                        {c.ref_type}
                    </div>
                </TableCell>
                <TableCell >
                    <Link href={`/${c.ref_type==='Gym'? 'gyms':'teams'}/${c.ref_id}`} >{c.getEntity().name}</Link>
                </TableCell>
                <TableCell >
                    {Utils.formatDateTime(c.created_on)}
                </TableCell>
                <TableCell >
                    <UserChip membership={c.getBy()} />
                </TableCell>
                <TableCell className="explanation">
                    {c.explanation}
                </TableCell>
                <TableCell className="explanation">
                    {c.supporting_file && !c.supporting_file.startsWith('http') &&
                        <button className="button" onClick={download}>Download</button>}
                    {c.supporting_file && c.supporting_file.startsWith('http') &&
                        <a href={c.supporting_file} >{c.supporting_file}</a>}  
                </TableCell>
                <TableCell className="explanation" align="center">
                    <TextAreaFormInput value={c.comment} onChange={e => c.comment = e.target.value} label="Comment"/>
                    {!c.approved_by && <button className="button" onClick={() => approve(c)}>Approve</button>}
                    {c.approved_by && <div className="approve-by">approved by <b>{UserModel.getMembershipName(c.getAppovedBy())}</b> on {Utils.formatDateTime(c.approved_on)}</div>}
                    {c.approved_by && <button className="button" onClick={() => unapprove(c)}>Unapprove</button>}
                </TableCell>
            </TableRow>;

        return !isExpand? <Row c={claim}/> : 
            [<Row c={claim}/>, 
            ...hasRequests.filter(c => c.id !== claim.id).map((c, i) => <Row key={i+1} c={c} isSub/>)];
    }

    const approvedIds = claims.filter(c => c.approved_by).map(c => c.ref_id);
    const approvedCounts = Utils.groupBy(claims, ['ref_id']);
    return <div className="ClaimProfiles">
        <ThemeProvider theme={Theme}>
            <AlertPane message={message}/>
            <div className="filter">
                <div className="toggle-approved"><Checkbox onClick={e => setAppoved(e.target.checked)}/>Approved List</div>
                <TextField placeholder="search..." value={search} onChange={e => setSearch(e.target.value)}/>
                <ClearIcon className="ClearIcon" onClick={() => setSearch('')}/>
            </div>
            <TableFormInput name="ClaimProfiles"
                className="ClaimProfiles-table"
                headCells={headCells}
                data={claims}
                renderTRow={({ row, index }) => {
                    let isDisplay = row.approved_by || (!approvedIds.includes(row.ref_id) && !row.approved_by && !row.approved_on);
                    return isDisplay && <Claim claim={row} key={index} hasRequests={approvedCounts[row.ref_id]}/>
                }}
            />
        </ThemeProvider>
    </div> 
    
}

export const MIME_TYPE = {
    pdf: 'application/pdf',
    jpeg: 'image/jpeg',
    jpg: 'image/jpeg',
    png: 'image/png',
    gif: 'image/gif',
    bmp: 'image/bmp',
    txt: 'text/plain',
    url: 'url'
}

function MyStats({ matches = [] }) {
    const session = useStore(state => state.session);
    let trophies = matches.filter(m => m.membership === session.id && m.place);
    let wins = matches.filter(m => m.result);
    let losses = matches.filter(m => m.membership !== session.id && !m.result);
    return <ul className={`list`}>
        <li>
            <span className="flex-wrapper">
                <span>Age</span>
                <span className="value">{Utils.getAge(session.dob)}</span>
            </span>
        </li>
        <li>
            <span className="flex-wrapper">
                <span>Weight</span>
                <span className="value">{`${session.weight} ${session.weight_unit}`}</span>
            </span>
        </li>
        <li>
            <span className="flex-wrapper">
                <span>Gender</span>
                <span className="value">{getGenderLabel(session.gender)}</span>
            </span>
        </li>
        <li>
            <span className="flex-wrapper">
                <span>BJJ Belt</span>
                <span className="value">{session.gi_level && GI_LEVEL_OPTIONS.find(gi => gi.value === session.gi_level).label}</span>
            </span>
        </li>
        <li>
            <span className="flex-wrapper">
                <span>Wins</span>
                <span className="value">{wins.length}</span>
            </span>
        </li>
        <li>
            <span className="flex-wrapper">
                <span>Losses</span>
                <span className="value">{losses.length}</span>
            </span>
        </li>
        <li>
            <span className="flex-wrapper">
                <span>Trophies</span>
                <span className="value">{trophies.length}</span>
            </span>
        </li>
    </ul>
}

const getTournamentDate = (tournament) => Utils.formatDate(convertDate(tournament.dates.start_date));

const headCells = [
    {
        id: "date",
        disablePadding: false,
        label: "Date",
        align: 'left'
    },
    {
        id: "tournament",
        disablePadding: false,
        label: "Tournament",
    },
    {
        id: "division",
        disablePadding: false,
        label: "Divsion",
    },
    {
        id: "opponent",
        disablePadding: false,
        label: "Opponent",
    },
    {
        id: "round",
        disablePadding: false,
        label: "Round",
    },
    {
        id: "outcome",
        disablePadding: false,
        label: "Outcome",
    },
    {
        id: "result",
        disablePadding: false,
        label: "Win By",
    }
];

export function MyMatches({ session }) {
    const [matches, setMatches] = useState([]);
    useEffect(() => {
        session && session.getMatches()
            .then(_matches => {
                setMatches(_matches.sort((a, b) => Utils.sort(b.getTournament().dates.start_date, a.getTournament().dates.start_date)));
            }, [session]);
    }, [session]);
    return session && <TableFormInput name="MyMatches"
        className="MyMatches"
        headCells={headCells}
        data={matches}
        renderTRow={({ row, index }) => {
            const { opp, outcome, round, result } = row.getOpponent();
            let division = row.getDivision() ? row.getDivision().name : row.division;
            let oppMemberhip = opp && opp.getMembership && opp.getMembership();
            const getHighlite = () => {
                return `${[session.id, opp.membership].filter(e => e).join('|')}&round=${round}`;
            }
            const getResult = () => {
                let r = row.result || '';
                if (!r) {
                    r = opp.result;
                }
                return [WINS_BY.WIN.value, ''].includes(r)? '':(WINS_BY[r]? WINS_BY[r].label:'');
            }
            return (
                <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={index}
                >
                    <TableCell >
                        {getTournamentDate(row.getTournament())}
                    </TableCell>
                    <TableCell >
                        {row.getTournament() && <Link href={`/tournaments/${row.tournament}`} color="primary">{row.getTournament().name}</Link>}
                    </TableCell>
                    <TableCell >
                        {division && <Link href={`/tournaments/${row.tournament}?tab=brackets&id=${row.division}&highlite=${getHighlite()}`} color="primary">
                            {division}
                        </Link>}
                    </TableCell>
                    <TableCell >
                        {opp && opp.membership===BYE? <span className="bye">Bye</span> : <UserChip membership={oppMemberhip} />}
                    </TableCell>
                    <TableCell align="center">
                        <span className="outcome"><b>{round+1}</b></span>
                    </TableCell>
                    <TableCell align="center">
                        <span className="outcome">{outcome}</span>
                    </TableCell>
                    <TableCell align="center">
                        <span className="outcome">{getResult()}</span>
                    </TableCell>
                </TableRow>)
        }}
    />
}

function MyLeagues({isAdmin=false}) {
    const session = useStore(state => state.session);
    let leagues = [...session.getLeagues()];
    let ids = leagues.map(g => g.id);
    session.getAdminInfo().leagues.forEach(l => {
        if (!ids.includes(l.id)) {
            l.isAdmin = true;
            leagues.push(l);
        }
    });

    leagues = leagues.filter(l => l.isAdmin === isAdmin);
    return <div className={`tableWrapper`}>
        {isAdmin && <div className="add">
            <Link href={`/leagues/add`} className="link">
                <button className="button">
                    <AddCircle /> Add a league
                </button>
            </Link>
        </div>}
        <div className={`table`}>
            <div className={`tableHeader`}>
                <div className={`tableCell`}>Name</div>
                <div className={`tableCell`}></div>
                <div className={`tableCell`}>Manage</div>
            </div>
            {leagues.length===0? <div className="count0-message">No league found</div> :
                leagues.map((l, i) => {
                    return <div key={i} className={`tableRow`}>
                        <div className={`tableCell`}>{l.name}</div>
                        <div className={`tableCell`}>{l.getLeagues && l.getLeagues().map(l => l.name).join(', ')}</div>
                        <div className={`tableCell`}>
                            <Link href={`/leagues/${l.id}`}>
                                <button className="small_button">Show</button>
                            </Link>
                        </div>
                        <div className={`tableCellCenter`}>
                            {l.isAdmin && <Link href={`/leagues/${l.id}/edit`}>
                                <button className="icon_button">
                                    <Icon name="settings" />
                                </button>
                            </Link>}
                        </div>
                    </div>
            })}
        </div>
    </div>
}

function MyTeams({isAdmin=false}) {
    const session = useStore(state => state.session);
    let teams = [...session.getTeams().filter(t => t.isAdmin===isAdmin)];
    let ids = teams.map(g => g.id);
    session.getAdminInfo().teams.forEach(t => {
        if (!ids.includes(t.id)) {
            t.isAdmin = true;
            teams.push(t);
        }
    });
    return <div className={`tableWrapper`}>
        {isAdmin && <div className="add">
            <Link href={`/teams/add`} className="link">
                <button className="button">
                    <AddCircle /> Add a team
                </button>
            </Link>
        </div>}
        <div className={`table`}>
            <div className={`tableHeader`}>
                <div className={`tableCell`}>Name</div>
                <div className={`tableCell`}>League</div>
                <div className={`tableCell`}>Captain</div>
                <div className={`tableCell`}></div>
                {isAdmin && <div className={`tableCell`}>Manage</div>}
            </div>
            {teams.length===0? <div className="count0-message">No team found</div> :
                teams.map((t, i) => {
                    return <div key={i} className={`tableRow`}>
                        <div className={`tableCell`}>{t.name}</div>
                        <div className={`tableCell`}>{t.getLeagues && t.getLeagues().map(l => l.name).join(', ')}</div>
                        <div className={`tableCell`}>{t.captains && t.captains.map(c => c.display).join(', ')}</div>
                        <div className={`tableCell`}>
                            <Link href={`/teams/${t.id}`}>
                                <button className="small_button">Show</button>
                            </Link>
                        </div>
                        {isAdmin && <div className={`tableCellCenter`}>
                            <Link href={`/teams/${t.id}/edit`}>
                                <button className="icon_button">
                                    <Icon name="settings" />
                                </button>
                            </Link>
                        </div>}
                    </div>
            })}
        </div>
    </div>
}

function MyGyms({isAdmin=false}) {
    const session = useStore(state => state.session);
    let gyms = [...session.getGyms()];
    let ids = gyms.map(g => g.id);
    session.getAdminInfo().gyms.forEach(g => {
        if (!ids.includes(g.id)) {
            g.isAdmin = true;
            gyms.push(g);
        }
    });
    gyms = gyms.filter(g => g.isAdmin === isAdmin);
    return <div className={`tableWrapper`}>
        {isAdmin && <div className="add">
            <Link href={`/gyms/add`} className="link">
                <button className="button">
                    <AddCircle /> Add a gym
                </button>
            </Link>
        </div>}
        <div className={`table`}>
            <div className={`tableHeader`}>
                <div className={`tableCell`}>Name</div>
                <div className={`tableCell`}>Team</div>
                <div className={`tableCell`}></div>
                {isAdmin && <div className={`tableCell`}>Manage</div>}
            </div>
            {gyms.length===0? <div className="count0-message">No gym found</div> :
                gyms.map((gym, i) => {
                    return <div key={i} className={`tableRow`}>
                        <div className={`tableCell`}>{gym.name}</div>
                        <div className={`tableCell`}>{gym.getTeams && gym.getTeams().map(l => l.name).join(', ')}</div>
                        <div className={`tableCell`}>
                            <Link href={`/gyms/${gym.id}`}><button className="small_button">Show</button></Link>
                        </div>
                        {isAdmin && <div className={`tableCellCenter`}>
                            <Link href={`/gyms/${gym.id}/edit`}>
                                <button className="icon_button">
                                    <Icon name="settings" />
                                </button>
                            </Link>
                        </div>}
                    </div>
            })}
        </div>
    </div>
}

const MODE = {
    organizer: 0,
    competitor: 1
}
function MyTournaments({session, isAdmin=false, matches=[]}) {
    const [tournaments, setTournaments] = useState([]);
    useEffect(() => {
        let tournaments = isAdmin? session.getAdminInfo().tournaments : session.getTournaments();
        if (isAdmin) {
            const setMessages = async () => {
                for (let t of tournaments) {
                    let messages = await TournamentModel.getMessages(t.id);
                    t.getMessages = () => messages;
                }
                setTournaments(tournaments);
            }
            return setMessages();
        }else {
            tournaments = tournaments.flatMap(t => 
                session.getRegistrations().filter(r => r.tournament === t.id)
                .map(r => r.getDivisions().map(d => {
                    return {
                        ...t, division: d
                    }
                }))
            ).flat();
        }

        setTournaments(tournaments)
    }, []);

    const getCup = place => {
        switch (place) {
            case 1: return 'gold';
            case 2: return 'silver';
            case 3: return 'bronze';
        }
        return '';
    }

    const getPlacement = tournament => {
        let placements = matches.filter(m => m.tournament === tournament.id && m.place);
        if (placements.length > 0){
            return <ul className="placements">
                {placements.map(p => {
                    return <li>{p.getDivision().name} <Icon name={`${getCup(p.place)}-cup`} className={`trophyIcon`} /></li>
                }).filter(p => p)}
            </ul>;
        }
        return '';
    }

    const getRegistrationDivision = (t) => {
        if (t.division) {
            if (t.publish.brackets) {
                return <Link href={`/tournaments/${t.id}?tab=brackets&id=${t.division.id}`} color="primary">{t.division.name}</Link>;
            }
            return t.division.name;
        } 
        return '';
    }

    const getUnreadMessages = (t) => {
        let found = session && session.getAdminInfo && 
            session.getAdminInfo().tournaments.find(_t => _t.id === t.id);
        return found && found.getMessages? Utils.sumArray(found.getMessages().messages.map(m => m.count)) : 0;
    }
    return <div className={`tableWrapper`}>
        {isAdmin && <div className="add">
            <Link href={`/tournaments/add`} className="link">
                <button className="button">
                    <AddCircle /> Add a tournament
                </button>
            </Link>
        </div>}
        <div className={`table`}>
            <div className={`tableHeader`}>
                <div className={`tableCell`}>Name</div>
                <div className={`tableCell`}>Date</div>
                <div className={`tableCell`}>Location</div>
                {!isAdmin && <div className={`tableCell`}>Division</div>}
                {isAdmin? <div className={`tableCell`}>Manage</div> : 
                    <div className={`tableCell`}>Placement</div>}
            </div>
            {tournaments.length===0? <div className="count0-message">No tournament found</div> : 
                tournaments.map((t, i) => {
                    return <div key={i} className={`tableRow`}>
                    <div className={`tableCell`}><Link href={`/tournaments/${t.id}`}>
                        <Badge color='primary'
                            max={1000}
                            badgeContent={getUnreadMessages(t)}>{t.name}</Badge>
                    </Link></div>
                    <div className={`tableCell`}>{t.dates && getTournamentDates(t.dates.start_date, t.dates.end_date)}</div>
                    <div className={`tableCell`}>
                        {getTournamentLocation(t)}
                    </div>
                    {!isAdmin && <div className={`tableCell`}>
                        {getRegistrationDivision(t)}
                    </div>}
                    {isAdmin? <div className={`tableCellCenter`}>
                        {t.isAdmin && <Link href={`/tournament/${t.id}/edit`}>
                            <button className="icon_button">
                                <Icon name="settings" />
                            </button>
                        </Link>}
                    </div>:
                    <div className="placement">{getPlacement(t)}</div>}
                    </div>
            })}
        </div>
    </div>
}

function MyTrophies({ matches = [] }) {
    let trophies = matches.filter(m => m.place);
    const getCup = place => {
        switch (place) {
            case 1: return 'gold';
            case 2: return 'silver';
            case 3: return 'bronze';
        }
        return '';
    }
    return <div className={`trophyModule`}>
        {trophies.length===0? <div className="count0-message">No placement found</div> : 
            trophies.map((t, i) => {
                return <div key={i} className={`trophyWrapper`}>
                    <Icon name={`${getCup(t.place)}-cup`} className={`trophyIcon`} />
                    <span className={`trophyName`}>
                        <p>{t.getTournament().name}</p>
                        <p>{t.getDivision().name}</p>
                        <p>{Utils.formatDate(t.getTournament().dates.start_date)}</p>
                    </span>
                </div>
            })}
    </div>
}

function CurrentRegistrations({ }) {
    const session = useStore(state => state.session);
    const getTournamentDate = (tour) => {
        let startDate = Utils.formatDate(tour.dates.start_date)
        let endDate = Utils.formatDate(tour.dates.end_date);
        if (startDate === endDate) {
            return startDate;
        }
        return [startDate, endDate].join(' - ');
    }
    let registrations = session && session.getRegistrations();
    return  <div className={`table`}>
                <div className={`tableHeader`}>
                    <div className={`tableCell`}>Tournament</div>
                    <div className={`tableCell`}>Division</div>
                    <div className={`tableCell`}>Dates</div>
                    <div className={`tableCell`}>Bracket</div>
                    <div className={`tableCell`}>Placement</div>
                </div>
                {!registrations && <IonSpinner className="spinner"/>}
                {registrations && registrations.length === 0 && <div className="count0-message">No registration found</div>}
                {registrations && registrations.length > 0 && registrations.map((r, i) => {
                    let tour = r.getTournament();
                    return r.getDivisions().filter(d => d).map(d => {
                        return <div key={`${i}_${d.id}`} className={`tableRow`}>
                            <div className={`tableCell`}>
                                <Link href={`/tournaments/${tour.id}`} color="inherit" underline="hover">
                                    {tour.name}
                                </Link>
                            </div>

                            <div className={`tableCell`}>{d.pool || d.name}</div>
                            <div className={`tableCell`}>
                                {getTournamentDate(tour)}
                            </div>
                            <div className={`tableCell`}>
                                <Link href={`/tournaments/${tour.id}?tab=brackets&id=${d.id}`}>
                                    <button className="small_button">Show</button>
                                </Link>
                            </div>
                        </div>;
                    })
                })}
            </div>;
        
}

function UpcomingMatches({ matches = [] }) {
    const session = useStore(state => state.session);
    return session && <ul className={`list`}>
        {matches.length ===0? <div className="count0-message">No upcoming matches</div> :
            matches.map((m, i) => {
                const { opp } = m.getOpponent();
                return opp && <li key={i}>
                    <div className={classNames("flex-wrapper", 'listItemHeader')}>
                        <span>{getTournamentDate(opp.getTournament())}</span>
                        <span>
                            <Link href={`/tournaments/${opp.tournament}?tab=brackets&id=${opp.division}`} color="inherit">
                                {opp.getDivision().name}
                            </Link></span>
                        <span>Round {opp.round + 1}</span>
                    </div>
                    <div className="flex-wrapper">
                        <UserChip membership={session} />
                        <p>vs</p>
                        <UserChip membership={opp && opp.getMembership()} />
                    </div>
                </li>
            })}
    </ul>;
}