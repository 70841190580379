import { IonContent, IonFab, IonFabButton, IonIcon, IonPage, useIonViewWillEnter } from "@ionic/react";
import { useHistory } from 'react-router-dom';
import PageStyles from "../Page.module.scss";
import "./TournamentPage.scss";
import TournamentPageStyles from './TournamentPage.module.scss';
import { useEffect, useState, createRef, forwardRef, useImperativeHandle, useRef } from "react";
import dayjs from "dayjs";
import LoadingScreen from "../../components/LoadingScreen/LoadingScreen";
import { useStore } from "../../Store";
import TournamentModel from "../../serverUtils/models/TournamentModel";
import Registrations, { SendMessage } from "../../components/TournamentForm/Registrations";
import Rankings from "./Rankings";
import Utils from "../../serverUtils/Utils";
import DivisionRegistration, { getDivisionTypeChips, getTournamentDivisionsByFilters } from "./DivisionRegistration";
import { GRAPH_SERVER_HOST, RequestUtils } from "../../serverUtils/requests";
import Image from "../../components/Image/Image";
import Icon from "../../components/Icon";
import { Tabs } from "../../components/Tabs/Tabs";
import { Chip, Link, ThemeProvider, Tooltip } from "@mui/material";
import UserModel from "../../serverUtils/models/UserModel";
import { pencil, bookOutline } from "ionicons/icons";
import GoogleMap from "../../components/GoogleMap/GoogleMap";
import Photos from "../../components/Form/Photos";
import { ENTITY } from "../../serverUtils/Models";
import BracketWrapper from "../../components/Bracket/BracketWrapper";
import Theme from "../../components/FormInput/Theme";
import SelectFormInput from "../../components/FormInput/SelectFormInput";
import ImageModel from "../../serverUtils/models/ImageModel";
import { IMAGE_TYPE, MESSAGE_TYPE } from "../../components/Form/Form";
import { BRACKET, STATUS } from "../../components/Bracket/Bracket";
import Schedules from "./Schedules";
import { getFlag } from "../UserPage/UserPage";
import { PDFReader } from "../SupportPage/SupportPage";
import { default as DoneIcon } from "@mui/icons-material/Cancel";
import { default as FacebookIcon } from "@mui/icons-material/Facebook";
import { default as TwitterIcon } from "@mui/icons-material/Twitter";
import { default as InstagramIcon } from "@mui/icons-material/Instagram";
import { default as LinkedInIcon } from "@mui/icons-material/LinkedIn";
import { default as PinterestIcon } from "@mui/icons-material/Pinterest";
import { default as WebsiteIcon } from "@mui/icons-material/Language";
import { default as MembershipIcon } from '@mui/icons-material/PersonOutline';
import AlertPane from "../../components/FormInput/AlertPane";


const TABS = [
    { html: <div>Registration</div>, url: 'registration' },
    { html: <div>Competitors</div>, url: 'competitors' },
    { html: <div>Schedules</div>, url: 'schedules' },
    { html: <div>Brackets</div>, url: 'brackets' },
    { html: <div>Results</div>, url: 'results' },
    { html: <div>Map</div>, url: 'map' },
    { html: <div>Photos</div>, url: 'photos' },
];

const SocialMedias = ({socialMedias}) => {
    const getIcon = n => {
        switch(n) {
            case 'Facebook':
                return <Tooltip title="Facebook"><FacebookIcon /></Tooltip> ;
            case 'Website':
                return <Tooltip title="Website"><WebsiteIcon /></Tooltip>;
            case 'Instagram':
                return <Tooltip title="Instagram"><InstagramIcon /></Tooltip>;
            case 'Twitter':
                return <Tooltip title="Twitter"><TwitterIcon /></Tooltip>;
            case 'Linkedin':
                return <Tooltip title="LinkIn"><LinkedInIcon /></Tooltip>;
            case 'Pinterest':
                return <Tooltip title="Pinterest"><PinterestIcon /></Tooltip>;

        }
    }
    return socialMedias && socialMedias.length > 0? <div className="SocialMedias">
        {socialMedias.filter(s => s)
            .map((s, i) => {
                return <Link href={s.url} target="_blank" key={i} className="Link">
                    {getIcon(s.name)}
                </Link>;
            })}
    </div> : '';
}

export const getTournamentDates = (start, end) => {
    let dts = [dayjs(start).format('MMMM D, YYYY'), dayjs(end || start).format('MMMM D, YYYY')];
    return Utils.uniqArray(dts).join(' - ');
}
export const getTournamentLocation = (tournament) => {
    if (!tournament.locations || tournament.locations.length===0){
        return '';
    }
    let l = tournament.locations[0];
    let s = 'state';
    if (['NZ', 'GB'].includes(l.country)){
        s = 'region';
    }
    return [l.venu, l.address, l.city, l[s], l.country].filter(l => l).join(', ');
}

const TournamentPage = (props) => {
    const session = useStore(state => state.session);
    const setSession = useStore(state => state.setSession);
    const [data, setData] = useState(null);
    const getCache = useStore(state => state.tournaments);
    const [filterCount, setFilterCount] = useState(0);
    const cache = useStore(state => state.pushTournament);
    const [sectionIndex, setSectionIndex] = useState();
    const [flag, setFlag] = useState();
    const [isSendMessage, setIsSendMessage] = useState(false);
    const tabsRef = createRef();
    const tournamentDataTimoutRef = useRef();

    const setTabFromURL = (t) => {
        let params = RequestUtils.getURLParameters();
        if (params.tab) {
            setSectionIndex(getPublishTabs(t).filter(t => t).findIndex(t => t.url === params.tab));
        }
    }

    const getData = async (tournamentId) => {
        let tournament = getCache[tournamentId];
        if (!tournament) {
            tournament = await TournamentModel.getTournament(tournamentId);
            cache(tournamentId, tournament);
        }
        if (tournament) {
            setData(tournament);
            let f = (tournament?.locations || []).find(l => l.country);
            if (f) {
                f = await getFlag(f.country);
                setFlag(f && <IonIcon className={`flag`} icon={f} />);
            } 
            let ids = tournament.getRegistrations && tournament.getRegistrations().map(r => r.membership);
            ids && ImageModel.getImageByRefs({ids, image_type: IMAGE_TYPE.profile, image_size: 60, page_size: ids.length})
                .then(imgs => {
                    console.log(imgs)
                    imgs.forEach(img => {
                        let membership = tournament.getMemberships().find(m => m.id === img.ref);
                        if (membership){
                            membership.getImage = () => img;
                        }
                    })
                });
            setTabFromURL(tournament);
            tournamentDataTimoutRef.current && clearTimeout(tournamentDataTimoutRef.current);
            const checkDates = () => {
                tournamentDataTimoutRef.current = setTimeout(async () => {
                    let dates = await TournamentModel.getTournamentDates(tournamentId);
                    console.log(dates);
                    dates = Object.values(TournamentModel.convertTournamentDates({dates}));
                    let inter = Utils.intersection(dates, Object.values(tournament.dates));
                    if (inter.length != dates.length) {
                        tournament.dates = dates;
                        setData({...tournament});
                    }
                    checkDates();
                }, 10 * 60000);
            }
            checkDates();
        }
    }

    useIonViewWillEnter(() => {
        getData(RequestUtils.getURLId('tournaments'));
    }, [session]);

    let cart = session && session.getCarts && data && session.getCarts().find(c => c.ref === data.id);
    let registered = session && data && session.getRegistrations(data.id);
    const getDates = (dates) => {
        let sd = dates?.start_date_tz;
        let ed = dates?.end_date_tz;
        return getTournamentDates(TournamentModel.convertTimeZoneToLocal(sd), 
            TournamentModel.convertTimeZoneToLocal(ed));
    }
    const getLocation = () => {
        return getTournamentLocation(data);
    }
    const getContacts= () => {
        if (!data.contact_infos || data.contact_infos.length===0){
            return '';
        }
        return data.contact_infos.map((c, i) => {
            let ci = [
                c.name&&<b key={0}>{c.name}</b>, 
                c.phone&&<span key={1}> {c.phone}</span>, 
                c.email&&<span key={2}> <Link href={`mailto:${c.email}`} underline="hover">{c.email}</Link></span>
            ].filter(ci => ci);
            return <div key={i} className="contact">{ci}</div>;
        });
    }
    const getPublishTabs = (tour=data) => {
        let publishes = Object.keys((tour && tour.publish) || {}).map(p => tour.publish[p] && p);
        let skips = ['registration', 'map', 'photos'];
        return TABS.map(t => (skips.includes(t.url) || publishes.includes(t.url)) && t);
    }

    const getEditLink = () => {
        return `/tournaments/${props.match.params.id}/edit`;
    }

    return <IonPage>
        {data ? <IonContent className="TournamentPage">
            {data?.getImage && data.getImage() && <Image image={data.getImage()} className={TournamentPageStyles.coverPhoto} size={800} />}
            <div className={`page-title ${TournamentPageStyles.header}`}>
                <h1 className={TournamentPageStyles.tournamentName}>
                    {data && UserModel.isAdmin(session, ENTITY.tournament, data.id) &&
                        <IonFab horizontal="end">
                            <IonFabButton className="entity-edit"
                                routerLink={getEditLink()}>
                                <IonIcon icon={pencil} className="button"/><span>Edit</span>
                            </IonFabButton>
                        </IonFab>}
                    {data.name}</h1>
                <div className="location-dates">    
                    <span className="location">
                        {flag}
                        <span>{getLocation()}</span>
                    </span>
                    <b className="dates">{getDates(data.dates)}</b>
                    <span className="timezone">@{data.time_zone}</span>
                </div>
                <div className="contacts">
                    {getContacts()}
                </div>
                <SocialMedias socialMedias={data.social_medias} />
                {registered && registered.length>0 && <h3 className="registered-message">You are registered for this tournament</h3>}
            </div>
            {session && <div className={PageStyles.alignCenter}>
                <button className="button" onClick={() => setIsSendMessage(true)}><Icon name="email" />Message</button>
                <button className="button"><Icon name="heart"/>Follow</button>
            </div>}
            {session && isSendMessage && 
                <SendMessage 
                    to={data.id} 
                    toDisplay={data.name} 
                    from={session.id} 
                    message_type={MESSAGE_TYPE.tournament} 
                    onDone={() => setIsSendMessage(false)}/>}
            <InfoSection data={data} />
            <Tabs ref={tabsRef}
                index={sectionIndex}
                tabs={getPublishTabs().filter(t => t)}
                sections={[
                    <div key={0} className={`registration-title ${TournamentPageStyles.Registrations}`}>
                        <DivisionRegistration
                            setFilterCount={setFilterCount}
                            tournament={data}
                            registered={registered}
                            cbUpdate={(session) => {
                                session && setSession({...session});
                            }}
                            getFilterCount={c => {
                                setFilterCount(c);
                            }}
                        />
                    </div>,
                    <div key={1} className="Competitors">
                        <Registrations tournament={data} isEdit={false} session={session}/>
                    </div>,
                    <Schedules key={2} tournament={data} tabsRef={tabsRef}/>,
                    <div key={3} className="Brackets">
                        <BracketWrapper tournament={data}/>
                    </div>,
                    <div key={4} className="Rankings">
                        <Rankings tournament={data}/>
                    </div>,
                    <div key={5} className="GoogleMap">
                        {data && Array.isArray(data.locations) && data?.locations.map((l, i) => <GoogleMap key={i} location={l} />)}
                    </div>,
                    <div key={6} className="Photos">
                        <h2>Photos</h2>
                        <Photos entity={data} entity_type={ENTITY.tournament} model={TournamentModel} session={session} />
                    </div>,
                ].filter((s, i) => {
                    return getPublishTabs().map((t, j) => t && j).filter(t => t !== false).includes(i);
                })}
            />
        </IonContent> : <LoadingScreen/>}
    </IonPage>
}

export const getPlacement = (p) => {
    if (!p) {
        return '';
    }
    if (p === 1) {
        return '1st';
    }
    if (p === 2) {
        return '2nd';
    }
    if (p === 3) {
        return '3rd';
    }
    return `Place ${p}`;
}

export const DivisionFilter = forwardRef(({value: chip, tournament, onSelect, onChip, includeBracketTypeSize, isActiveTab, isEditMode, division: _division}, ref) => {
    const history = useHistory();
    const isBracketEntriesUpdate = useStore(state => state.isBracketEntriesUpdate);
    const [selected, setSelected] = useState();
    const [division, setDivision] = useState();
    const [refresh, setRefresh] = useState(false);
    const filterDivisions = useRef({});
    const urlParams = RequestUtils.getURLParameters();

    useImperativeHandle(ref, () => ({
        filters: filterDivisions.current, 
        setSelected, 
        initDivision, 
        division, 
        selected,
        setChipByDivision: div => {
            let chip = [...filterDivisions.current]
                .sort((a, b) => Utils.sort(a.divisions.length, b.divisions.length))
                .find(fs => fs.divisions.find(d => d.id === div));
            chip && setSelected(chip.value);
        }
    }));
    const createFilters = () => {
        filterDivisions.current = getDivisionTypeChips(tournament);
        for (let fs of filterDivisions.current) {
            let divisions = getTournamentDivisionsByFilters(tournament, [fs], [], () => [], () => [], isEditMode);
            fs.divisions = divisions;
        }

        return Object.keys(filterDivisions.current).length > 0 && filterDivisions.current;
    }

    const initDivision = (d) => {
        if (d) {
            d.getTournament = () => tournament;
            setDivision(d.pool || d.id);
            isActiveTab && RequestUtils.insertURLParam('id', d.pool || d.id, history);
            onSelect(d.pool || d.id);
        }
    }

    useEffect(() => {
        // console.log('_division', _division)
        // _division && initDivision(_division);
    }, [_division]);

    useEffect(() => {
        // console.log('isActiveTab', isActiveTab)
        // setRefresh(!refresh);
    }, [isActiveTab]);

    useEffect(() => {
        if (!tournament) {
            return;
        }
        
        let fs = createFilters();
        if (fs) {
            let f;
            if (chip) {
                f = fs.find(_f => _f.value === chip);
            }
            if (!f) {
                f = fs.find(_f => _f.value === urlParams.chip);
                if (!f) {
                    f = fs.find(filter => filter.divisions.length > 0);
                }
            }
            if (f) {
                setSelected(f.value);
                let div = f.divisions[0];
                if (urlParams.id){
                    let d = f.divisions.find(_d => [_d.id, _d.pool].includes(urlParams.id));
                    if (d) {
                        div = d;
                    }
                }
                initDivision(div);
            }
        }
    }, [tournament, isBracketEntriesUpdate, chip, ]); //urlParams.chip, urlParams.id

    const getBracketTypeSize = (b) => {
        if (includeBracketTypeSize) {
            try{
                let d = tournament.getAvailableDivisions().find(_d => [b.id, b.pool].includes(_d.id));
                if (d) {
                    if (!d.getRegistrations) {
                        d.getRegistrations = () => tournament.getRegistrations().filter(r => [...r.pool.split('|'), r.division].includes(d.id));
                    }
                    let regs = d.getRegistrations().filter(r => r.status === 'A');
                    let length = regs.length;
                    if (d.pool === d.id) {
                        length = regs.filter(r => r.pool.split('|').includes(d.pool)).length;
                    }
                    return ` [${b.bracket_type.toUpperCase()}-${length}]${d.bracket_type===BRACKET.Single_Elimination && d.is_third? ' [has 3rd]':''}`;
                }
            }catch(e) {
                console.log(e);
            }   
        }
        return '';
    }

    const getDivisionOptions = () => {
        if (!selected) {
            return [];
        }
        let f = filterDivisions.current.find(f => f.value === selected);
        return !f? [] : f.divisions.map(b => ({ value: b.pool || b.id, label: <div>{b.pool || b.name}<b>{getBracketTypeSize(b)}</b></div>}));
    }

    return <ThemeProvider theme={Theme}>
        {Object.keys(filterDivisions.current).length>0? 
            <div className={`DivisionFilter ${TournamentPageStyles.DivisionFilter}`}>
                <div className={TournamentPageStyles.DivisionFilterButtons}>
                    {filterDivisions.current.filter(f => f.divisions.length > 0).map((f, i) => {
                        return <Chip
                            key={i}
                            className="filter"
                            label={f.label}
                            variant={f.value === selected ? 'filled' : 'outlined'}
                            color={f.value === selected ? 'primary' : undefined}
                            onClick={() => {
                                setSelected(f.value);
                                onChip(f.value);
                                let divs = f.divisions;
                                initDivision(divs.length>0? divs[0] : null);
                            }}
                        />
                    })}
                </div>
                <div className="SelectFormInput-wrapper">
                    {selected && <SelectFormInput width={'98%'}
                        name="division_filter"
                        label="Division"
                        value={division || ''}
                        options={getDivisionOptions()}
                        onChange={value => {
                            isActiveTab && RequestUtils.insertURLParam('id', value, history);
                            onSelect(value);
                            setDivision(value);
                        }}
                    />}
                </div>
            </div> : <AlertPane message={`warning: There is no available division.  Please 
                check the registration status of the competitors tab.  Registration 
                status must be active.`} />}

    </ThemeProvider>
});

function InfoSection({ data }) {
    const { dates, getRegistrations, getLeague, admins } = data;
    const [showRule, setShowRule] = useState(false);
    const [ruleFileURL, setRuleFileURL] = useState();
    const [isRuleFilePDF, setIsRuleFilePDF] = useState(false);
    useEffect(() => {
        if (data) {
            let rf = data.getRuleFile();
            if (rf && rf.name) {
                let ext = rf.name.split('.').pop();
                let fileUrl = `${GRAPH_SERVER_HOST}/getFile/${rf.id}`;
                setRuleFileURL(fileUrl);
                setIsRuleFilePDF(ext === 'pdf');
            }
        }
    }, [data]);
    const getNumberOfCompetitors = () => {
        return Utils.uniqArray(((getRegistrations && getRegistrations()) || [])?.map(r => r.membership)).length;
    };

    const getDate = (dt, deFault = 'TBD') => Utils.formatDateTime(dt) || deFault;
    return <div className="InfoSection">
        {isRuleFilePDF && showRule && <ShowRule file={data.getRuleFile()} onDone={() => setShowRule(false)}/>} 
        <div className={`InfoSection ${TournamentPageStyles.infoSection}`}>
            <div className={TournamentPageStyles.info}>
                <Icon name="users"/>
                <div>
                    <span>Number of competitors</span>
                    <span>{getNumberOfCompetitors()}</span>
                </div>
            </div>
            <div className={TournamentPageStyles.info}>
                <Icon name="league" />
                <div>
                    <span>League</span>
                    {getLeague && getLeague() && <Link href={`/leagues/${getLeague().id}`}>{getLeague().name}</Link>}
                </div>
            </div>
            <div className={TournamentPageStyles.info}>
                <MembershipIcon />
                <div>
                    <span>Admins</span>
                    {admins && admins.map((a, i) => <Link key={i} href={`/users/${a.id}`}>{a.display}</Link>)}
                </div>
            </div>
            {data.rule && 
            <div className={`rules ${TournamentPageStyles.info}`}>
                <IonIcon icon={bookOutline}/>
                <div className="rule-link">
                    {isRuleFilePDF? 
                        <Link onClick={e => setShowRule(true)} style={{cursor: 'pointer'}}>Rules</Link> :
                        <Link href={ruleFileURL}>Rules</Link>}
                </div>
            </div>}
            {dates?.close_date && <div className={TournamentPageStyles.info}>
                <Icon name="calendar"/>
                <div>
                    <span>Registration Close Date</span>
                    <span>{getDate(TournamentModel.convertTimeZoneToLocal(dates?.close_date_tz))}</span>
                    <span className="timezone">@{data.time_zone}</span>
                </div>
            </div>}
            {dates?.member_change_date && <div className={TournamentPageStyles.info}>
                <Icon name="calendar"/>
                <div>
                    <span>Athlete Change Deadline</span>
                    <span>{getDate(TournamentModel.convertTimeZoneToLocal(dates?.member_change_date_tz))}</span>
                    <span className="timezone">@{data.time_zone}</span>
                </div>
            </div>}
            {dates?.coach_change_date && <div className={TournamentPageStyles.info}>
                <Icon name="calendar"/>
                <div>
                    <span>Coach Change Deadline</span>
                    <span>{getDate(TournamentModel.convertTimeZoneToLocal(dates?.coach_change_date_tz))}</span>
                    <span className="timezone">@{data.time_zone}</span>
                </div>
            </div>}
            {dates?.refund_date && <div className={TournamentPageStyles.info}>
                <Icon name="calendar"/>
                <div>
                    <span>Cancellation Deadline</span>
                    <span>{getDate(TournamentModel.convertTimeZoneToLocal(dates?.refund_date_tz))}</span>
                    <span className="timezone">@{data.time_zone}</span>
                </div>
            </div>}
        </div>
    </div>;
}

const ShowRule = ({file, onDone}) => {
    let ext = file.name.split('.').pop();
    let fileUrl = `${GRAPH_SERVER_HOST}/getFile/${file.id}`;
    if (ext === 'pdf') {
        return <div className="ShowRule pdf">
            <button className="button icon_button" onClick={onDone}><DoneIcon /></button>
            <PDFReader filePath={fileUrl} />
        </div>
    }
    return ''
}

export default TournamentPage;
